import React, { useEffect, useState, useRef, useContext } from "react";
import "./POSInvoice";
//import { Link } from 'react-router-dom';
import PreviousOrder from '../../Inventory/Dashboard/PreviousOrder';
import AccountMaster from '../../Inventory/Master/AccountMaster/AccountMaster';
import OrderFeedback from "../OrderTaker/OrderFeedback";
import ItemGroupApi from '../../API/APIServices/ItemGroup';
import TableGroupApi from '../../API/APIServices/TableGroup';
import TableApi from '../../API/APIServices/Table';
import OrderApi from '../../API/APIServices/Order';
import UserCredentialApi from '../../API/APIServices/UserCredential';
import ItemDepartmentApi from '../../API/APIServices/ItemDepartment';
import AccountApi from "../../API/APIServices/Account";
import ItemMasterApi from "../../API/APIServices/ItemMaster";
import TaxCategoryApi from "../../API/APIServices/TaxCategory";
import BillTypeApi from "../../API/APIServices/BillType";
import BillSeriesMasterApi from "../../API/APIServices/BillSeriesMaster";
import Table from "../../API/APIServices/Table";
import Account from "../../API/APIServices/Account";
import BillSeriesMaster from "../../API/APIServices/BillSeriesMaster";
import BillPaymentMode from "../../API/APIServices/BillPaymentMode";
import Loader from "../../Inventory/Props/Loader";
import MyContext from "../../Inventory/MyContext";
import OrderTakerBillPrint from "../OrderTaker/OrderTakerBillPrint";
import KotPrintPage from "../KotManager/KotPrintPage";
import useIntersectionObserver from "./useIntersectionObserver "
import Order from "../../API/APIServices/Order";
import DeliveryAndTimeSlot from "../../API/APIServices/DeliveryAndTimeSlot";
import PaymantModeModel from "./PaymantModeModel";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import BillLayoutSetting from "../../API/APIServices/BillLayoutSetting";
import ToolTipMaster from "../../Inventory/Props/ToolTipMaster";
import ItemMaster from "../../API/APIServices/ItemMaster";
import OverallAndKotPrint from '../OrderTaker/OverallAndKotPrint'
import TableGroup from "../../API/APIServices/TableGroup";
import UserCredential from "../../API/APIServices/UserCredential";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";
import Company from "../../API/APIServices/Company";
import MultipleKotPrintPage from "../KotManager/MultipleKotPrintPage";
import PosSlider from "./PosSlider";
import DineIn from "./DineIn";
import WebTemplate from "../../API/APIServices/WebTemplate";
import ReportDesign from "./ReportDesign"
import POS from "./POS";
import Payment from "../../API/APIServices/Payment";
import axios from "axios";

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const OrderPage = () => {

    // const imageBaseUrl = `${WebApiUrl}/api/v1/ItemMaster/Image?D1ItemId=`;
    const { isShowTotalAmountInReport, imageBaseUrl, isAllowModificationOfVoucher, UserDetails } = useContext(MyContext);
    // console.log("isAllowModificationOfVoucher", isAllowModificationOfVoucher);

    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useState(() => {
        document.title = 'POS';
    }, []);

    const [companyData, setCompanyData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const storedArrayString = localStorage.getItem('loginAccountUserData');
            var loginUser;
            if (storedArrayString) {
                loginUser = JSON.parse(storedArrayString);
            }
            try {
                if (loginUser) {
                    const data = await Company.GetCompanyById(loginUser.compId)
                    setCompanyData(data);
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [])

    const [orderData, setOrderData] = useState({
        ord1Id: 0,
        orderSeriesId: 0,
        orderNo: "0",
        autoOrderNo: 0,
        serviceTypeId: 0,
        overallOrderStatus: (companyData && companyData?.isPOSConfirmNKotBtn == true) ? "Confirm" : "Pending",  //confirm only for Confirm & KOT
        orderDate: "",
        orderTime: "",
        orderDoneBy: 0,
        orderDoneOn: "",
        orderConfirmedBy: 0,
        orderConfirmedOn: "",
        orderDoneFrom: "POS",
        d1AccountId: 0,
        d2AccountId: 0,
        billTypeId: 0,
        tableGroupId: 0,
        tableId: 0,
        noOfPerson: 0,
        noOfAdult: 0,
        noOfChild: 0,
        couponCode: "",
        couponCodeAmount: 0,
        deliveryCharges: 0,
        deliveryDate: "",
        deliveryTimeId: 0,
        deliveryStatus: "",
        deliveryBoyId: 0,
        deliveryBoyDispatchTime: "00:00:00",
        deliveryBoyTripNo: 0,
        deliveryBoyTripIncentive: 0,
        deliveryReferenceNo: 0,
        deliveryPreferenceNo: 0,
        deliveryTripStatus: "",
        orderPrintStatus: 0,
        totalAmt: 0,
        initialOrderTotalAmt: 0,
        grandTotalAmt: 0,
        paymentStatus: "Pending",
        paymentAmount: 0,
        returnAmount: 0,
        discountAmount: 0,
        paymentDoneBy: 0,
        paymentDoneOn: "",
        paymentAttemptedCounter: 0,
        paymentMode: 0,
        paymentBank: "",
        paymentRemarks: "",
        remarks: "",
        orderSuggestion: "",
        kotDispatchTargetTime: "",  // deliveryTime
        latitude: "",
        longitude: "",
        currencyId: 0,
        hasRunningOrder: false,
        runningOrderCounter: 0,
        runningOrderTotalAmt: 0,
        kotManagerId: 0,
        deliveryAddress: "",
        name: "",
        mobileNo: "",
        companyGSTNo: "",
        companyDetails: "",
        o1CancelledAmt: 0,
    });

    // const [menuItems, seyMenuItems] = useState([
    //     {
    //         id: 1,
    //         itemName: "Tandoori Momos",
    //         barcode: 'I0001',
    //         image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQq673pOk1Wllffzp4AEagD_qCN_Dng1qTnKg&usqp=CAU",
    //         price: 150.00,
    //         variableItem: true,
    //         deliveryPreference: 1,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 2,
    //         itemName: "Garlic Bread",
    //         barcode: 'I0002',
    //         image: "https://w7.pngwing.com/pngs/55/450/png-transparent-garlic-bread-domino-s-pizza-falafel-benefits-of-raw-garlic-baked-goods-food-american-food.png",
    //         price: 100.00,
    //         variableItem: false,
    //         deliveryPreference: 2,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 3,
    //         itemName: "Chilli Mushroom",
    //         barcode: 'I0003',
    //         image: "https://img-global.cpcdn.com/recipes/9291e0fe9073f60a/1200x630cq70/photo.jpg",
    //         price: 120.00,
    //         variableItem: false,
    //         deliveryPreference: 3,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 4,
    //         itemName: "Hakka Noodles",
    //         barcode: 'I0004',
    //         image: "https://m.media-amazon.com/images/I/71dXe+aKzZL.jpg",
    //         price: 150.00,
    //         variableItem: true,
    //         deliveryPreference: 3,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 5,
    //         itemName: "Veg Sandwich",
    //         barcode: 'I0005',
    //         image: "https://images.unsplash.com/photo-1586190848861-99aa4a171e90?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YnVyZ2VyJTIwcG5nfGVufDB8fDB8fHww&w=1000&q=80",
    //         price: 145.00,
    //         variableItem: false,
    //         deliveryPreference: 2,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 6,
    //         itemName: "Spring Roll",
    //         barcode: 'I0006',
    //         image: "https://www.pngmart.com/files/16/Crispy-Spring-Rolls-Transparent-PNG.png",
    //         price: 80.00,
    //         variableItem: false,
    //         deliveryPreference: 1,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 7,
    //         itemName: "Cheese Pasta",
    //         barcode: 'I0007',
    //         image: "https://e7.pngegg.com/pngimages/38/556/png-clipart-pasta-pasta-thumbnail.png",
    //         price: 180.00,
    //         variableItem: false,
    //         deliveryPreference: 1,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 8,
    //         itemName: "Aloo Tikki Burger",
    //         barcode: 'I0008',
    //         image: "https://images.unsplash.com/photo-1586190848861-99aa4a171e90?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YnVyZ2VyJTIwcG5nfGVufDB8fDB8fHww&w=1000&q=80",
    //         price: 280.00,
    //         variableItem: false,
    //         deliveryPreference: 2,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 9,
    //         itemName: "French Fries",
    //         barcode: 'I0009',
    //         image: "https://kirbiecravings.com/wp-content/uploads/2019/09/easy-french-fries-1.jpg",
    //         price: 99.00,
    //         variableItem: false,
    //         deliveryPreference: 3,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 10,
    //         itemName: "Tandoori Momos",
    //         barcode: 'I0010',
    //         image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQq673pOk1Wllffzp4AEagD_qCN_Dng1qTnKg&usqp=CAU",
    //         price: 150.00,
    //         variableItem: true,
    //         deliveryPreference: 3,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 11,
    //         itemName: "Garlic Bread",
    //         barcode: 'I0011',
    //         image: "https://w7.pngwing.com/pngs/55/450/png-transparent-garlic-bread-domino-s-pizza-falafel-benefits-of-raw-garlic-baked-goods-food-american-food.png",
    //         price: 100.00,
    //         variableItem: false,
    //         deliveryPreference: 2,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 12,
    //         itemName: "Chilli Mushroom",
    //         barcode: 'I0012',
    //         image: "https://img-global.cpcdn.com/recipes/9291e0fe9073f60a/1200x630cq70/photo.jpg",
    //         price: 120.00,
    //         variableItem: false,
    //         deliveryPreference: 1,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 13,
    //         itemName: "Hakka Noodles",
    //         barcode: 'I0013',
    //         image: "https://m.media-amazon.com/images/I/71dXe+aKzZL.jpg",
    //         price: 150.00,
    //         variableItem: true,
    //         deliveryPreference: 1,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 14,
    //         itemName: "Veg Sandwich",
    //         barcode: 'I0014',
    //         image: "https://images.unsplash.com/photo-1586190848861-99aa4a171e90?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YnVyZ2VyJTIwcG5nfGVufDB8fDB8fHww&w=1000&q=80",
    //         price: 145.00,
    //         variableItem: false,
    //         deliveryPreference: 2,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 15,
    //         itemName: "Spring Roll",
    //         barcode: 'I0015',
    //         image: "https://www.pngmart.com/files/16/Crispy-Spring-Rolls-Transparent-PNG.png",
    //         price: 80.00,
    //         variableItem: false,
    //         deliveryPreference: 3,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 16,
    //         itemName: "Cheese Pasta",
    //         barcode: 'I0016',
    //         image: "https://e7.pngegg.com/pngimages/38/556/png-clipart-pasta-pasta-thumbnail.png",
    //         price: 180.00,
    //         variableItem: false,
    //         deliveryPreference: 3,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 17,
    //         itemName: "Aloo Tikki Burger",
    //         barcode: 'I0017',
    //         image: "https://images.unsplash.com/photo-1586190848861-99aa4a171e90?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YnVyZ2VyJTIwcG5nfGVufDB8fDB8fHww&w=1000&q=80",
    //         price: 280.00,
    //         variableItem: false,
    //         deliveryPreference: 2,
    //         quantity: 1,
    //         color: "white"
    //     },
    //     {
    //         id: 18,
    //         itemName: "French Fries",
    //         barcode: 'I0018',
    //         image: "https://kirbiecravings.com/wp-content/uploads/2019/09/easy-french-fries-1.jpg",
    //         price: 99.00,
    //         variableItem: false,
    //         deliveryPreference: 1,
    //         quantity: 1,
    //         color: "white"
    //     },
    // ])

    const [menuItems, setMenuItems] = useState([])
    const [order3, setOrder3] = useState();
    const [order2, setOrder2] = useState([]);
    const [runningOrder, setRunningOrder] = useState({});
    const [runningOrderOverAll, setRunningOrderOverAll] = useState({});

    const [selectedItemIndex, setSelectedItemIndex] = useState(null);

    const [index, setIndex] = useState(1);
    const containerRef = useRef(null);
    const [fetchMoreData, setFetchMoreData] = useState(true);


    // useEffect(() => {
    //     fetchItemMasterData(1); // Fetch data for initial index
    // }, []);

    const [activeButton, setActiveButton] = useState(null);
    // const [inputVisible, setInputVisible] = useState(false);

    const handleButtonClick = (buttonId, serviceTypeId, event) => {
        const id = Number(serviceTypeId);
        console.log(`btn- ${buttonId}, id- ${id}`);
        localStorage.setItem("serviceTypeId", id)
        const updatedValues = {
            ...getDeliveryAndTimeSlotId,
            orderType: id,
            deliveryPointName: 1,
            distance: 0,
            isCurrentDate: true,
        };
        setDeliveryAndTimeSlotId((prevState) => ({
            ...prevState,
            orderType: id,
            deliveryPointName: 1,
            distance: 0,
            isCurrentDate: true,
        }));

        const data = fetchTimeSlot(updatedValues);

        if (id != 751) {
            setRunningOrder([])
            setRunningOrderOverAll([])
            fetchOrderData(id)
        }
        else {
            fetchTableGroupData();
            //fetchTableData();
        }
        //alert(buttonId)
        if (buttonId === 2 || buttonId === 3) {
            // setInputVisible(true);
            document.getElementById('gridView').style.gridTemplateColumns = "repeat(3, auto)";
        }
        else {
            // setInputVisible(false)
            document.getElementById('gridView').style.gridTemplateColumns = "repeat(3, auto)";
        }
        setActiveButton(buttonId);

        if (data) {
            setOrderData((prevValues) => ({
                ...prevValues,
                "serviceTypeId": id,
                "deliveryTimeId": data[0]?.id,
                "ord1Id": 0,
                "tableId": 0,
                "tableGroupId": 0
            }));
        } else {
            setOrderData((prevValues) => ({
                ...prevValues,
                "serviceTypeId": id,
                "ord1Id": 0,
                "tableId": 0,
                "tableGroupId": 0
            }));
        }
        const element = document.getElementById('TableNo')
        if (element) {
            element.style.display = "none";
            document.getElementById('menu-pos').style.width = "100%";
        }
    };

    const [variableId, setVariableId] = useState();

    const handleButtonClickforVariableItem = (index, d2Id) => {
        setSelectedItemIndex(index);
        setVariableId(d2Id)
        console.log("index", index, "d2Id", d2Id);
    };

    const variableCloseModal = () => {
        const val = VariableSaveBtn();
        if (val) {
            closeVariableItemModal();
        }
    }

    const VariableSaveBtn = () => {
        if (selectedItemIndex == null || selectedItemIndex == undefined) {
            alert("Please select atleast one Item");
            return false;
        }
        ItemButtonClick(variableId)
        return true;
        //closeVariableItemModal();
    }

    const getVariableStyle = (buttonId) => {
        return {
            backgroundColor: activeButton === buttonId ? 'white' : '#12234d',
            fontWeight: activeButton === buttonId ? '800' : '600',
            color: activeButton === buttonId ? '#12234d' : 'white',
            border: activeButton === buttonId ? '5px solid #12234d' : '3px solid white',
            transition: 'background-color 0.3s, font-weight 0.3s',
            height: 'auto',
            paddingTop: activeButton === buttonId ? '3px' : '4px',
            minWidth: activeButton === buttonId ? '150px' : '150px',
            minHeight: activeButton === buttonId ? '100px' : '100px',
        };
    };
    const getVariableStyleWhite = (buttonId) => {
        return {
            backgroundColor: activeButton === buttonId ? '#12234d' : 'white',
            fontWeight: activeButton === buttonId ? '800' : '600',
            color: activeButton === buttonId ? 'white' : '#12234d',
            border: activeButton === buttonId ? '1px solid white' : '1px solid #12234d',
            transition: 'background-color 0.3s, font-weight 0.3s',
            height: 'auto',
            paddingTop: '5px',
            minWidth: activeButton === buttonId ? '150px' : '150px',
            minHeight: activeButton === buttonId ? '100px' : '100px',

        };
    };

    const getButtonStyle = (buttonId) => {
        return {
            backgroundColor: activeButton === buttonId ? 'white' : '#12234d',
            fontWeight: activeButton === buttonId ? '800' : '600',
            color: activeButton === buttonId ? '#12234d' : 'white',
            border: activeButton === buttonId ? '5px solid #12234d' : '3px solid white',
            transition: 'background-color 0.3s, font-weight 0.3s',
            height: '40px',
            paddingTop: activeButton === buttonId ? '3px' : '4px',
            //':hover': {
            //    backgroundColor: activeButton === buttonId ? 'red' : 'yellow',
            //    fontWeight: '800',
            //}
        };
    };

    // *****************************{Price Update}*****************************
    // const [Price, setPrice] = useState([]);

    const [cartData, setCartData] = useState([]);
    const [isRunningOrderChange, setIsRunningOrderChange] = useState(false);

    const handleIncrement = (id, overAllStatus, ord2Id) => {
        setLoading(true);
        if (overAllStatus == "Pending") {  //runningOrder
            const itemIndex = runningOrder.findIndex(item => item.ord2Id === ord2Id);
            const menuIndex = menuItems.findIndex(item => item.d2Id === id);

            const itemData = menuItems.filter(item => item.d2Id == id);
            if (itemData && itemData[0].stockCheck == 1) {
                const cartCheckData = runningOrder.filter(item => item.d2Id == id)
                if (cartCheckData && cartCheckData.length > 0 && (cartCheckData[0].quantity == itemData[0].restoAvailableQuantity)) {
                    alert("Out of Stock");
                    setLoading(false);
                    return false;
                }
            }
            if (itemIndex !== -1) {
                const updatedCartData = [...runningOrder];
                updatedCartData[itemIndex].quantity += 1;
                updatedCartData[itemIndex].mrpPrice = (updatedCartData[itemIndex].quantity + 1) * updatedCartData[itemIndex].salePrice;
                setRunningOrder(updatedCartData);
            }
            changePrice(id, menuItems[menuIndex].salePrice * runningOrder[itemIndex].quantity, overAllStatus);
            setIsRunningOrderChange(true);
            setLoading(false);
        }
        else {
            const itemIndex = cartData.findIndex(item => item.d2Id === id);
            const menuIndex = menuItems.findIndex(item => item.d2Id === id);
            const orderIndex = order2.findIndex(item => item.d2Id === id);

            const itemData = menuItems.filter(item => item.d2Id == id);
            if (itemData && itemData[0].stockCheck == 1) {
                const cartCheckData = cartData.filter(item => item.d2Id == id)
                if (cartCheckData && cartCheckData.length > 0 && (cartCheckData[0].quantity == itemData[0].restoAvailableQuantity)) {
                    alert("Out of Stock");
                    setLoading(false);
                    return false;
                }
            }
            if (itemIndex !== -1) {
                debugger
                const updatedCartData = [...cartData];
                updatedCartData[itemIndex].quantity += 1;
                updatedCartData[itemIndex].mrpPrice = (updatedCartData[itemIndex].quantity + 1) * updatedCartData[itemIndex].salePrice;
                setCartData(updatedCartData);
                // ****************update order2******************
                const updatedOrderData = [...order2];
                updatedOrderData[orderIndex].qty += 1;
                setOrder2(updatedOrderData);
            }
            changePrice(id, menuItems[menuIndex].salePrice * cartData[itemIndex].quantity);
            setLoading(false);
        }
        //console.log(cartData[itemIndex].quantity, menuItems[index].salePrice, index)
    };

    const handleDecrement = async (id, overAllStatus, ord2Id) => {
        if (overAllStatus == "Pending") {
            const itemIndex = runningOrder.findIndex(item => item.ord2Id === ord2Id);
            const menuIndex = menuItems.findIndex(item => item.d2Id === id);
            if (runningOrder[itemIndex].quantity > 1) {
                if (itemIndex !== -1) {
                    const updatedCartData = [...runningOrder];
                    updatedCartData[itemIndex].quantity -= 1;
                    setRunningOrder(updatedCartData);
                }
                changePrice(id, ((menuItems[menuIndex].salePrice * runningOrder[itemIndex].quantity)), overAllStatus);
            }
            else {
                const userConfirmed = window.confirm("Are you sure, you want to cancel this item?");
                if (userConfirmed) {
                    deleteCartItemClick(id, overAllStatus, ord2Id);
                }
            }
            setIsRunningOrderChange(true);
        }
        else {
            const itemIndex = cartData.findIndex(item => item.d2Id === id);
            const menuIndex = menuItems.findIndex(item => item.d2Id === id);
            const orderIndex = order2.findIndex(item => item.d2Id === id);
            if (cartData[itemIndex].quantity > 1) {
                if (itemIndex !== -1) {
                    debugger;
                    const updatedCartData = [...cartData];
                    updatedCartData[itemIndex].quantity -= 1;
                    setCartData(updatedCartData);
                    // ***************update order2******************
                    const updatedOrderData = [...order2];
                    updatedOrderData[orderIndex].qty -= 1;
                    setOrder2(updatedOrderData);
                }
                changePrice(id, ((menuItems[menuIndex].salePrice * cartData[itemIndex].quantity)));  //- menuItems[menuIndex].salePrice
                console.log(menuItems[menuIndex].salePrice + "---" + cartData[itemIndex].quantity)
            }
            else {
                //changePrice(id, ((menuItems[menuIndex].salePrice * cartData[itemIndex].quantity)));
                deleteCartItemClick(id);
            }
        }
    };

    const onQuantityChange = (event, id) => {
        const data = event.target.value
        const itemIndex = cartData.findIndex(item => item.d2Id === id);
        const menuIndex = menuItems.findIndex(item => item.d2Id === id);
        const orderIndex = order2.findIndex(item => item.d2Id === id);

        const updatedCartData = [...cartData];
        updatedCartData[itemIndex].quantity = Number(data);
        setCartData(updatedCartData);

        changePrice(id, (menuItems[menuIndex].salePrice * cartData[itemIndex].quantity));

        const updatedOrderData = [...order2];
        updatedOrderData[orderIndex].qty = data;
        setOrder2(updatedOrderData);
        // if (data === "0") {
        //     deleteCartItemClick(id);
        // }
    };

    const changePrice = (id, data, overAllStatus) => {
        if (overAllStatus == "Pending") {
            const itemIndex = runningOrder.findIndex(item => item.d2Id === id);
            if (runningOrder[itemIndex].quantity > 0) {
                if (itemIndex !== -1) {
                    const updatedCartData = [...runningOrder];
                    updatedCartData[itemIndex].mrpPrice = data;
                    setRunningOrder(updatedCartData);
                    calculateTotalPrice(updatedCartData, overAllStatus);
                }
            }
        }
        else {
            const itemIndex = cartData.findIndex(item => item.d2Id === id);
            if (cartData[itemIndex].quantity > 0) {
                if (itemIndex !== -1) {
                    const updatedCartData = [...cartData];
                    updatedCartData[itemIndex].mrpPrice = data;
                    setCartData(updatedCartData);
                    calculateTotalPrice(updatedCartData);
                }
            }
        }
    }
    const [VariableItemModal, setVariableItemModal] = useState(false)
    const [VariableData, setVariableData] = useState([])

    const openVariableItemModal = async (id) => {
        setLoading(true)
        const filteredData = [
            {
                fieldName: "attributeParentId",
                operatorName: "Equal",
                compareValue: id
            },
            // {
            //     fieldName: "availableForPOS",
            //     operatorName: "Equal",
            //     compareValue: true
            // },
            // {
            //     fieldName: "isAttributeItem",
            //     operatorName: "Equal",
            //     compareValue: true
            // }
        ]
        try {
            const data = await ItemMasterApi.GetItemMasterWithFilter(filteredData);
            console.log(data);
            setVariableData(data);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false)
        }
        setVariableItemModal(true)
    }
    const closeVariableItemModal = () => {
        setSelectedItemIndex(null);
        setVariableItemModal(false)
    }

    const handleDelPreferenceChange = (event, id) => {
        console.log(cartData);
        console.log(order2);

        const itemIndex = cartData.findIndex(item => item.d2Id === id);
        console.log("index", itemIndex);
        console.log("id", id);
        console.log(event.target.value);

        if (itemIndex !== -1) {
            const updatedCartData = [...cartData];

            updatedCartData[itemIndex] = {
                ...updatedCartData[itemIndex],
                "preferenceNo": event.target.value
            };
            setCartData(updatedCartData);
        }

        const index = order2.findIndex(item => item.d2Id === id);

        if (index !== -1) {
            const updatedCartData = [...order2];

            updatedCartData[index] = {
                ...updatedCartData[index],
                "preferenceNo": event.target.value
            };
            setOrder2(updatedCartData);
        }
    }

    const checkItemExistence = async (id, itemIndex, selectedItem) => {
        if (itemIndex !== -1) {
            handleIncrement(id);
        } else {
            setCartData(prevCartData => {
                const updatedOrderStatus = [...prevCartData];
                const updatedItem = {
                    ...selectedItem,
                    "quantity": 1,
                    "preferenceNo": 1 //0
                };
                updatedOrderStatus.push(updatedItem);
                return updatedOrderStatus;
            });
            console.log(selectedItem);

            var kotStatus = "Received";
            if (selectedItem.preferenceNo === 1) {
                //kotStatus = "Transferred"
            }
            else {
                //kotStatus = "Received"
            }
            if (selectedItem.taxCategory1 !== 0) {
                const data = await TaxCategoryApi.getTaxCategoryById(selectedItem.taxCategory1)
                setOrder2([
                    ...order2,
                    {
                        //ord1Id: order2[0].ord1Id,
                        d2Id: selectedItem.d2Id,
                        godownId: selectedItem.defaultGodown,  //will get from Item Master
                        d1ItemId: selectedItem.d1Id,  //will get from Item Master
                        d2ItemId: selectedItem.d2Id,  //will get from Item Master
                        qty: 1,//selectedItem.quantity,
                        rate: selectedItem.salePrice,
                        totalAmt: 1 * selectedItem.salePrice,  //selectedItem.quantity
                        kotStatus: kotStatus,
                        preferenceNo: 1, //0, //selectedItem.preferenceNo,
                        orderDate: "",
                        orderTime: "",
                        itemSuggestion: "",
                        itemRunningOrderCounter: 0,
                        orderRunningQty: 0,
                        itemRunningQty: orderData.ord1Id !== 0 ? orderData.qty : 0,
                        taxableAmount: 1 * selectedItem.salePrice,
                        cgstPer: data.localTaxCGST,
                        cgstValue: (1 * selectedItem.salePrice) * (data.localTaxCGST / 100),
                        sgstPer: data.localTaxSGST,
                        sgstValue: (1 * selectedItem.salePrice) * (data.localTaxSGST / 100),
                        igstPer: data.centralTaxIGST,
                        igstValue: (1 * selectedItem.salePrice) * (data.centralTaxIGST / 100),
                        cessPer: data.cessTax,
                        cessValue: (1 * selectedItem.salePrice) * (data.cessTax / 100),
                        totalTaxableAmt: 1 * selectedItem.salePrice,
                        isLocal: true,    //true for table order
                        isInclusive: false,
                        hasComplementryItem: selectedItem.addasComplementaryItem,
                        cookStatus: "Pending",
                        itemIncentivePoint: selectedItem.salesManItemIncentive,
                        ord2Id: 0,
                        itemName: selectedItem.name,
                        popupCategoryForTable: selectedItem.sP_PopupCategoryForTable,
                        immediateTransferForHD: selectedItem.immediateTransferForHD,
                        itemDepartmentId: selectedItem.itemDepartment,
                    }
                ]);
            }
            else {
                setOrder2([
                    ...order2,
                    {
                        //ord1Id: order2[0].ord1Id,
                        d2Id: selectedItem.d2Id,
                        godownId: selectedItem.defaultGodown,  //will get from Item Master
                        d1ItemId: selectedItem.d1Id,  //will get from Item Master
                        d2ItemId: selectedItem.d2Id,  //will get from Item Master
                        qty: 1,//selectedItem.quantity,
                        rate: selectedItem.salePrice,
                        totalAmt: 1 * selectedItem.salePrice,  //selectedItem.quantity
                        kotStatus: kotStatus,
                        preferenceNo: 1, //0 //selectedItem.preferenceNo,
                        orderDate: "",
                        orderTime: "",
                        itemSuggestion: "",
                        itemRunningOrderCounter: 0,
                        orderRunningQty: 0,
                        itemRunningQty: orderData.ord1Id !== 0 ? orderData.qty : 0,
                        taxableAmount: 1 * selectedItem.salePrice,
                        cgstPer: 0,
                        cgstValue: 0,
                        sgstPer: 0,
                        sgstValue: 0,
                        igstPer: 0,
                        igstValue: 0,
                        cessPer: 0,
                        cessValue: 0,
                        totalTaxableAmt: 1 * selectedItem.salePrice,
                        isLocal: false,
                        isInclusive: false,
                        hasComplementryItem: false,
                        cookStatus: "Pending",
                        itemIncentivePoint: 0,
                        ord2Id: 0,
                        itemName: selectedItem.name,
                        popupCategoryForTable: selectedItem.sP_PopupCategoryForTable,
                        immediateTransferForHD: selectedItem.immediateTransferForHD,
                        itemDepartmentId: selectedItem.itemDepartment,
                    }
                ]);
            }
            setOrderData((prevValues) => ({
                ...prevValues,
                "paymentStatus": "Pending",
            }));
        }
    }

    const ItemButtonClick = async (id, cartDataList) => {
        setLoading(true);
        if (updatedId === true) {
            console.log("Updated");
            setUpdatedId(false);
            //return true;
        }

        // ***************************************
        let arrayList = [...menuItems];
        if (cartDataList) {
            arrayList = [cartDataList]
        }
        // console.log(menuItems);
        const selectedItem = arrayList.find(item => item.d2Id === id);

        if (selectedItem && selectedItem.attributeParentId === 0 && selectedItem.isAttributeItem === true) {
            openVariableItemModal(selectedItem.d2Id);
            return true;
        }
        console.log(cartData);
        if (selectedItem) {
            const itemIndex = cartData.findIndex(item => item.d2Id === id);
            // if (runningOrderOverAll && runningOrderOverAll.length > 0) {
            //     const itemRunningIndex = runningOrder.findIndex(item => item.d2Id === id);
            //     if (itemRunningIndex !== -1) {
            //         if(runningOrder[0].overallOrderStatus == "Pending"){
            //             handleIncrement(id,runningOrder[0].overallOrderStatus);
            //         }
            //         else{
            //             checkItemExistence(id, itemIndex, selectedItem);
            //         }
            //     }
            //     else {
            //         checkItemExistence(id, itemIndex, selectedItem);
            //     }
            // }
            // else {
            checkItemExistence(id, itemIndex, selectedItem);
            // }

        }
        scrollToBottom();
        console.log(order2);
        setLoading(false)
        // const updatedCartData = [...cartData];
        // updatedCartData[itemIndex].quantity += 1;
        // setCartData(updatedCartData);
    }

    const deleteCartItemClick = (id, overAllStatus, ord2Id) => {
        console.log(id);
        if (overAllStatus == "Pending") {
            const updatedCartData = runningOrder.filter(item => item.ord2Id !== ord2Id);
            setRunningOrder(updatedCartData);
            calculateTotalPrice(updatedCartData, overAllStatus);
        }
        else {
            const updatedCartData = cartData.filter(item => item.d2Id !== id);
            setCartData(updatedCartData);

            const updatedCartData1 = order2.filter(item => item.d2Id !== id);
            setOrder2(updatedCartData1);
            calculateTotalPrice(updatedCartData);
        }
    }

    // const handleTableButtonClick = (num) => {
    //     const id = "table" + num;
    //     //const name = "transaction" + num;
    //     if (document.getElementById(id).style.backgroundColor === "#9acd32") {
    //         document.getElementById(id).style.backgroundColor = "white";
    //     }
    //     else {
    //         document.getElementById(id).style.backgroundColor = "#9acd32";
    //     }
    // }

    const [isSliderOpen, setSliderOpen] = useState(false);

    const toggleSlider = () => {
        setSliderOpen(!isSliderOpen);
    };

    const [CouponModal, setCouponModal] = useState(false)

    const openCouponModal = () => {
        setCouponModal(true)
    }
    const closeCouponModal = () => {
        setCouponModal(false)
    }
    // const [couponCode, setCouponCode] = useState('');

    // const handleInputChange1 = (e) => {
    //     setCouponCode(e.target.value);
    // };
    const [CommentName, setCommentName] = useState();
    const [comments, setComments] = useState([]);
    const [commentModal, setCommentModal] = useState(false);
    const [commentedId, setCommentedId] = useState(null);
    const [selectedComment, setSelectedComment] = useState(null);

    const openCommentModal = (val, id) => {
        console.log(cartData);
        console.log(comments)
        console.log("id", id);
        setCommentModal(true);
        setCommentedId(id);
        setCommentName(val);
        setSelectedComment(comments[id] || '');

        if (input9Ref.current) {
            input9Ref.current.focus();
        }

        const itemIndex = comments.findIndex(item => item.d2Id === id);
        if (itemIndex !== -1) {
            const prevValue = comments[itemIndex].commentName;
            setSelectedComment(prevValue);
            console.log(prevValue);
        }
        else {
            // const itemIndex = orderData.findIndex(item => item.d2Id === id);
            // if (itemIndex !== -1) {
            //     setSelectedComment(orderData[itemIndex].itemSuggestion);
            // }
        }
    };

    useEffect(() => {
        if (commentModal && input9Ref.current) {
            input9Ref.current.focus();
        }
    }, [commentModal]);

    const closeCommentModal = () => {
        setCommentModal(false);
        setSelectedComment(null);
    };

    const handleCommentChange = (event) => {
        const val = event.target.value;
        setSelectedComment(val);
    };

    const saveComment = () => {
        setLoading(true)
        setComments([
            ...comments,
            {
                "d2Id": commentedId,
                "commentName": selectedComment
            }
        ]);
        const itemIndex = order2.findIndex(item => item.d2Id === commentedId);
        console.log("itemIndex", itemIndex);

        if (itemIndex !== -1) {
            const updatedOrder2 = [...order2];
            updatedOrder2[itemIndex] = {
                ...updatedOrder2[itemIndex],
                "itemSuggestion": selectedComment
            };
            setOrder2(updatedOrder2);
            console.log("updatedOrder2");
            console.log(updatedOrder2);
        }
        closeCommentModal();
        setLoading(false)
    };

    const selectStyle = {
        WebkitAppearance: 'none',  // for older versions of Chrome/Safari
        MozAppearance: 'none',     // for older versions of Firefox
        appearance: 'none',        // for modern browsers
    };
    const [AccountMasterModal, setAccountMasterModal] = useState(false)

    const openAccountMasterModal = () => {
        setAccountMasterModal(true);
    }
    const closeAccountMasterModal = () => {
        setAccountMasterModal(false)
    }
    const [FeedbackModal, setFeedbackModal] = useState(false)

    const openFeedbackModal = () => {
        setFeedbackModal(true);
    }
    const closeFeedbackModal = () => {
        setFeedbackModal(false)
    }
    const [showToast, setShowToast] = useState(false);

    const handleMessageBtn = () => {
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 1000);
    };

    const [cancelOrderModal, setCancelOrderModal] = useState(false)

    const openCancelOrderModal = () => {
        setCancelOrderModal(true)
    }

    const closeCancelOrderModal = () => {
        setCancelOrderModal(false)
    }

    const [remark, setRemark] = useState('')

    const onRemarkChange = (e) => {
        setRemark(e.target.value);
    }

    const CancelItemBtn = async () => {
        if (remark == null || remark == undefined || remark == "") {
            alert("Please enter reason to Cancel Order");
            return;
        }
        setLoading(true);
        const updatedValues = [
            {
                operationType: 0,
                path: "overallOrderStatus",
                op: "Add",
                from: "string",
                value: "Cancel"
            },
            {
                operationType: 0,
                path: "remarks",
                op: "Add",
                from: "string",
                value: remark
            },
            {
                operationType: 0,
                path: "orderPrintStatus",
                op: "Add",
                from: "string",
                value: false
            }
        ]
        await OrderApi.updateOrderPartially(updatedValues, orderData.ord1Id);

        // ************************ Make Table Free *******************
        if (orderData.serviceTypeId == 751) {
            const filteredData = [{
                operationType: 0,
                path: "tableStatus",
                op: "Add",
                from: "string",
                value: "Free"
            }]
            await Table.updateTablePartially(filteredData, orderData.tableId);
            localStorage.setItem("tableGroupId", orderData.tableGroupId);
        }
        setLoading(false);
        localStorage.setItem("serviceTypeId", orderData.serviceTypeId);
        alert("Cancelled Successfully")
        setCancelOrderModal(false)
        setLoading(false)
        // window.location.reload();
        const updatedItems = tableData && tableData.map(item =>
            item.id === orderData.tableId
                ? {
                    ...item,
                    orderPrintStatus: false,
                    paymentStatus: "Pending",
                    tableStatus: "Free"
                }
                : item
        );
        setTableData(updatedItems);
        ClearDataFunction(orderData.tableId)
        setInputValues({
            name: '',
            aMobileNo: '',
            adob: '',
            adom: '',
            referralBy: '',
            aAddress: ''
        });
        setInputItemValue('');
    }
    const [ordDetail, setOrdDetail] = useState({
        orderNo: "",
        tableName: "",
        ord1Id: 0,
        tableId: 0
    })

    const [tableChangeData, setTableChangeData] = useState()

    const onTableGroupDataChange = async (e) => {
        const data = await TableApi.getTableByTableGroupId(e.target.value)
        setTableChangeData(data);

    }

    const [tableChangeModal, setTableChangeModal] = useState(false);

    const openTableChangeModal = () => {
        setLoading(true)
        const tableFilteredData = tableData.filter(item => item.id == orderData.tableId)
        setOrdDetail((prevValues) => ({
            ...prevValues,
            "orderNo": orderData.orderNo,
            "tableName": tableFilteredData[0].tableName,
            "ord1Id": orderData.ord1Id,
            "tableId": orderData.tableId
        }));
        setTableChangeModal(true);
        setLoading(false);
    }

    const closeTableModal = () => {
        setTableChangeModal(false)
    }

    const [selectedTableChange, setSelectedTableChange] = useState();

    const handleTableChange = (e) => {
        setSelectedTableChange(e.target.value)
    }

    const tableChangeBtn = async () => {
        setLoading(true);
        if (selectedTableChange == undefined) {
            alert("Please select Table")
            return false
        }
        else {
            const filterData = [{
                fieldName: "id",
                operatorName: "Equal",
                compareValue: selectedTableChange
            }]

            const tableData = await Table.getTableGroupByTableId(filterData);
            console.log(tableData);
            const data = [
                {
                    "operationType": 0,
                    "path": "tableId",
                    "op": "Add",
                    "from": "string",
                    "value": Number(selectedTableChange)
                },
                {
                    "operationType": 0,
                    "path": "tableGroupId",
                    "op": "Add",
                    "from": "string",
                    "value": Number(tableData[0].tableGroupId)
                }
            ]
            await OrderApi.updateOrderPartially(data, ordDetail.ord1Id);

            const filteredData = [{
                operationType: 0,
                path: "tableStatus",
                op: "Add",
                from: "string",
                value: "Busy"
            }]
            await Table.updateTablePartially(filteredData, selectedTableChange);

            const filteredDataFree = [{
                operationType: 0,
                path: "tableStatus",
                op: "Add",
                from: "string",
                value: "Free"
            }]
            await Table.updateTablePartially(filteredDataFree, ordDetail.tableId);

            await onTableGroupSelection(Number(tableData[0].tableGroupId))
            await onTableSelect(selectedTableChange)

            // await onTableGroupSelection(orderData.tableGroupId);
            // await onTableSelect(ordDetail.tableId);
            // const tableGroupData = await TableApi.getTableByTableGroupId(orderData.tableGroupId);
            // setTableNumberData(tableGroupData);

            // const updatedItems = tableGroupData.map(item => {
            //     if (item.tableStatus === "Free") {
            //         return { ...item, tableStatus: 'NotSelected' };
            //     }
            //     return item;
            // });
            // setTableData(updatedItems);

            // setOrderData((prevValues) => ({
            //     ...prevValues,
            //     "tableId": Number(selectedTableChange),
            //     "tableGroupId": Number(tableData[0].tableGroupId),
            // }));

            alert("Updated");
            closeTableModal();
        }
        setLoading(false);
    }

    // ************************{Modal Back}**************************
    const handleModalClick = (e) => {
        e.stopPropagation();
    };
    const [itemGroupList, setItemGroupList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const data = await ItemGroupApi.GetItemGroup();
                console.log(data);
                setItemGroupList(data);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false)
            }
        };
        fetchData();
        fetchTableData();
    }, []);

    const [tableData, setTableData] = useState();
    const [tableNumberData, setTableNumberData] = useState();

    const fetchTableData = async () => {
        setLoading(true)
        const tableData = await TableApi.getTable();
        setTableNumberData(tableData);
        setTableChangeData(tableData);
        setLoading(false)
    }

    const onTableGroupSelection = async (id, isFromDineIn, tableId, tableStatus) => {
        // setLoading(true)
        const fieldName = "tableGroupId";
        const operatorName = "Equal";
        const compareValue = id;

        const data = await TableApi.getTableFromTableGroup(fieldName, operatorName, compareValue);
        setTableNumberData(data);

        const updatedItems = data.map(item => {
            if (isFromDineIn == "Yes") {
                if (item.id == tableId) {
                    if (tableStatus == "Free") {
                        return { ...item, tableStatus: 'Selected' };
                    }
                }
            }
            else {
                if (item.tableStatus === "Free") {
                    return { ...item, tableStatus: 'NotSelected' };
                }
            }
            return item;
        });
        setTableData(updatedItems);

        setOrderData((prevValues) => ({
            ...prevValues,
            "tableGroupId": id,
        }));

        var element = document.getElementById('TableNo');
        var element1 = document.getElementById('menu-pos');
        if (element && element1) {
            element.style.display = "block";
            element1.style.width = "100%";
        }
        // setLoading(false)
    }

    const [cancelData, setCancelData] = useState([]);

    const onOrderNoSelection = async (e) => {
        if (e.target.value == "0") {
            ClearDataFunction(0);
            setInputValues({
                name: '',
                aMobileNo: '',
                adob: '',
                adom: '',
                referralBy: '',
                aAddress: ''
            });
            setInputItemValue('');
            setRunningOrder([]);
            setRunningOrderOverAll([]);
            return 0;
        }
        setLoading(true)
        // alert(e.target.value)
        const orderDataaa = await OrderApi.getOrderListByOrd1Id(e.target.value);
        // const cancelData = await OrderApi.getCancelItemByOrd1Id(e.target.value);

        const data = orderDataaa.item1;
        console.log("order1Data", data);

        const updatedValues = {
            ...getDeliveryAndTimeSlotId,
            orderType: orderData.serviceTypeId,
            deliveryPointName: 1,
            distance: 0,
            isCurrentDate: false,
        };

        await fetchTimeSlot(updatedValues);

        if (data.length > 0) {
            setRunningOrder([]);
            setRunningOrderOverAll([]);

            const newDataItems = data.map((item, index) => ({
                ...item,
                d2Id: item.d2ItemId,
                name: item.d2ItemName,
                barcode: item.defaultBarcode,
                image: "",
                salePrice: item.rate,
                variableItem: true,
                preferenceNo: item.preferenceNo,
                quantity: item.qty,
                color: "white",
                mrpPrice: 0,
            }));

            setComments(prevComments => [
                ...prevComments,
                ...data.map(item => ({
                    "d2Id": item.d2ItemId,
                    "commentName": item.itemSuggestion
                }))
            ]);
            console.log("runningOrder", newDataItems);
            setRunningOrder(newDataItems);
            setRunningOrderOverAll(newDataItems);
            // setCancelData(cancelData);

            let total = 0;

            for (let i = 0; i < newDataItems.length; i++) {
                const quantity = newDataItems[i].quantity || 0;
                const price = parseFloat(quantity * newDataItems[i].salePrice);
                if (!isNaN(price)) {
                    total += price;
                }
            }
            for (let i = 0; i < cartData.length; i++) {
                const price = parseFloat(cartData[i].quantity * cartData[i].salePrice);
                if (!isNaN(price)) {
                    total += price;
                }
            }
            setTotalAmount(total);
            if (cartData && cartData.length > 0) {
                setOrd1TotalCancelAmount(cartData[0].o1CancelledAmt);
                setTotalAmount(total - cartData[0].o1CancelledAmt);
            }
            else if (newDataItems && newDataItems.length) {
                setOrd1TotalCancelAmount(newDataItems[0].o1CancelledAmt);
                setTotalAmount(total - newDataItems[0].o1CancelledAmt);
            }

            setOrderData((prevValues) => ({
                ...prevValues,
                "ord1Id": data[0].ord1Id,
                "noOfChild": data[0].noChildPerson,
                "noOfAdult": data[0].noAdultPerson,
                "orderSuggestion": data[0].orderSuggestion,
                "orderSeriesId": data[0].orderSeriesId,
                "orderNo": data[0].orderNo,
                "autoOrderNo": 0,
                "serviceTypeId": data[0].serviceTypeId,
                "overallOrderStatus": data[0].overallOrderStatus,
                "d1AccountId": data[0].d1AccId,
                "d2AccountId": data[0].accId,
                "billTypeId": data[0].billTypeId,
                "tableGroupId": data[0].tableGroupId,
                "tableId": data[0].tableId,
                "noOfPerson": data[0].noOfPerson,
                "totalAmt": data[0].o1TotalAmt,
                "o1CancelledAmt": data[0].o1CancelledAmt,
                // "initialOrderTotalAmt": data[0].initialOrderTotalAmt,
                "grandTotalAmt": data[0].grandTotal,
                "balanceAmt": data[0].balanceAmt,
                "couponCode": data[0].couponCode,
                "couponCodeAmount": data[0].couponCodeAmount,
                "orderDate": data[0].orderDate,
                "orderTime": data[0].orderTime,
                "orderConfirmedBy": data[0].orderConfirmedById,
                "orderConfirmedOn": data[0].orderConfirmedOn,
                "orderDoneFrom": data[0].orderDoneById,
                "deliveryCharges": data[0].deliveryCharges,
                "deliveryDate": data[0].deliveryDate,
                "deliveryTimeId": data[0].deliveryTimeId,
                "deliveryStatus": data[0].deliveryStatus,
                // "orderPrintStatus": data[0].orderPrintStatus,
                "paymentStatus": data[0].paymentStatus,
                "paymentAmount": data[0].paymentAmount,
                "returnAmount": data[0].returnAmount,
                // "discountAmount": data[0].discountAmount,
                "paymentDoneBy": data[0].paymentDoneById,
                "paymentDoneOn": data[0].paymentDoneOn,
                "paymentAttemptedCounter": data[0].paymentAttemptedCounter,
                "paymentMode": data[0].paymentMode,
                "paymentBank": data[0].paymentBank,
                // "paymentRemarks": data[0].paymentRemarks,
                "remarks": data[0].remarks,
                "deliveryBoyId": data[0].deliveryBoyId,
                "kotDispatchTargetTime": data[0].kotDispatchTargetTime,
                "latitude": data[0].latitude,
                "longitude": data[0].longitude,

                "name": data[0].name,
                "mobileNo": data[0].mobileNo,
                "companyGSTNo": data[0].companyGSTNo,
                "companyDetails": data[0].companyDetails,
            }));
            // ******************* SetOrder2 ********************
            setUpdatedId(true);
            setOrder2([
                ...order2,
                {
                    "godownId": data[0].iGodownId,
                    "d1ItemId": 0,
                    "d2ItemId": data[0].d2ItemId,
                    "qty": data[0].qty,
                    "rate": data[0].rate,
                    "totalAmt": data[0].o2TotalAmt,
                    "kotStatus": data[0].o2KotStatus,
                    "preferenceNo": data[0].preferenceNo,
                    "orderDate": data[0].orderDate,
                    "orderTime": data[0].orderTime,
                    "taxableAmount": data[0].o2TotalAmt,
                    "cgstPer": 0,
                    "cgstValue": 0,
                    "sgstPer": 0,
                    "sgstValue": 0,
                    "igstPer": 0,
                    "igstValue": 0,
                    "cessPer": 0,
                    "cessValue": 0,
                    "totalTaxableAmt": 0,
                    "isLocal": true,
                    "isInclusive": true,
                    "itemSuggestion": data[0].itemSuggestion,
                    "itemRunningOrderCounter": data[0].itemRunningOrderCounter,
                    "orderRunningQty": data[0].orderRunningQty,
                    "itemRunningQty": data[0].itemRunningQty,
                    "hasComplementryItem": true,
                    "cookStatus": "string",
                    "itemIncentivePoint": 0,
                    "isActive": data[0].isActive,
                    "color": "grey"
                }
            ]);

            setInputValues({
                name: data[0].accName,
                aMobileNo: data[0].accMobileNo,
                adob: data[0].accDOB,
                adom: data[0].accDOM,
                referralBy: data[0].accReferredById,
                aAddress: data[0].accAddress,
                //createdOn: ''
            })
            setInputItemValue(data[0].accName);
        }
        setLoading(false)

    }

    const onFloorSelect = async (value) => {
        setLoading(true)
        const fieldName = "tableGroupId";
        const operatorName = "Equal";
        const compareValue = value;

        const data = await TableApi.getTableFromTableGroup(fieldName, operatorName, compareValue);
        setTableNumberData(data);

        setOrderData((prevValues) => ({
            ...prevValues,
            "tableGroupId": value,
        }));
        setLoading(false)
    }

    const [updatedId, setUpdatedId] = useState(false);
    //const [previousData, setPreviousData] = useState();
    async function getCurrentDate() {
        const isoString = await CurrentDateAndTime.getCurrentDate();
        const currentDate = isoString;
        return currentDate;
    }

    const onTableSelect = async (val) => {
        setLoading(true)
        const tableId = val;
        // alert(val)
        var tableGroup = 0;
        if (tableId != 0) {
            tableGroup = await Table.getTableById(tableId);
        }
        setOrderData((prevValues) => ({
            ...prevValues,
            "tableId": tableId,
            "tableGroupId": tableGroup.tableGroupId,
        }));

        setPaymentAmtDetails([]);
        setInputValues1([]);
        setIsRunningOrderChange(false);

        const data = await OrderApi.getOrderListByTableId(tableId);
        // if (data && data.length > 0) {
        //     const cancelData = await OrderApi.getCancelItemByOrd1Id(data[0].ord1Id);
        //     setCancelData(cancelData);
        // }
        console.log("order1Data", data);

        const updatedValues = {
            ...getDeliveryAndTimeSlotId,
            orderType: 751,
            deliveryPointName: 1,
            distance: 0,
            isCurrentDate: false,
        };

        await fetchTimeSlot(updatedValues);

        if (data && data.length > 0) {    //data !== null || 
            //setCartData([]);
            setRunningOrder([]);
            setRunningOrderOverAll([]);

            const newDataItems = data.map((item, index) => ({
                ...item,
                d2Id: item.d2ItemId,
                name: item.d2ItemName,
                barcode: item.defaultBarcode,
                image: "",
                salePrice: item.rate, // item.qty * item.rate,
                variableItem: true,
                preferenceNo: item.preferenceNo,
                quantity: item.qty,
                color: "white",
                mrpPrice: 0,
                //(() => {
                // if (item.itemRunningOrderCounter === 0 || item.itemRunningOrderCounter == null || item.itemRunningOrderCounter == undefined) {
                //     return "rgba(255, 10, 10, 0.16)";  //red
                // }else if (item.itemRunningOrderCounter === 0 || item.itemRunningOrderCounter == null || item.itemRunningOrderCounter == undefined) {
                //     return "rgba(10, 14, 255, 0.16)";  //blue
                // } else if (item.itemRunningOrderCounter === 1) {
                //     return "#f0b93080"; //yellow
                // } else if (item.itemRunningOrderCounter === 2) {
                //     return "rgba(227, 19, 175, 0.35)"; //pink
                // } else if (item.itemRunningOrderCounter === 3) {
                //     return "#00800036"; //green
                // } else if (item.itemRunningOrderCounter === 4) {
                //     return "rgba(246, 110, 63, 0.4)"; //orange
                // }
                //return "rgba(255, 10, 10, 0.16)";
                //})(),
            }));

            setComments(prevComments => [
                ...prevComments,
                ...data.map(item => ({
                    "d2Id": item.d2ItemId,
                    "commentName": item.itemSuggestion
                }))
            ]);

            //setPreviousData(newDataItems);
            //setCartData(prevCartData => [...prevCartData, ...newDataItems]);
            console.log("runningOrder", newDataItems);
            setRunningOrder(newDataItems);
            setRunningOrderOverAll(newDataItems);

            let total = 0;

            for (let i = 0; i < newDataItems.length; i++) {
                const quantity = newDataItems[i].quantity || 0;
                const price = parseFloat(quantity * newDataItems[i].salePrice);
                if (!isNaN(price)) {
                    total += price;
                }
            }
            for (let i = 0; i < cartData.length; i++) {
                const price = parseFloat(cartData[i].quantity * cartData[i].salePrice);
                if (!isNaN(price)) {
                    total += price;
                }
            }
            console.log("total", total);
            setTotalAmount(total);
            if (cartData && cartData.length > 0) {
                setOrd1TotalCancelAmount(cartData[0].o1CancelledAmt);
                setTotalAmount(total - cartData[0].o1CancelledAmt);
            }
            else if (newDataItems && newDataItems.length) {
                setOrd1TotalCancelAmount(newDataItems[0].o1CancelledAmt);
                setTotalAmount(total - newDataItems[0].o1CancelledAmt);
            }
            // alert(total);
            // setCartData([...cartData, ...newDataItems]);

            console.log("tableGroupId", data[0]?.tableGroupId, "tableId", data[0]?.tableId);

            setOrderData((prevValues) => ({
                ...prevValues,
                "ord1Id": data[0].ord1Id,
                "noOfChild": data[0].noChildPerson,
                "noOfAdult": data[0].noAdultPerson,
                "orderSuggestion": data[0].orderSuggestion,
                "orderSeriesId": data[0].orderSeriesId,
                "orderNo": data[0].orderNo,
                "autoOrderNo": 0,
                "serviceTypeId": data[0].serviceTypeId,
                "overallOrderStatus": data[0].overallOrderStatus,
                "d1AccountId": data[0].d1AccId,
                "d2AccountId": data[0].accId,
                "billTypeId": data[0].billTypeId,
                "tableGroupId": data[0].tableGroupId,
                "tableId": data[0].tableId,
                "noOfPerson": data[0].noOfPerson,
                "totalAmt": data[0].o1TotalAmt,
                // "initialOrderTotalAmt": data[0].initialOrderTotalAmt,
                "grandTotalAmt": data[0].grandTotal,
                "o1CancelledAmt": data[0].o1CancelledAmt,
                "balanceAmt": data[0].balanceAmt,
                "couponCode": data[0].couponCode,
                "couponCodeAmount": data[0].couponCodeAmount,
                "orderDate": data[0].orderDate,
                "orderTime": data[0].orderTime,
                "orderConfirmedBy": data[0].orderConfirmedById,
                "orderConfirmedOn": data[0].orderConfirmedOn,
                "orderDoneFrom": data[0].orderDoneById,
                "deliveryCharges": data[0].deliveryCharges,
                "deliveryDate": data[0].deliveryDate,
                "deliveryTimeId": data[0].deliveryTimeId,
                "deliveryStatus": data[0].deliveryStatus,
                // "orderPrintStatus": data[0].orderPrintStatus,
                "paymentStatus": data[0].paymentStatus,
                "paymentAmount": data[0].paymentAmount,
                "returnAmount": data[0].returnAmount,
                // "discountAmount": data[0].discountAmount,
                "paymentDoneBy": data[0].paymentDoneById,
                "paymentDoneOn": data[0].paymentDoneOn,
                "paymentAttemptedCounter": data[0].paymentAttemptedCounter,
                "paymentMode": data[0].paymentMode,
                "paymentBank": data[0].paymentBank,
                // "paymentRemarks": data[0].paymentRemarks,
                "remarks": data[0].remarks,
                "deliveryBoyId": data[0].deliveryBoyId,
                // "deliveryBoyDispatchTime": data[0].deliveryBoyDispatchTime,
                // "deliveryBoyTripNo": data[0].deliveryBoyTripNo,
                // "deliveryBoyTripIncentive": data[0].deliveryBoyTripIncentive,
                // "deliveryReferenceNo": data[0].deliveryReferenceNo,
                // "deliveryPreferenceNo": data[0].deliveryPreferenceNo,
                // "deliveryTripStatus": data[0].deliveryTripStatus,
                "kotDispatchTargetTime": data[0].kotDispatchTargetTime,
                "latitude": data[0].latitude,
                "longitude": data[0].longitude,

                "name": data[0].name,
                "mobileNo": data[0].mobileNo,
                "companyGSTNo": data[0].companyGSTNo,
                "companyDetails": data[0].companyDetails,
                // "currencyId": data[0].currencyId,
            }));
            // ******************* SetOrder2 ********************
            setUpdatedId(true);
            setOrder2([
                ...order2,
                {
                    "godownId": data[0].iGodownId,
                    "d1ItemId": 0,
                    "d2ItemId": data[0].d2ItemId,
                    "qty": data[0].qty,
                    "rate": data[0].rate,
                    "totalAmt": data[0].o2TotalAmt,
                    "kotStatus": data[0].o2KotStatus,
                    "preferenceNo": data[0].preferenceNo,
                    "orderDate": data[0].orderDate,
                    "orderTime": data[0].orderTime,
                    "taxableAmount": data[0].o2TotalAmt,
                    "cgstPer": 0,
                    "cgstValue": 0,
                    "sgstPer": 0,
                    "sgstValue": 0,
                    "igstPer": 0,
                    "igstValue": 0,
                    "cessPer": 0,
                    "cessValue": 0,
                    "totalTaxableAmt": 0,
                    "isLocal": true,
                    "isInclusive": true,
                    "itemSuggestion": data[0].itemSuggestion,
                    "itemRunningOrderCounter": data[0].itemRunningOrderCounter,
                    "orderRunningQty": data[0].orderRunningQty,
                    "itemRunningQty": data[0].itemRunningQty,
                    "hasComplementryItem": true,
                    "cookStatus": "string",
                    "itemIncentivePoint": 0,
                    "isActive": data[0].isActive,
                    "color": "grey"
                    // d2Id: data[0].d2Id,
                    // orderDate: data[0].orderDate,
                    // orderTime: data[0].orderTime,
                    // orderDoneBy: data[0].orderDoneById,
                    // orderDoneOn: data[0].orderDoneOn,
                    // orderConfirmedBy: data[0].orderConfirmedById,
                    // orderConfirmedOn: data[0].orderConfirmedOn,
                    // // orderDoneFrom: "",
                    // // d1AccountId: 0,
                    // d2AccountId: data[0].accId,
                    // billTypeId: data[0].billTypeId,
                    // tableGroupId: data[0].tableGroupId,
                    // tableId: data[0].tableId,
                    // noOfPerson: data[0].noOfPersons,
                    // noOfAdult: data[0].noAdultPersons,
                    // noOfChild: data[0].noChildPersons,
                    // couponCode: "",
                    // couponCodeAmount: 0,
                    // deliveryCharges: data[0].deliveryCharges,
                    // deliveryDate: data[0].deliveredDate,
                    // deliveryTimeId: data[0].deliveryTimeId,
                    // deliveryStatus: data[0].deliveryStatus,
                    // deliveryBoyId: data[0].deliveryBoyId,
                    // deliveryBoyDispatchTime: "00:00:00",
                    // deliveryBoyTripNo: 0,
                    // deliveryBoyTripIncentive: 0,
                    // deliveryReferenceNo: data[0].accReferredById,
                    // deliveryPreferenceNo: data[0].preferenceNo,
                    // deliveryTripStatus: "",
                    // orderPrintStatus: data[0].o3OrderStatus,
                    // totalAmt: data[0].o1TotalAmt,
                    // initialOrderTotalAmt: 0,
                    // grandTotalAmt: data[0].grandTotal,
                    // paymentStatus: data[0].paymentStatus,
                    // paymentAmount: 0,
                    // returnAmount: 0,
                    // discountAmount: 0,
                    // paymentDoneBy: 0,
                    // paymentDoneOn: "",
                    // paymentAttemptedCounter: 0,
                    // paymentMode: data[0].paymentMode,
                    // paymentBank: "",
                    // paymentRemarks: "",
                    // remarks: "",
                    // orderSuggestion: "",
                    // kotDispatchTargetTime: "",  // deliveryTime
                    // latitude: "",
                    // longitude: "",
                    // currencyId: data[0].currencyId,
                    // hasRunningOrder: data[0].hasRunningOrder,
                    // runningOrderCounter: data[0].runningOrderCounter,
                    // runningOrderTotalAmt: data[0].runningOrderTotalAmt,
                    // ord2Id: data[0].ord2Id,
                }
            ]);

            setInputValues({
                name: data[0].accName,
                aMobileNo: data[0].accMobileNo,
                adob: data[0].accDOB,
                adom: data[0].accDOM,
                referralBy: data[0].accReferredById,
                aAddress: data[0].accAddress,
                //createdOn: ''
            })
            setInputItemValue(data[0].accName);
        }
        else {
            console.log("else");
            //ClearDataFunction(tableId);
        }
        setLoading(false)
    }

    const [tableGroup, setTableGroup] = useState();

    const fetchTableGroupData = async () => {
        try {
            setLoading(true)
            const data = await TableGroupApi.getTableGroup();
            console.log(data);
            setTableGroup(data);
            const tableGroupId = localStorage.getItem("tableGroupId");
            if (tableGroupId && tableGroupId != 0) {
                onTableGroupSelection(tableGroupId)
            }
            else if (data && data.length > 0) {
                onTableGroupSelection(data[0].id)
            }
            setLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false)
        }
    };

    const [orderNoData, setOrderNoData] = useState();

    const fetchOrderData = async (serviceTypeId) => {
        try {
            setLoading(true);
            const filteredData = [
                {
                    fieldName: "serviceTypeId",
                    operatorName: "Equal",
                    compareValue: serviceTypeId
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "Equal",
                    compareValue: "Pending"
                },
                // {
                //     fieldName: "overallOrderStatus",
                //     operatorName: "NotEqual",
                //     compareValue: "Done"
                // },
                // {
                //     fieldName: "overallOrderStatus",
                //     operatorName: "NotEqual",
                //     compareValue: "Returned"
                // },
                // {
                //     fieldName: "overallOrderStatus",
                //     operatorName: "NotEqual",
                //     compareValue: "Cancel"
                // },
                // {
                //     fieldName: "overallOrderStatus",
                //     operatorName: "NotEqual",
                //     compareValue: "Delivered"
                // },
                // {
                //     fieldName: "overallOrderStatus",
                //     operatorName: "NotEqual",
                //     compareValue: "Return Request"
                // },
            ]
            // const data = await OrderApi.getOrderReportFilter(filteredData)
            const orderData1 = await OrderApi.getRawOrderReport(filteredData)
            // var summary = orderData1.summary

            var data = orderData1.orderList
            setOrderNoData(data)
            setLoading(false)
        }
        catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchOrderData(754);
    }, [])

    const [serviceType, setServiceType] = useState();
    const [billseries, setBillSeries] = useState();
    const [paymentDetails, setPaymentDetails] = useState([]);

    const fetchBillTypeData = async () => {
        const filteredData = [
            {
                fieldName: "IsDefault",
                operatorName: "Equal",
                compareValue: true
            }
        ]
        try {
            setLoading(true)
            const data = await OrderApi.getLastOrderCounterByUser();
            const updatedData = await BillTypeApi.GetFilteredBillType(filteredData);

            if (data?.length > 0) {
                const orderNo = await OrderApi.getBillSeriesCounter(data[0].orderSeriesId);
                setOrderData((prevValues) => ({
                    ...prevValues,
                    "billTypeId": updatedData[0].id, //585
                    "orderSeriesId": data[0].orderSeriesId,
                    "orderNo": (Number(orderNo) + 1),
                    "autoOrderNo": (Number(orderNo) + 1),
                }));
                //alert(data[0].orderSeriesId)
                const paymentMode = await BillPaymentMode.getvoucherPaymentModeId(data[0].orderSeriesId);
                setPaymentDetails(paymentMode);
                setLoading(false);
            }
            else {
                const billSeries = await BillSeriesMasterApi.getBillSeriesMasterFiltered(filteredData);

                if (billSeries && billSeries.length > 0) {
                    const orderNo = await OrderApi.getBillSeriesCounter(billSeries[0].id);
                    setOrderData((prevValues) => ({
                        ...prevValues,
                        "billTypeId": updatedData[0].id, //585
                        "orderSeriesId": billSeries[0].id,
                        "orderNo": (Number(orderNo) + 1),
                        "autoOrderNo": (Number(orderNo) + 1),
                    }));
                    const paymentMode = await BillPaymentMode.getvoucherPaymentModeId(billSeries[0].id);
                    setPaymentDetails(paymentMode);
                }
                setLoading(false);
            }
        }
        catch (error) {
            console.log(error);
            setLoading(false)
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchBillTypeData();
    }, [])

    const billSeriesChange = async (val) => {
        setLoading(true)
        const orderNo = await OrderApi.getBillSeriesCounter(val);
        // alert(val)
        const paymentMode = await BillPaymentMode.getvoucherPaymentModeId(val);
        setPaymentDetails(paymentMode);
        console.log(paymentMode);
        setRunningOrder([]);
        setRunningOrderOverAll([]);
        setOrderData((prevValues) => ({
            ...prevValues,
            // "billTypeId": updatedData[0].id, //585
            "orderSeriesId": val,
            "orderNo": (Number(orderNo) + 1),
            "autoOrderNo": (Number(orderNo) + 1),
            "ord1Id": 0,
        }));
        // setOrderData((prevValues) => ({
        //     ...prevValues,
        //     "ord1Id": 0,
        // }));
        setLoading(false)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const data = await OrderApi.getServiceType();
                // const userData = UserDetails;
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                const loginUser = JSON.parse(storedArrayString);

                const userData = await UserCredential.getUserCredentialById(loginUser.userId);

                const serviceDataIds = userData && userData.serviceType.split(", ").map(id => id.trim());

                const filteredData = data.filter(item => serviceDataIds.includes(String(item.id)));

                setServiceType(filteredData);

                let serviceType = localStorage.getItem("serviceTypeId");
                const tableGroupId = localStorage.getItem("tableGroupId");
                if (filteredData.length > 0) {
                    if (serviceType) {
                        let btnIndex = filteredData.findIndex(item => item.id == serviceType);
                        console.log("serviceType", serviceType);
                        let index = 0;
                        if (btnIndex == -1) {
                            serviceType = filteredData[0].id
                            index = 1;
                            btnIndex = 1;
                            if (filteredData[0].id == 751) {
                                setShowInTable(true);
                                setShowInOtherServiceType(false);
                                onTableGroupSelection(tableGroupId)
                            }
                            else {
                                setShowInOtherServiceType(true);
                                setShowInTable(false);
                            }
                        }
                        else if (serviceType == 751) {
                            index = 1;
                            setShowInTable(true);
                            setShowInOtherServiceType(false);
                            onTableGroupSelection(tableGroupId)
                        }
                        else if (serviceType == 752) {
                            index = 2
                            setShowInOtherServiceType(true);
                            setShowInTable(false);
                        }
                        else if (serviceType == 753) {
                            index = 3
                            setShowInOtherServiceType(true);
                            setShowInTable(false);
                        }
                        else if (serviceType == 754) {
                            index = 4
                            setShowInOtherServiceType(true);
                            setShowInTable(false);
                        }
                        handleButtonClick(index, serviceType)
                        getButtonStyle(btnIndex + 1);
                        setActiveButton(btnIndex + 1);
                        // localStorage.removeItem("serviceTypeId")
                        // localStorage.removeItem("tableGroupId")
                    }
                    else {
                        setShowInTable(true);
                        setShowInOtherServiceType(false);
                        let index = 0;
                        if (filteredData[0].id == 751) {
                            index = 1;
                        }
                        else if (filteredData[0].id == 752) {
                            index = 2;
                        }
                        else if (filteredData[0].id == 753) {
                            index = 3;
                        }
                        else if (filteredData[0].id == 754) {
                            index = 4;
                        }
                        handleButtonClick(index, filteredData[0].id)
                        getButtonStyle(1);
                        setActiveButton(index);
                        localStorage.setItem("serviceTypeId", filteredData[0].id)
                        if (tableGroupId && tableGroupId != 0) {
                            onTableGroupSelection(tableGroupId)
                            localStorage.setItem("tableGroupId", tableGroupId)
                        }
                    }
                }
                else {
                    localStorage.setItem("serviceTypeId", 0);
                }
                setOrderData((prevValues) => ({
                    ...prevValues,
                    "serviceTypeId": serviceType || filteredData[0].id || 0,
                }));
                setLoading(false)

                const updatedValues = {
                    ...getDeliveryAndTimeSlotId,
                    orderType: filteredData[0]?.id || 0,
                    deliveryPointName: 1,
                    distance: 0,
                    isCurrentDate: true,
                };
                setDeliveryAndTimeSlotId((prevState) => ({
                    ...prevState,
                    orderType: filteredData[0]?.id || 0,
                    deliveryPointName: 1,
                    distance: 0,
                    isCurrentDate: true,
                }));
                fetchTimeSlot(updatedValues);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false)
            }
        };

        const fetchData1 = async () => {
            setLoading(true)
            try {
                const data = await BillSeriesMaster.getBillSeriesMaster();
                setBillSeries(data);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false)
            }
        };
        fetchData();
        fetchData1();
    }, []);

    const [searchValue, setSearchValue] = useState('');

    const onHandleKeyPress = (event) => {
        const selectedItem = menuItems.find(item =>
            item.name.toLowerCase().includes(event.target.value.toLowerCase())
        );
        if (event.key === 'Enter') {
            if (selectedItem) {
                const id = selectedItem.d2Id;
                ItemButtonClick(id);
            }
        }
    }
    const onSearchChange = (event) => {
        const searchValue = event.target.value
        setSearchValue(searchValue);

        const filteredItems = menuItems.filter(item =>
            item.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredItems(filteredItems);
    }

    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
        setLoading(true)
        const filteredItems = menuItems.filter(item =>
            item.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredItems(filteredItems);
        console.log(filteredItems);
        setLoading(false)
    }, [searchValue, menuItems]);

    // const [barcode, setBarcode] = useState('');

    const handleCodeChange = (event) => {
        const selectedItem = menuItems.filter(item =>
            item.defaultBarcode.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setFilteredItems(selectedItem);
    }

    const onHandleCodeKeyPress = (event) => {
        const selectedItem = menuItems.find(item =>
            item.defaultBarcode.toLowerCase() == (event.target.value).toLowerCase()
        );
        if (event.key === 'Enter') {
            if (selectedItem) {
                const id = selectedItem.d2Id;
                ItemButtonClick(id);
                console.log(event.target.value);
            }
        }
    }
    // ********************** Get users *******************
    const [users, setUsers] = useState();

    useEffect(() => {
        const filteredData = [
            {
                fieldName: "userRole",
                operatorName: "Equal",
                compareValue: 563             //Order taker Id 
            }
        ]
        const fetchData = async () => {
            setLoading(true)
            try {
                const data = await UserCredentialApi.getFilteredUserCredential(filteredData);
                console.log("users");
                console.log(data);
                setUsers(data);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false)
            }
        };
        fetchData();
    }, []);

    // ********************** Get Item Department *******************
    const [itemDepartment, setItemDepartment] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const data = await ItemDepartmentApi.getItemDepartment();
                console.log(data);
                setItemDepartment(data);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false)
            }
        };
        fetchData();
    }, []);

    const handleItemDepartmentChange = async (e) => {
        setLoading(true);
        let filteredData;
        if (e.target.value == "default") {
            filteredData = [
                {
                    fieldName: "string",
                    operatorName: "string",
                    compareValue: "string"
                },
            ]
        }
        else {
            filteredData = [
                {
                    fieldName: "itemDepartment",
                    operatorName: "Equal",
                    compareValue: e.target.value
                },
                // {
                //     fieldName: "availableForPOS",
                //     operatorName: "Equal",
                //     compareValue: true
                // }
            ]
        }
        try {
            const itemData = await ItemMasterApi.GetItemMasterWithFilter(filteredData)
            setMenuItems(prevItems => [...prevItems, ...itemData]);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
        // setItemGroupList()
    }
    const [imageSrc, setImageSrc] = useState('');

    const loadImage = (element) => {
        if (element && element.src !== imageSrc) {
            setImageSrc(element.src);
        }
    };

    const imageRef = useIntersectionObserver(loadImage, { threshold: 0.1 });

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    const [inputItemValue, setInputItemValue] = useState('');

    const [suggestedItems1, setSuggestedItems1] = useState();

    const [isItemOpen, setIsItemOpen] = useState(false);

    const [ItemSuggestions, setItemSuggestions] = useState();

    //const ItemSuggestions = [{ name: 'user1' }, { name: 'user2' }, { name: 'user3' }, { name: 'user4' }];

    const [itemAvailable, setItemAvailable] = useState(false);

    const handleItemValueChange = (event) => {
        const value = event.target.value;
        setInputItemValue(value);
        setIsItemOpen(true);

        const lowerCaseValue = value.toLowerCase();

        const filteredSuggestions = ItemSuggestions
            .filter(item =>
                // item.parentGroupId == 0 && 
                item.name.toLowerCase().includes(lowerCaseValue)
            );

        setSuggestedItems1(filteredSuggestions);
        console.log(filteredSuggestions);

        if (filteredSuggestions.length > 0) {
            setIsItemOpen(true);
        }
        else {
            setIsItemOpen(false);
            setItemAvailable(true);
            console.log("else");
        }
    };

    const suggestionClose = () => {
        setIsItemOpen(false);
        setItemSuggestionOpen(false);
    }

    const handleItemSuggestion = async (ItemSuggestions, id1, id2) => {
        setInputItemValue(ItemSuggestions);
        setSuggestedItems1([]);
        setIsItemOpen(false);
        setInputValues((prevValues) => ({
            ...prevValues,
            'name': ItemSuggestions,
        }));

        const data = await AccountApi.getAccountById(id1);
        console.log("Cashhh", data);
        setInputValues({
            name: data.item1[0].name,
            aMobileNo: data.item1[0].mobileNo,
            adob: data.item1[0].dob,
            adom: data.item1[0].dom,
            aAddress: data.item1[0].address,
            referralBy: data.item1[0].referralById,
        })

        setOrderData((prevValues) => ({
            ...prevValues,
            "d1AccountId": id1,
            "d2AccountId": id2,
            "deliveryAddress": "",
            "name": data.item1[0].name,
            "mobileNo": data.item1[0].mobileNo,
            "companyGSTNo": data.item1[0].gStINNo,
            "companyDetails": data.item1[0].accountDetails,
        }));
        console.log("referral id", data.item1[0].referralById);
    };

    const handleClick = (e) => {
        e.stopPropagation();
    };

    const [inputValues, setInputValues] = useState({
        name: '',
        aMobileNo: '',
        adob: '',
        adom: '',
        referralBy: 0,
        aAddress: '',
        createdOn: ''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        // event.target.setAttribute("autocomplete", "off");

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));

        if (name === 'name') {
            handleItemValueChange(event);
            setOrderData((prevValues) => ({
                ...prevValues,
                "name": value,
            }));
        }
        else if (name == "aMobileNo") {
            setOrderData((prevValues) => ({
                ...prevValues,
                "mobileNo": value,
            }));
        }
        // if (name === 'noOfAdult' || name === 'noOfChild') {
        //     const val = Number(orderData.noOfAdult) + Number(orderData.noOfChild);
        //     console.log(val +"=" +Number(orderData.noOfAdult), Number(orderData.noOfChild));
        //     setInputValues((prevValues) => ({
        //         ...prevValues,
        //         "noOfPerson": val,
        //     }));
        // }
        else if (name === 'couponCode') {
            setInputValues((prevValues) => ({
                ...prevValues,
                "couponCodeAmount": (orderData.totalAmt * 20 / 100),
            }));
        }
        console.log(inputValues);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setOrderData((prevValues) => {
            const updatedValues = {
                ...prevValues,
                [name]: value,
            };

            if (name === 'noOfAdult' || name === 'noOfChild') {
                const noOfAdult = name === 'noOfAdult' ? Number(value) : Number(prevValues.noOfAdult);
                const noOfChild = name === 'noOfChild' ? Number(value) : Number(prevValues.noOfChild);
                updatedValues.noOfPerson = noOfAdult + noOfChild;
                console.log(`${updatedValues.noOfPerson} = ${noOfAdult} + ${noOfChild}`);
            }
            else if (name === 'couponCode') {
                updatedValues.couponCodeAmount = (Number(updatedValues.totalAmt) * 20) / 100;
            }

            return updatedValues;
        });
    };
    //const [newOrder, setNewOrder] = useState();

    const formatDate = async () => {
        const isoString = await CurrentDateAndTime.getCurrentDate();
        return isoString;
    }

    const formatedTime = async () => {
        const isoString = await CurrentDateAndTime.getCurrentTime();
        return isoString;
    }

    const [showPrintModal, setShowPrintModal] = useState(false);

    const closePrintModal = () => {
        setShowPrintModal(false)
        // window.location.reload();
    }

    const [AllocatedTimeSlots, setAllocatedTimeSlots] = useState([]);

    const fetchTimeSlot = async (updatedValues) => {
        try {
            const data = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlots(updatedValues);
            setAllocatedTimeSlots(data);
            console.log("timeSlot", data);
            return data;
        }
        catch (error) {
            console.log(error);
            return false;
        }
    }

    const subtract30Minutes = (timeString) => {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);

        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(seconds);

        date.setMinutes(date.getMinutes() - 30);

        const updatedHours = String(date.getHours()).padStart(2, '0');
        const updatedMinutes = String(date.getMinutes()).padStart(2, '0');
        const updatedSeconds = String(date.getSeconds()).padStart(2, '0');

        return `${updatedHours}:${updatedMinutes}:${updatedSeconds}`;
    }

    // ************************************** Save Order (Confirm & Kot)***********************************
    const [isButtonVisible, setIsButtonVisible] = useState(true);

    const ConfirmAndKot = async (printStatus, overAllStatus, cartDataStatus) => {
        setIsButtonVisible(false);
        setTimeout(() => {
            setIsButtonVisible(true);
        }, 3000);

        setLoading(true)
        if (runningOrderOverAll && runningOrderOverAll.length > 0) {
            runningOrderUpdate();
            if (cartData.length == 0) {
                alert("Updated");
                setLoading(false)
                return 0;
            }
        } else {
            if (cartData.length == 0) {
                alert("Please add atleast one item");
                setLoading(false);
                return 0;
            }
        }
        if (inputValues.name == "" || orderData.d1AccountId == 0) {
            setInputItemValue("");
            alert("Please enter customer name");
            setLoading(false);
            input1Ref.current.focus();
            return 0;
        }
        const serviceType = localStorage.getItem("serviceTypeId");
        if (orderData.serviceTypeId == 0 || serviceType == 0 || serviceType == null || serviceType == undefined) {
            alert("Please select Service Type");
            setLoading(false);
            return false;
        }
        if (orderData.serviceTypeId == 751) {
            if (orderData.tableId == 0 || orderData.tableId == "") {
                alert("Please select Table");
                setLoading(false);
                handleFocusOnTableClick();
                return false;
            }
        }
        if (companyData.isAllowDlvryPreferCheck === true) {
            const filterData = order2.filter(item => item.d1ItemId != 0 && item.d2ItemId != 0);
            const data = filterData.filter(item => item.preferenceNo == 0 || item.preferenceNo == null || item.preferenceNo == "" || item.preferenceNo == undefined)
            if (data && data.length > 0) {
                alert("Please Set Delivery Preference");
                setLoading(false);
                return false;
            }
        }

        let paymentTotalAmt = 0;

        if (paymentAmtDetails && paymentAmtDetails.length > 0) {
            paymentTotalAmt = paymentAmtDetails.reduce((sum, data) => sum + Number(data.paymentAmount), 0);
        }

        const balanceAmount = orderData.grandTotalAmt - (Number(orderData.o1CancelledAmt) + Number(orderData.paymentAmount));

        let paymentStatus = "Pending";

        if (paymentTotalAmt == balanceAmount) { //orderData.grandTotalAmt
            paymentStatus = "Done";
        }
        // else if (paymentTotalAmt > balanceAmount) {
        //     alert("Payment cannot exceed the Actual Amount");
        //     setLoading(false);
        //     return false;
        // }

        const BillData = await BillLayoutSetting.getBillLayoutSettingById(orderData.orderSeriesId);
        console.log("BillData", BillData)

        if (BillData && BillData != null && BillData != undefined && BillData.paymentType == 2) {
            if (paymentTotalAmt != balanceAmount) {
                alert(`Please first pay Balance Amount: ₹${balanceAmount}`);
                setLoading(false)
                return false;
            }
        }
        console.log(order2)
        console.log(orderData)

        const formattedDate1 = await formatDate();
        const kotTime = AllocatedTimeSlots && AllocatedTimeSlots.filter(item => item.id == orderData.deliveryTimeId);

        let formattedTime = await formatedTime();
        if (kotTime) {
            if (kotTime[0]) {
                const time = subtract30Minutes(kotTime[0]?.availableFrom);
                formattedTime = time;
            }
        }
        const formattedDate = await CurrentDateAndTime.getCurrentDateTime();

        // ******************************
        const filteredData = [
            {
                fieldName: "IsDefault",
                operatorName: "Equal",
                compareValue: true
            }
        ]
        const updatedData = await BillTypeApi.GetFilteredBillType(filteredData)
        const billSeries = await BillSeriesMasterApi.getBillSeriesMasterFiltered(filteredData)
        const orderNo = await OrderApi.getBillSeriesCounter(orderData.orderSeriesId);
        console.log("billTypeId-", updatedData[0].id)

        // ******************************
        let ord1Id;
        if (itemAvailable !== false) {
            const UpdatedInputValues = {
                ...inputValues,
                "createdOn": formattedDate,
            };
            setInputValues((prevValues) => ({
                ...prevValues,
                "createdOn": formattedDate,
            }));
            console.log("Add Account");
            const data = await AccountApi.insertAccount(UpdatedInputValues);
            setItemAvailable(false);

            console.log(data)
            const dataString = data?.result?.result;
            console.log(dataString);

            let keyValuePairs = [];
            if (dataString) {
                keyValuePairs = dataString.split(',');
            }

            let d1id, d2id;
            keyValuePairs.forEach(pair => {
                try {
                    const [key, value] = pair.split(':');
                    const trimmedKey = key.trim();
                    const trimmedValue = value.trim();
                    if (trimmedKey === 'd1id') {
                        d1id = parseInt(trimmedValue);
                    } else if (trimmedKey === 'd2id') {
                        d2id = parseInt(trimmedValue);
                    }
                }
                catch (error) {
                    console.log(error)
                }
            });
            const accountData = await AccountApi.getAccountById(d1id);

            let billseriesId = updatedValues.orderSeriesId;
            if (updatedValues.orderSeriesId == 0) {
                billseriesId = billSeries[0].id;
            }

            let overallOrderStatus;
            if (overAllStatus == true) {
                overallOrderStatus = "Pending";
            }
            else {
                overallOrderStatus = "Confirm";//updatedValues.overallOrderStatus;
            }

            const updatedOrderData = {
                ...orderData,
                "billTypeId": updatedData[0].id, //585
                "orderSeriesId": billseriesId,
                // "orderNo": (Number(orderNo) + 1),
                // "autoOrderNo": (Number(orderNo) + 1),
                "paymentStatus": paymentStatus,
                "paymentAmount": orderData.paymentAmount + paymentTotalAmt,
                "overallOrderStatus": overallOrderStatus,
                "d1AccountId": d1id || 0,
                "d2AccountId": accountData.item1[0]?.d2Id, //(accountData.item1).length > 0 ? accountData.item1[0]?.d2Id : 0,
                "name": accountData.item1[0]?.name,
                "mobileNo": accountData.item1[0]?.mobileNo,
                "companyGSTNo": accountData.item1[0]?.gStINNo,
                "companyDetails": accountData.item1[0]?.accountDetails,

                "orderDate": formattedDate1,
                "orderTime": formattedTime,
                "orderDoneOn": formattedDate,
                "orderConfirmedOn": formattedDate,
                "deliveryDate": formattedDate1,
                //"deliveryTime": formattedTime,
                "paymentDoneOn": formattedDate,
                "kotDispatchTargetTime": formattedTime,
                // "orderConfirmedBy": loginUserDetails.id,
            };

            const updatedOrders = order2.map(item => ({
                ...item,
                "orderDate": formattedDate1,
                "orderTime": formattedTime
            }));

            console.log("updatedOrderData");
            console.log(updatedOrderData);

            const updatedOrder3 = {
                "orderStatus": "Received",
                "orderDateTime": formattedDate,
            };
            setLoading(true);

            if (updatedOrderData.serviceTypeId == 751) {
                const tableStatus = await Table.checkTableStatus(updatedOrderData.tableId);
                if (tableStatus == "Busy") {
                    alert("This table is already Busy. Please select another table");
                    setLoading(false);
                    return false;
                }
                setLoading(false);
            }
            const filteredOrders = updatedOrders.filter(order =>
                cartData.some(cartItem =>
                    order.d1ItemId === cartItem.d1Id && order.d2ItemId === cartItem.d2Id
                )
            );
            var success = await OrderApi.insertOrder(updatedOrderData, filteredOrders, updatedOrder3);
            const id = success.result.order1.id;
            const orderNo = success.result.order1.orderNo;
            ord1Id = id;
            if (success.isSuccess === true) {
                alert(`Thanks for Your Order & Your Order no is ${orderNo}`);
                setLoading(false);
            }
            else {
                alert("Error while Inserting Order");
                setLoading(false);
            }
            // ******************** Insert Payment Mode **********************
            if (paymentAmtDetails && paymentAmtDetails.length > 0) {
                const updatedData = paymentAmtDetails.map((item, index) => ({
                    ...item,
                    ord1Id: ord1Id
                })).filter(item => item.paymentAmount != 0);

                setPaymentAmtDetails(updatedData);
                await Order.createPaymentModeWithOrder(updatedData);
            }
            setLoading(false)
            localStorage.setItem("serviceTypeId", updatedOrderData.serviceTypeId);
            localStorage.setItem("tableGroupId", updatedOrderData.tableGroupId);
            if (isSliderOpen_bottomUp == true) {
                setIsSliderOpen_bottomUp(false);
            }

            // ******************* Print *********************
            // if (ord1Id != null && ord1Id != undefined && ord1Id != 0 && ord1Id != "") {
            //     const data = await OrderApi.getOrderListByOrd1Id(ord1Id);
            //     console.log("SaveAndKot", data.item1)
            //     setOrderListData(data.item1);
            //     setShowPrintModal(true);
            // }
            setShowPrintModal(true)
            return ord1Id;
        }
        // ***************************
        let billseriesId = orderData.orderSeriesId;
        if (orderData.orderSeriesId == 0) {
            billseriesId = billSeries[0].id;
        }

        let overallOrderStatus;
        if (overAllStatus == true) {
            overallOrderStatus = "Pending";
        }
        else {
            overallOrderStatus = "Confirm";//orderData.overallOrderStatus;
        }

        const updatedValues = {
            ...orderData,
            "orderDate": formattedDate1,
            "orderTime": formattedTime,
            "orderDoneOn": formattedDate,
            "orderConfirmedOn": formattedDate,
            "deliveryDate": formattedDate1,
            "orderSeriesId": billseriesId,
            //"deliveryTime": formattedTime,
            "paymentDoneOn": formattedDate,
            "kotDispatchTargetTime": formattedTime,
            "billTypeId": updatedData[0].id, //585
            // "orderSeriesId": billSeries[0].id,
            "orderNo": (Number(orderNo) + 1),
            "autoOrderNo": (Number(orderNo) + 1),
            "paymentStatus": paymentStatus,
            "paymentAmount": paymentTotalAmt,
            // "orderConfirmedBy": loginUserDetails.id,
            "overallOrderStatus": overallOrderStatus,
        };

        let total = 0;

        for (let i = 0; i < cartData.length; i++) {
            const price = parseFloat(cartData[i].quantity * cartData[i].salePrice);
            if (!isNaN(price)) {
                total += price;
            }
        }

        let runningQty = 0;

        for (let i = 0; i < cartData.length; i++) {
            const qty = Number(cartData[i].quantity);
            if (!isNaN(qty)) {
                runningQty += qty;
            }
        }

        const updatedOrders = order2.map(item => ({
            ...item,
            "orderDate": formattedDate1,
            "orderTime": formattedTime
        }));

        console.log(updatedOrders);
        console.log("Already Existing Account");
        console.log(updatedOrders);

        const updatedItems = updatedOrders.map(item => {
            if (item.ord2Id !== 0) {
                return null;
            } else {
                return item;
            }
        }).filter(Boolean);

        console.log("updatedValues")
        console.log(updatedValues)

        // *************** Update Account Partially **********************
        const filterData = [
            {
                "operationType": 0,
                "path": "Name",
                "op": "Add",
                "from": "string",
                "value": inputValues.name
            },
            {
                "operationType": 0,
                "path": "AMobileNo",
                "op": "Add",
                "from": "string",
                "value": inputValues.aMobileNo
            },
            {
                "operationType": 0,
                "path": "ADOM",
                "op": "Add",
                "from": "string",
                "value": inputValues.adom
            },
            {
                "operationType": 0,
                "path": "ADob",
                "op": "Add",
                "from": "string",
                "value": inputValues.adob
            },
            {
                "operationType": 0,
                "path": "ReferralBy",
                "op": "Add",
                "from": "string",
                "value": inputValues.referralBy
            },
        ]
        await Account.updateAccountPartially(filterData, orderData?.d1AccountId, orderData?.d2AccountId);

        if (orderData.serviceTypeId == 751) {
            const updatedTables = tableData.map(item =>
                item.id === orderData.tableId
                    ? { ...item, paymentStatus: "Pending" }
                    : item
            );
            setTableData(updatedTables);
        }
        // ********************* Running Order ****************************
        if (orderData.ord1Id !== 0 && orderData.ord1Id !== undefined) {
            const runningData = runningOrder && runningOrder.slice().reverse();
            console.log("runningData", runningData);
            console.log("update", orderData.ord1Id);

            let overallOrderStatus;
            if (overAllStatus == true) {
                overallOrderStatus = "Pending";
            }
            else {
                overallOrderStatus = "Confirm"; //orderData.overallOrderStatus;
            }

            const updatedValuesForUpdate = {
                ...orderData,
                "orderDate": formattedDate1,
                "orderTime": formattedTime,
                "orderDoneOn": formattedDate,
                "orderConfirmedOn": formattedDate,
                "deliveryDate": formattedDate1,
                //"deliveryTime": formattedTime,
                "paymentDoneOn": formattedDate,
                "kotDispatchTargetTime": formattedTime,
                // "orderConfirmedBy": loginUserDetails.id,
                "hasRunningOrder": true,
                "runningOrderTotalAmt": (Number(runningData[0]?.runningOrderTotalAmt ?? 0) + total),
                "runningOrderCounter": (Number(runningData[0]?.runningOrderCounter ?? 0) + 1),
                "overallOrderStatus": overallOrderStatus,
            };

            const updatedOrder1 = updatedItems.map(item => ({
                ...item,
                "ord1Id": orderData.ord1Id,
                "itemRunningOrderCounter": (Number(runningData[0]?.itemRunningOrderCounter ?? 0) + 1),
                "orderRunningQty": runningQty,
                "itemRunningQty": item.qty,
                "kotStatus": cartDataStatus === "Delivered" ? "Delivered" : "Received",
            }));

            console.log("CartDataaaaa", updatedOrder1);
            console.log("CartDataaaaa", cartData);

            const updatedOrder3 = {
                "orderStatus": cartDataStatus === "Delivered" ? "Delivered" : "Received",
                "orderDateTime": formattedDate,
            };

            const filteredOrders = updatedOrder1.filter(order =>
                cartData.some(cartItem =>
                    order.d1ItemId === cartItem.d1Id && order.d2ItemId === cartItem.d2Id
                )
            );

            setLoading(true);
            const success = await OrderApi.updateOrder(updatedValuesForUpdate, filteredOrders, updatedOrder3);

            const filterData = [{
                operationType: 0,
                path: "runningOrderCounter",
                op: "Add",
                from: "string",
                value: (Number(runningData[0]?.runningOrderCounter ?? 0) + 1),
            }]

            await OrderApi.updateOrderPartially(filterData, orderData.ord1Id)

            if (success === true) {
                setRunningOrder([]);
                setRunningOrderOverAll([]);
                setCartData([]);
                alert("Your Order has been Updated");
                localStorage.setItem("serviceTypeId", updatedValuesForUpdate.serviceTypeId);
                localStorage.setItem("tableGroupId", updatedValuesForUpdate.tableGroupId);

                if (isSliderOpen_bottomUp == true) {
                    setIsSliderOpen_bottomUp(false);
                }
                if (printStatus != true) {
                    // window.location.reload();
                    setPaymentAmtDetails([]);
                    setInputValues1([]);
                    ClearDataFunction(0)
                    setInputValues({
                        name: '',
                        aMobileNo: '',
                        adob: '',
                        adom: '',
                        referralBy: '',
                        aAddress: ''
                    });
                    setInputItemValue('');
                }
                setLoading(false);
                // if (orderData.ord1Id != null && orderData.ord1Id != undefined && orderData.ord1Id != 0 && orderData.ord1Id != "") {
                //     const data = await OrderApi.getOrderListByOrd1Id(orderData.ord1Id);
                //     console.log("ConfirmAndKot", data.item1)
                //     const filteredData = data.item1.filter(item => item.itemRunningOrderCounter > 0);
                //     const maxCounter = Math.max(...filteredData.map(item => item.itemRunningOrderCounter));
                //     const lastGreatestItems = filteredData.filter(item => item.itemRunningOrderCounter === maxCounter);
                //     console.log(lastGreatestItems);
                //     setOrderListData(lastGreatestItems);
                //     setShowPrintModal(true);
                // }
                setShowPrintModal(true)
                return orderData.ord1Id;
            }
            else {
                alert("Error while Updating Running Order")
                setLoading(false);
            }
        }
        else {
            console.log("insert");
            console.log(updatedOrders);
            console.log(updatedValues);

            const updatedOrder3 = {
                "orderStatus": "Received",
                "orderDateTime": formattedDate,
            };
            setLoading(true)
            if (updatedValues.serviceTypeId == 751) {
                const tableStatus = await Table.checkTableStatus(updatedValues.tableId);
                if (tableStatus == "Busy") {
                    alert("This table is already Busy. Please select another table");
                    setLoading(false);
                    return false;
                }
                setLoading(false)
            }
            const filteredOrders = updatedOrders.filter(order =>
                cartData.some(cartItem =>
                    order.d1ItemId === cartItem.d1Id && order.d2ItemId === cartItem.d2Id
                )
            );
            const success = await OrderApi.insertOrder(updatedValues, filteredOrders, updatedOrder3);
            const id = success.result.order1.id;
            const orderNo = success.result.order1.orderNo;
            ord1Id = id;
            const filteredData = [{
                operationType: 0,
                path: "tableStatus",
                op: "Add",
                from: "string",
                value: "Busy"
            }]
            if (orderData.serviceTypeId == 751) {
                localStorage.setItem("tableGroupId", updatedValues.tableGroupId);
                await Table.updateTablePartially(filteredData, updatedValues.tableId);
                onTableGroupSelection(updatedValues.tableGroupId);
            }
            if (success.isSuccess === true) {
                alert(`Thanks for Your Order & Your Order no is ${orderNo}`);
                setLoading(false)
            }
            else {
                alert("Error while Inserting Order");
                setLoading(false);
            }
            // ******************** Insert Payment Mode **********************
            if (paymentAmtDetails && paymentAmtDetails.length > 0) {
                const updatedData = paymentAmtDetails.map((item, index) => ({
                    ...item,
                    ord1Id: ord1Id
                })).filter(item => item.paymentAmount != 0);

                setPaymentAmtDetails(updatedData);
                await Order.createPaymentModeWithOrder(updatedData)
            }
            localStorage.setItem("serviceTypeId", updatedValues.serviceTypeId);

            if (isSliderOpen_bottomUp == true) {
                setIsSliderOpen_bottomUp(false);
            }

            if (printStatus != true) {
                // window.location.reload();
                setPaymentAmtDetails([]);
                setInputValues1([]);
                ClearDataFunction(0)
                setInputValues({
                    name: '',
                    aMobileNo: '',
                    adob: '',
                    adom: '',
                    referralBy: '',
                    aAddress: ''
                });
                setInputItemValue('');
            }
            // if (ord1Id != null && ord1Id != undefined && ord1Id != 0 && ord1Id != "") {
            //     const data = await OrderApi.getOrderListByOrd1Id(ord1Id);
            //     console.log("SaveAndKot", data.item1)
            //     setOrderListData(data.item1);
            //     setShowPrintModal(true);
            // }
            setShowPrintModal(true);
        }
        setPaymentAmtDetails([]);
        setInputValues1([]);
        ClearDataFunction(0);
        setInputValues({
            name: '',
            aMobileNo: '',
            adob: '',
            adom: '',
            referralBy: '',
            aAddress: ''
        });
        setInputItemValue('');
        setLoading(false)
        return ord1Id;
    }

    const calculation = (updatedCartData) => {
        console.log(updatedCartData)
        if (updatedCartData && updatedCartData.length > 0) {
            let total = 0;
            for (let i = 0; i < updatedCartData.length; i++) {
                const item = updatedCartData[i];
                const qty = parseInt(item.quantity);
                const rate = parseFloat(item.rate);
                if (!isNaN(qty) && !isNaN(rate)) {
                    total += qty * rate;
                }
            }
            console.log("total", total);
            return total;
        }
    }

    const handleRunningOrdQuantityChange = async () => {
        var success = false;
        let totalRunAmount = 0;
        for (var i = 0; i < runningOrder.length; i++) {
            if (runningOrder[i].itemRunningQty > 1) {
                const itemAmount = runningOrder[i].qty * runningOrder[i].rate;
                totalRunAmount += itemAmount;
            }
            const updatedValues = [
                {
                    operationType: 0,
                    path: "qty",
                    op: "Add",
                    from: "string",
                    value: Number(runningOrder[i].quantity)
                },
                {
                    operationType: 0,
                    path: "ITotalAmount",
                    op: "Add",
                    from: "string",
                    value: runningOrder[i].quantity * runningOrder[i].rate // totalAmount (qty * rate)
                }
            ];

            // Add an additional operation if qty is greater than 1
            if (runningOrder[i].itemRunningQty > 1) {
                updatedValues.push({
                    operationType: 0,
                    path: "itemRunningQty",
                    op: "Add",
                    from: "string",
                    value: Number(runningOrder[i].quantity)
                });
            }
            success = await OrderApi.updateItemPartially(updatedValues, runningOrder[i].ord2Id);
        }
        const totalAmount = calculation(runningOrder);

        const filteredData = [
            {
                operationType: 0,
                path: "TotalAmt",
                op: "Add",
                from: "string",
                value: totalAmount
            },
            {
                operationType: 0,
                path: "GrandTotalAmt",
                op: "Add",
                from: "string",
                value: totalAmount
            },
            {
                operationType: 0,
                path: "RunningOrderTotalAmt",
                op: "Add",
                from: "string",
                value: totalRunAmount ? totalRunAmount : runningOrder[0].runningOrderTotalAmount,
            }
        ]
        await OrderApi.updateOrderPartially(filteredData, runningOrder[0].ord1Id);
        if (success === true) {
            //alert("Updated");
        }
    }

    const [orderListData, setOrderListData] = useState([]);

    const runningOrderUpdate = async () => {
        if (runningOrderOverAll && runningOrderOverAll.length > 0) {
            handleRunningOrdQuantityChange();
            const deleteItemData = runningOrderOverAll.filter(id => !runningOrder.includes(id));
            if (deleteItemData && deleteItemData.length > 0) {
                for (var i = 0; i < deleteItemData.length; i++) {
                    await OrderApi.deleteItemFromOrder(deleteItemData[i].ord1Id, deleteItemData[i].ord2Id)
                }
            }
        }
    }

    const KotAndPrint = async () => {
        setIsButtonVisible(false);
        setTimeout(() => {
            setIsButtonVisible(true);
        }, 3000);
        try {
            setLoading(true);
            const ord1Id = await ConfirmAndKot(true, true);
            // alert(ord1Id);
            const filterData = {
                fieldName: "ord1Id",
                operatorName: "equal",
                compareValue: ord1Id,
            };
            debugger;
            // if (ord1Id != null && ord1Id != undefined && ord1Id != 0 && ord1Id != "") {
            //     const data = await OrderApi.getOrderListByOrd1Id(ord1Id);
            //     console.log("SaveAndKot", data.item1)
            //     setOrderListData(data.item1);
            //     setShowPrintModal(true);
            // }
            setShowPrintModal(true);
            setCartData([]);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const SavePaymentBtn = async () => {
        setLoading(true);
        if (paymentAmtDetails.length == 0) {
            alert("Please enter Payment Amount")
            setLoading(false);
            return 0;
        }
        const formattedDate = await CurrentDateAndTime.getCurrentDateTime();
        // const formattedDate = new Date(isoString);

        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);

        let paymentTotalAmt = 0;

        if (paymentAmtDetails && paymentAmtDetails.length > 0) {
            paymentTotalAmt = paymentAmtDetails.reduce((sum, data) => sum + Number(data.paymentAmount), 0);
        }

        const balanceAmount = orderData.grandTotalAmt - (Number(orderData.o1CancelledAmt) + Number(orderData.paymentAmount));

        let paymentStatus = "Pending";
        if (paymentTotalAmt == balanceAmount) { //orderData.grandTotalAmt
            paymentStatus = "Done";

            setOrderData((prevValues) => ({
                ...prevValues,
                paymentStatus: "Done",
            }));

            const updatedItems = tableData.map(item =>
                item.id === orderData.tableId
                    ? { ...item, paymentStatus: "Done" }
                    : item
            );
            setTableData(updatedItems);
        }
        else if (paymentTotalAmt > balanceAmount) {
            alert(`Payment cannot exceed the Balance Amount (₹${balanceAmount})`);
            setLoading(false);
            return false;
        }

        if (paymentAmtDetails && paymentAmtDetails.length > 0) {
            const updatedData = paymentAmtDetails.map((item, index) => ({
                ...item,
                ord1Id: orderData.ord1Id
            })).filter(item => item.paymentAmount != 0);

            setPaymentAmtDetails(updatedData);
            await Order.createPaymentModeWithOrder(updatedData)
        }

        const updatedValues = [
            {
                operationType: 0,
                path: "paymentStatus",
                op: "Add",
                from: "string",
                value: paymentStatus
            },
            {
                operationType: 0,
                path: "paymentAmount",
                op: "Add",
                from: "string",
                value: orderData.paymentAmount + paymentTotalAmt
            },
            {
                operationType: 0,
                path: "PaymentDoneOn",
                op: "Add",
                from: "string",
                value: formattedDate
            },
            {
                operationType: 0,
                path: "PaymentDoneBy",
                op: "Add",
                from: "string",
                value: loginUser.userId
            },
        ]
        await OrderApi.updateOrderPartially(updatedValues, orderData.ord1Id);

        setOrderData((prevValues) => ({
            ...prevValues,
            "paymentStatus": paymentStatus,
            "paymentAmount": (orderData.paymentAmount + paymentTotalAmt),
        }));
        setInputValues1([]);

        setLoading(false);
        alert("Updated Payment Status");
        return 1;
    }

    const [showBillPrint, setShowBillPrint] = useState(false);

    const closeBillPrint = () => {
        setShowBillPrint(false)
    }

    // const [apiData, setApiData] = useState([]);
    // const [droppedItems, setDroppedItems] = useState([]);
    // const [styleArray, setStylesArray] = useState([]);

    // useEffect(() => {
    //     console.log("styleArray", styleArray);
    //     console.log("apiData", apiData);
    //     console.log("droppedItems", droppedItems);
    // }, [apiData, droppedItems, styleArray]);

    // const showTemplateData = async (e) => {
    //     const filteredData = [{
    //         "fieldName": "name",
    //         "operatorName": "equal",
    //         "compareValue": "ReportDesign"
    //     }];
    //     var getdata = await WebTemplate.GetCustomizedPageTemplatesWithFilter(filteredData);
    //     if (getdata) {
    //         if (getdata.jsonStructure) {
    //             try {
    //                 const decodedData = decodeURIComponent(getdata.jsonStructure)
    //                 const parsedData = JSON.parse(decodedData);
    //                 // getdata.jsonStructure = parsedData
    //                 setDroppedItems(parsedData);
    //                 if (getdata.textContents) {
    //                     const decodedData = decodeURIComponent(getdata.textContents)
    //                     const parsedData = JSON.parse(decodedData);
    //                     // getdata.jsonStructure = parsedData
    //                     setStylesArray(parsedData);
    //                 }
    //             } catch (error) {
    //                 console.error('Error parsing JSON:', error);
    //                 setDroppedItems([]);
    //             }
    //         } else {
    //             setDroppedItems([]);
    //         }
    //         var templateInnerHtml = getdata.textContents;
    //         var templateInnerHtmlDecode = decodeURIComponent(templateInnerHtml);
    //         getdata.textContents = templateInnerHtmlDecode

    //         setApiData(getdata);

    //         // setDataHtml(getdata)

    //     } else {
    //         document.getElementById("drop-target-innerHtml").outerHTML = "";
    //         setDroppedItems([]);
    //     }
    // }
    // useEffect(() => {
    //     showTemplateData()
    // }, []);

    // useEffect(() => {
    //     // Use a for loop to iterate over stylesArray
    //     for (let index = 0; index < styleArray.length; index++) {
    //         const styleObject = styleArray[index];
    //         console.log(styleObject.id);

    //         const element = document.getElementById(styleObject.id);

    //         if (element) {
    //             for (const [key, value] of Object.entries(styleObject.style)) {
    //                 // Convert kebab-case to camelCase for inline styles
    //                 const camelCaseKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
    //                 element.style[camelCaseKey] = value;
    //             }
    //         }
    //     }
    // }, [styleArray]);

    // const renderDroppedItems = () => {
    //     return droppedItems.length > 0 && droppedItems.some(item => item.items.length > 0) ? (
    //         droppedItems.map((item, index) => {
    //             if (item.items.length > 0) {
    //                 return (
    //                     <div key={index} dangerouslySetInnerHTML={{ __html: item.textContents }} />
    //                 );
    //             }
    //             return null;
    //         })
    //     ) : null;
    // };

    // const renderDroppedItemsAsHTML = () => {
    //     if (droppedItems.length > 0 && droppedItems.some(item => item.items.length > 0)) {
    //         return droppedItems.map((item) => item.textContents).join('');
    //     }
    //     return '';
    // };

    const BillPrintBtn = async () => {
        try {
            setLoading(true)
            if (orderData.ord1Id != null && orderData.ord1Id != undefined && orderData.ord1Id != 0 && orderData.ord1Id != "") {
                try {
                    // const newTab = window.open();
                    // const htmlContent = `
                    //     <!DOCTYPE html>
                    //     <html>
                    //         <head>
                    //             <title>Bill Print</title>
                    //             <style>
                    //                 /* Add any styles here */
                    //                 body { font-family: Arial, sans-serif; }
                    //             </style>
                    //         </head>
                    //         <body>
                    //             ${renderDroppedItemsAsHTML()}
                    //         </body>
                    //     </html>
                    // `;

                    // newTab.document.write(htmlContent);
                    // newTab.document.close();
                    //navigate("/ReportDesign");

                    const data = await OrderApi.getOrderListByOrd1Id(orderData.ord1Id);
                    // alert(orderData.ord1Id)
                    setShowBillPrint(true);
                    console.log("Print", data.item1)
                    setOrderListData(data.item1);
                    const updatedItems = tableData.map(item =>
                        item.id === orderData.tableId
                            ? { ...item, orderPrintStatus: true }
                            : item
                    );
                    setTableData(updatedItems);
                }
                catch (error) {
                    console.log(error)
                }
                try {
                    const filteredData = [{
                        operationType: 0,
                        path: "orderPrintStatus",
                        op: "Add",
                        from: "string",
                        value: true
                    }]
                    await OrderApi.updateOrderPartially(filteredData, orderData.ord1Id)
                }
                catch (error) {
                    console.log(error);
                }
            }
            // closeBillPrint();
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const handleSettleBillClick = async () => {
        setIsButtonVisible(false);
        setTimeout(() => {
            setIsButtonVisible(true);
        }, 3000);

        if (runningOrderOverAll && runningOrderOverAll.length > 0) {
            runningOrderUpdate();
        }
        if (cartData && cartData.length > 0) {
            const ord1Id = await ConfirmAndKot(true, true, "Delivered");
        }
        await BillPrintBtn();
    }

    const [isSliderOpen_bottomUp, setIsSliderOpen_bottomUp] = useState(false);

    const toggleBottomSlider = () => {
        setIsSliderOpen_bottomUp(!isSliderOpen_bottomUp);
    };

    const firstInputRef = useRef(null);
    // ****************************** Order Done ****************************
    const OrderDoneBtn = async () => {
        setIsButtonVisible(false);
        setTimeout(() => {
            setIsButtonVisible(true);
        }, 3000);

        setLoading(true);

        let paymentTotalAmt = 0;

        if (paymentAmtDetails && paymentAmtDetails.length > 0) {
            paymentTotalAmt = paymentAmtDetails.reduce((sum, data) => sum + Number(data.paymentAmount), 0);
        }

        let balanceAmount = orderData.grandTotalAmt - (Number(orderData.o1CancelledAmt) + Number(orderData.paymentAmount));

        if (paymentTotalAmt == balanceAmount) {
            if (balanceAmount != 0) {
                await SavePaymentBtn();
            }
            balanceAmount = 0;
        }

        if (balanceAmount != 0) {
            alert(`Please Pay Balance Amount ₹${balanceAmount}`);
            if (firstInputRef.current) {
                if (isSliderOpen_bottomUp == false) {
                    setIsSliderOpen_bottomUp(true);
                }
                firstInputRef.current.focus();
            }
            setLoading(false)
            return false;
        }
        else {
            const formattedDate = await CurrentDateAndTime.getCurrentDateTime();
            // const formattedDate = new Date(isoString);

            const storedArrayString = localStorage.getItem('loginAccountUserData');
            const loginUser = JSON.parse(storedArrayString);

            const updatedValues = [
                {
                    operationType: 0,
                    path: "overallOrderStatus",
                    op: "Add",
                    from: "string",
                    value: "Done"
                },
                {
                    operationType: 0,
                    path: "orderDoneBy",
                    op: "Add",
                    from: "string",
                    value: loginUser.userId
                },
                {
                    operationType: 0,
                    path: "orderDoneOn",
                    op: "Add",
                    from: "string",
                    value: formattedDate
                },
                {
                    operationType: 0,
                    path: "orderPrintStatus",
                    op: "Add",
                    from: "string",
                    value: false
                }
            ]
            await OrderApi.updateOrderPartially(updatedValues, orderData.ord1Id);

            // ************************ Make Table Free *******************
            if (orderData.serviceTypeId == 751) {
                const filteredData = [{
                    operationType: 0,
                    path: "tableStatus",
                    op: "Add",
                    from: "string",
                    value: "Free"
                }]
                await Table.updateTablePartially(filteredData, orderData.tableId);
                localStorage.setItem("tableGroupId", orderData.tableGroupId);
                onTableGroupSelection(orderData.tableGroupId);
            }
            setLoading(false);
            localStorage.setItem("serviceTypeId", orderData.serviceTypeId);
            alert("Updated Status to Done")
            const updatedItems = tableData && tableData.map(item =>
                item.id === orderData.tableId
                    ? {
                        ...item,
                        orderPrintStatus: false,
                        paymentStatus: "Pending",
                        tableStatus: "Free"
                    }
                    : item
            );
            if (isSliderOpen_bottomUp == true) {
                setIsSliderOpen_bottomUp(false);
            }
            setTableData(updatedItems);
            ClearDataFunction(orderData.tableId)
            setInputValues({
                name: '',
                aMobileNo: '',
                adob: '',
                adom: '',
                referralBy: '',
                aAddress: ''
            });
            setInputItemValue('');
        }
    }

    const ClearDataFunction = async (tableId) => {
        const dateTime = await CurrentDateAndTime.getCurrentDateTime();
        const filteredData = [
            {
                fieldName: "IsDefault",
                operatorName: "Equal",
                compareValue: true
            }
        ]
        const updatedData = await BillTypeApi.GetFilteredBillType(filteredData)
        const billSeries = await BillSeriesMasterApi.getBillSeriesMasterFiltered(filteredData);

        const orderNo = await OrderApi.getBillSeriesCounter(billSeries[0]?.id);
        const tableGroup = localStorage.getItem("tableGroupId")
        const serviceType = localStorage.getItem("serviceTypeId");

        setOrderData({
            ord1Id: 0,
            billTypeId: updatedData[0]?.id || orderData.billTypeId, //585
            orderSeriesId: billSeries[0]?.id || orderData.orderSeriesId,
            orderNo: (orderNo) + 1,
            autoOrderNo: (Number(orderNo) + 1),
            serviceTypeId: serviceType || 0,
            overallOrderStatus: (companyData && companyData?.isPOSConfirmNKotBtn == true) ? "Confirm" : "Pending",
            // orderDate: "",
            // orderTime: "",
            orderDoneBy: 0,
            orderDoneOn: "",
            orderConfirmedBy: loginUserDetails.userId,
            orderConfirmedOn: dateTime,
            orderDoneFrom: "POS",
            d1AccountId: 0,
            d2AccountId: 0,
            // billTypeId: 0,
            tableGroupId: tableGroup || 0,
            tableId: tableId || 0,
            noOfPerson: 0,
            noOfAdult: 0,
            noOfChild: 0,
            couponCode: "",
            couponCodeAmount: 0,
            deliveryCharges: 0,
            deliveryDate: "",
            deliveryTimeId: 0,
            deliveryStatus: "",
            deliveryBoyId: 0,
            deliveryBoyDispatchTime: "00:00:00",
            deliveryBoyTripNo: 0,
            deliveryBoyTripIncentive: 0,
            deliveryReferenceNo: 0,
            deliveryPreferenceNo: 0,
            deliveryTripStatus: "",
            orderPrintStatus: 0,
            totalAmt: 0,
            initialOrderTotalAmt: 0,
            grandTotalAmt: 0,
            paymentStatus: "Pending",
            paymentAmount: 0,
            returnAmount: 0,
            discountAmount: 0,
            paymentDoneBy: 0,
            paymentDoneOn: "",
            paymentAttemptedCounter: 0,
            paymentMode: 0,
            paymentBank: "",
            paymentRemarks: "",
            remarks: "",
            orderSuggestion: "",
            kotDispatchTargetTime: "",  // deliveryTime
            latitude: "",
            longitude: "",
            currencyId: 0,
            hasRunningOrder: false,
            runningOrderCounter: 0,
            runningOrderTotalAmt: 0,
        });
        setPaymentAmtDetails([]);
        setInputValues1([]);
        setOrd1TotalCancelAmount(0);
        setTotalAmount(0);
        setComments([]);
        setOrder2([]);
        setCartData([]);
        // setInputItemValue('');
        // setInputValues({
        //     name: '',
        //     aMobileNo: '',
        //     adob: '',
        //     adom: '',
        //     referralBy: '',
        //     aAddress: ''
        // });
        setRunningOrder([])
        setTotalAmount(0);
        setOrd1TotalCancelAmount(0);

        let index = 0;
        if (serviceType == 751) {
            index = 1;
            setShowInTable(true);
            setShowInOtherServiceType(false);
            onTableGroupSelection(tableGroup || 0)
        }
        else if (serviceType == 752) {
            index = 2
            setShowInOtherServiceType(true);
            setShowInTable(false);
        }
        else if (serviceType == 753) {
            index = 3
            setShowInOtherServiceType(true);
            setShowInTable(false);
        }
        else if (serviceType == 754) {
            index = 4
            setShowInOtherServiceType(true);
            setShowInTable(false);
        }
        handleButtonClick(index, serviceType)
        getButtonStyle(index);
        setActiveButton(index);
    }
    const [currentDate, setCurrentDate] = useState();
    const [currentTime, setCurrentTime] = useState();

    // **************** Current Date & Time *************************
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const isoString = await CurrentDateAndTime.getCurrentDateTime();
            const currentDate = new Date(isoString);

            const day = currentDate.getDate().toString().padStart(2, '0');
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const year = currentDate.getFullYear().toString();
            const formattedDate = `${day}/${month}/${year}`;
            setCurrentDate(formattedDate);

            let hours = currentDate.getHours();
            const minutes = currentDate.getMinutes().toString().padStart(2, '0');
            let period = 'AM';
            if (hours > 12) {
                hours -= 12;
                period = 'PM';
            } else if (hours === 12) {
                period = 'PM';
            } else if (hours === 0) {
                hours = 12;
            }
            const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
            setCurrentTime(formattedTime);

            setOrderData((prevValues) => ({
                ...prevValues,
                "orderConfirmedOn": currentDate,
            }));

            setLoading(false)
        }
        fetchData()
    }, [])

    // **************** Get Accounts **************
    const [account, setAccount] = useState([]);

    const fetchData = async () => {
        try {
            setLoading(true)
            const data = await AccountApi.getAccount();
            //console.log(data);
            const accounts = data
                // .filter(item =>
                //     item.parentGroupId == 0 ||
                //     //(item.parentGroupId != 0 && item.name.toLowerCase().includes("CASH"))
                // )
                .map(item => ({
                    d1Id: item.d1Id,
                    d2Id: item.d2Id,
                    name: item.name,
                    aMobileNo: item.mobileNo,
                    adob: item.dob,
                    adom: item.dom,
                    aAddress: item.address,
                    referralBy: item.referralById || 0,
                }));
            const inputData = data.filter(item => item.name.toLowerCase().includes("cash"));
            console.log(inputData);
            if (inputData && inputData.length > 0) {
                handleItemSuggestion(inputData[0].name, inputData[0].d1Id, inputData[0].d2Id);
                setOrderData((prevValues) => ({
                    ...prevValues,
                    d1AccountId: inputData[0].d1Id,
                    d2AccountId: inputData[0].d2Id,
                }));
            }
            console.log("accounts", accounts);
            setAccount(accounts);
            setItemSuggestions(data);
            setLoading(false)
            //console.log("referralBy", data.item[0].referralById)
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // ************************* Login User Details ***********************
    const [loginUserDetails, setLoginUserDetails] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const storedArrayString = localStorage.getItem('loginUser');
            const loginUser = JSON.parse(storedArrayString);
            setLoginUserDetails(loginUser);
            console.log("userName");
            console.log(loginUser.userName);

            setOrderData((prevValues) => ({
                ...prevValues,
                "orderConfirmedBy": loginUser.userId,
            }));
            setLoading(false)
        };
        fetchData();
    }, []);

    const handleOrderTakerSelect = (e) => {
        const val = e.target.value;
        if (val == "default") {
            setOrderData((prevValues) => ({
                ...prevValues,
                "orderConfirmedBy": loginUserDetails.userId,
            }));
        }
        else {
            setOrderData((prevValues) => ({
                ...prevValues,
                "orderConfirmedBy": val,
            }));
        }
    }

    const [itemDepartmentActiveIndex, setItemDepartmentActiveIndex] = useState(0)

    const itemGroupClick = async (id, index) => {
        const filteredData = [{
            fieldName: "parentGrpId2",
            operatorName: "Equal",
            compareValue: id
        }]
        const data = await ItemMasterApi.GetItemMasterWithFilter(filteredData)
        setFilteredItems(data);
        setItemDepartmentActiveIndex(index + 1);
        // alert(id)
        // const filteredItems = menuItems.filter(item => item.parentGrpId2 === id)
        // setFilteredItems(filteredItems);
        // console.log(filteredItems);
    }

    const handleFocus = (event) => {
        event.target.select();
    };

    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    const handleKeyPressForSuggestion = (event) => {
        if (isItemOpen) {
            if (event.key === 'ArrowDown') {
                setHighlightedIndex((prevIndex) =>
                    prevIndex === suggestedItems1.length - 1 ? 0 : prevIndex + 1
                );
            } else if (event.key === 'ArrowUp') {
                setHighlightedIndex((prevIndex) =>
                    prevIndex <= 0 ? suggestedItems1.length - 1 : prevIndex - 1
                );
            } else if (event.key === 'Enter' && highlightedIndex >= 0) {
                const selectedSuggestion = suggestedItems1[highlightedIndex];
                handleItemSuggestion(
                    selectedSuggestion.name,
                    selectedSuggestion.d1Id,
                    selectedSuggestion.d2Id,
                    event
                );
            }
        }
    };

    const [paymentAmtDetails, setPaymentAmtDetails] = useState([]);
    const [inputValues1, setInputValues1] = useState({});

    const handlePaymentChange = (e, accountId) => {
        const { value } = e.target;
        const parsedValue = parseFloat(value);

        console.log("paymentAmtDetails", paymentAmtDetails);

        setInputValues1(prev => ({
            ...prev,
            [accountId]: value // Store the value for the specific accountId
        }));

        setPaymentAmtDetails((prevData) => {
            let updatedData;
            const existingIndex = prevData.findIndex(item => item.paymentModeId === accountId);
            if (parsedValue === 0) {
                updatedData = prevData.filter(item => item.paymentModeId !== accountId);
            } else if (existingIndex !== -1) {
                updatedData = [...prevData];
                updatedData[existingIndex] = {
                    ...updatedData[existingIndex],
                    paymentAmount: value,
                };
            } else {
                updatedData = [
                    ...prevData,
                    {
                        ord1Id: 0,
                        paymentModeId: accountId,
                        paymentAmount: value,
                        remarks: '',
                        transId: "",
                        payUId: 0,
                        bankRefNo: "",
                        status: "",
                        paymentTypeMethod: "",
                    },
                ];
            }

            const totalPaymentAmount = updatedData.reduce((sum, data) => sum + parseFloat(data.paymentAmount), 0);

            const paymentStatus = totalPaymentAmount === totalAmount ? "Done" : "Pending";
            // setOrderData((prevValues) => ({
            //     ...prevValues,
            //     paymentStatus,
            // }));

            return updatedData;
        });
    };

    const [showInTable, setShowInTable] = useState(false);

    useEffect(() => {
        if (orderData.serviceTypeId == 751) {
            setShowInTable(true);
        }
        else {
            setShowInTable(false);
        }
    }, [orderData])

    const [showInOtherServiceType, setShowInOtherServiceType] = useState(false);

    useEffect(() => {
        if (orderData.serviceTypeId != 751) {
            setShowInOtherServiceType(true);
        }
        else {
            setShowInOtherServiceType(false);
        }
    }, [orderData])

    const handleTableSelect = async (id, tableStatus, isFromDineIn, tableGroupId) => {
        try {
            setLoading(true)
            if (isFromDineIn == "Yes") {
                onTableGroupSelection(tableGroupId, "Yes", id, tableStatus);
                setLoading(true);
            }
            if (tableStatus == "Busy") {
                await onTableSelect(id);
                setLoading(true);
                if (isFromDineIn != "Yes") {
                    const updatedItems = tableData.map(item => {
                        if (item.tableStatus == "Selected") {
                            return { ...item, tableStatus: 'NotSelected' };
                        }
                        return item;
                    });
                    setTableData(updatedItems);
                }
            }
            else {
                const updatedItems = tableData.map(item => {
                    if (item.id === id) {
                        return { ...item, tableStatus: 'Selected' };
                    } else if (item.tableStatus != "Busy") {
                        return { ...item, tableStatus: 'NotSelected' };
                    }
                    return item;
                });
                setOrd1TotalCancelAmount(0);
                if (isFromDineIn != "Yes") {
                    setTableData(updatedItems);
                }
                setRunningOrder([]);
                setRunningOrderOverAll([]);

                setOrderData((prevValues) => ({
                    ...prevValues,
                    "ord1Id": 0,
                    "tableId": id,
                    "hasRunningOrder": false,
                    "runningOrderCounter": 0,
                    "runningOrderTotalAmt": 0,
                    "d1AccountId": 0,
                    "d2AccountId": 0,
                    "orderDoneBy": 0,
                    "paymentStatus": "Pending",
                    "paymentAmount": 0,
                    "paymentDoneBy": 0,
                    "paymentMode": 0,
                    "hasRunningOrder": false,
                    "runningOrderCounter": 0,
                    "runningOrderTotalAmt": 0,
                }));
                const inputData = account.filter(item => item.name.toLowerCase().includes("cash"));
                console.log(inputData);
                if (inputData && inputData.length > 0) {
                    handleItemSuggestion(inputData[0].name, inputData[0].d1Id, inputData[0].d2Id);
                }
                const updatedValues = {
                    ...getDeliveryAndTimeSlotId,
                    orderType: 751,
                    deliveryPointName: 1,
                    distance: 0,
                    isCurrentDate: true,
                };
                setDeliveryAndTimeSlotId((prevState) => ({
                    ...prevState,
                    orderType: 751,
                    deliveryPointName: 1,
                    distance: 0,
                    isCurrentDate: true,
                }));
                fetchTimeSlot(updatedValues);
                //setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
        setLoading(false);
    }
    // ****************** Price Calculate ******************
    const [totalAmount, setTotalAmount] = useState(0);
    const [ord1TotalCancelAmount, setOrd1TotalCancelAmount] = useState(0);

    const calculateTotalPrice = (updatedCartData, overAllStatus) => {
        // if (cartData && cartData.length > 0) {
        setLoading(true);
        let total = 0;

        if (overAllStatus == "Pending") {
            for (let i = 0; i < updatedCartData.length; i++) {
                const price = parseFloat(updatedCartData[i].quantity * updatedCartData[i].salePrice);
                if (!isNaN(price)) {
                    total += price;
                }
            }
            for (let i = 0; i < cartData.length; i++) {
                const price = parseFloat(cartData[i].quantity * cartData[i].salePrice);
                if (!isNaN(price)) {
                    total += price;
                }
            }
        } else {
            for (let i = 0; i < runningOrder.length; i++) {
                const price = parseFloat(runningOrder[i].quantity * runningOrder[i].salePrice);
                if (!isNaN(price)) {
                    total += price;
                }
            }
            for (let i = 0; i < updatedCartData.length; i++) {
                const price = parseFloat(updatedCartData[i].quantity * updatedCartData[i].salePrice);
                if (!isNaN(price)) {
                    total += price;
                }
            }
        }
        setTotalAmount(total);

        if (runningOrder && runningOrder.length > 0) {
            setOrd1TotalCancelAmount(runningOrder[0].o1CancelledAmt);
            setTotalAmount(total - runningOrder[0].o1CancelledAmt);
        }

        setOrderData((prevValues) => ({
            ...prevValues,
            totalAmt: total,
            initialOrderTotalAmt: total,
            grandTotalAmt: total,
        }));
        setLoading(false);
        console.log("total", total);
        // }
    }

    useEffect(() => {
        calculateTotalPrice(cartData);
    }, [cartData, runningOrder]);

    // ************************* Delivery Time Slot ********************
    const [getDeliveryAndTimeSlotId, setDeliveryAndTimeSlotId] = useState({
        deliveryPointName: 1,
        orderType: orderData.serviceTypeId,
        distance: 0,
        isCurrentDate: false,
    });

    const [paymentDetailModalPopup, setPaymentDetailModal] = useState(false)

    const openPaymentDetailsModal = async () => {
        const data = await handleGetPayment(orderData.ord1Id)
        if (data)
            setPaymentDetailModal(true)
    }

    const closePaymentDetailsModal = () => {
        setPaymentDetailModal(false)
    }
    const [PaymentModalData, setPaymentModalData] = useState([]);

    const handleGetPayment = async (orderId) => {
        const PaymentData = await Order.GetPaymentModeWithOrders(orderId);
        console.log(PaymentData);
        setPaymentModalData(PaymentData);
        if (PaymentData.length > 0) {
            return true
        }
        else {
            alert("No Payment Found")
            return false
        }
    };

    const handlePayment = async (rupeeAmt, orderNo, ord1Id) => {
        navigate("/Request", {
            state: {
                orderNo,
                ord1Id
            }
        });
    }

    const saveNoOfPerson = async () => {
        setLoading(true)
        const updatedValues = [
            {
                operationType: 0,
                path: "noOfAdult",
                op: "Add",
                from: "string",
                value: orderData.noOfAdult
            },
            {
                operationType: 0,
                path: "noOfChild",
                op: "Add",
                from: "string",
                value: orderData.noOfChild
            },
            {
                operationType: 0,
                path: "noOfPerson",
                op: "Add",
                from: "string",
                value: (orderData.noOfAdult + orderData.noOfChild)
            },
        ]
        await OrderApi.updateOrderPartially(updatedValues, orderData.ord1Id);
        setLoading(false)
        alert("Updated")
    }

    const cartItemRef = useRef(null);

    const scrollToBottom = () => {
        if (cartItemRef.current) {
            cartItemRef.current.scrollTop = Number(cartItemRef.current.scrollHeight);
        }
    };

    const [itemMasterModal, setItemMasterModal] = useState(false)

    const handleOpenItemMasterModal = () => {
        setItemMasterModal(true)
    }

    const closeItemMasterModal = () => {
        setItemMasterModal(false);
    }

    const [itemValue, setItemValues] = useState({
        ProductName: "",
        ProductAlias: "",
        PrintName: "",
        SalePrice: 0,
        AvailableForHomeDelivery: true,
        AvailableForTableOrder: true,
        AvailableForPickUp: true,
        AvailableForPOS: true,
    })

    const [searchData, setSearchData] = useState("");
    const [isItemSuggestionOpen, setItemSuggestionOpen] = useState(false);

    const handleItemMasterChange = (e) => {
        const { name, value } = e.target;
        if (name == "ProductName") {
            setItemValues((prevValues) => ({
                ...prevValues,
                ProductName: value,
                ProductAlias: value,
                PrintName: value,
            }))

            const searchValue = value;

            const filteredItems = menuItems.filter(item =>
                item.name.toLowerCase().includes(searchValue.toLowerCase())
            );
            setSearchData(filteredItems);

            if (filteredItems.length > 0) {
                setItemSuggestionOpen(true);
            }
            else {
                setItemSuggestionOpen(false);
            }
        }
        else {
            setItemValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
            setItemSuggestionOpen(false);
        }
    }
    const closeSuggestion = () => {
        setItemSuggestionOpen(false);
    }

    const handleInsertItemMaster = async () => {
        const data = await ItemMaster.InsertItemMaster(itemValue)
        if (data.errorMessages == "Item already exists.") {
            alert("Item Already Exist.");
            return false;
        }
        const d2Id = data.result.d2_Id;
        const itemMasterData = await fetchItemMasterData(1);
        ItemButtonClick(d2Id, ...itemMasterData);
        alert("Updated")
        closeItemMasterModal();
    }

    const [cancelModal, setCancelModal] = useState(false);

    const [cancelItemList, setCancelItemList] = useState([]);
    const [itemWiseList, setItemWiseList] = useState([]);

    const fetchItemWiseAndCancelOrder = async (ord1Id) => {
        setLoading(true);
        try {
            // const data = await OrderApi.getOrderItemWiseList(ord1Id);
            // console.log("dataaaa", data);
            // setItemWiseList(data);
            const cancelItem = await OrderApi.getCancelItemByOrd1Id(ord1Id);
            setCancelItemList(cancelItem);
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const [itemCancelList, setItemCancelList] = useState([]);
    const inputRef = useRef(null);

    const handleCancelFocus = () => {
        if (inputRef.current) {
            inputRef.current.select();
        }
    }

    const handleRunningOrderChange = async (ord1Id, ord2Id, qty, overAllStatus, d2Id, o2OrderStatus) => {
        const tableGroupData = tableData.find(item => item.id == orderData.tableId);
        if (overAllStatus == "Pending") {
            if (tableGroupData && tableGroupData.orderPrintStatus === false) {
                // const minusQty = runningOrderOverAll.filter(item => item.ord2Id == ord2Id)
                // if (minusQty[0]?.quantity < (qty)) {
                handleDecrement(d2Id, overAllStatus, ord2Id);
                return false;
                // }
            }
            else {
                const minusQty = runningOrderOverAll.filter(item => item.ord2Id == ord2Id)
                if (minusQty[0]?.qty < (qty)) {
                    handleDecrement(d2Id, overAllStatus, ord2Id);
                    return false;
                }
            }
        }
        if (UserDetails.canUseCancelOrdrFeature == true) {
            if (o2OrderStatus != "Pending" && o2OrderStatus != "Received") {
                alert("Cannot Cancel Item as it already Transferred to Chef");
                return false;
            }
            setCancelModal(true);
            await fetchItemWiseAndCancelOrder(ord1Id);
            if (inputRef.current) {
                inputRef.current.select();
            }
            const data = runningOrder.filter(item => item.ord2Id == ord2Id);
            // if (data[0].kotStatus == "Pending") {
            //     alert("Cannot Cancel as Item is Pending")
            //     return false;
            // }
            // setApprovedQty(apprQty)
            // setRequestedQty(qty)
            setItemCancelList(data);
            // let totalQty = 0;
            // if (name == "increment") {
            //     totalQty = qty + 1
            // }
            // else if (name == "decrement") {
            //     totalQty = qty - 1
            // }

            // if (totalQty == 0) {
            //     await OrderApi.deleteItemFromOrder(ord1Id, ord2Id)

            //     const filteredItems = runningOrder.filter(item => item.ord2Id != ord2Id);
            //     setRunningOrder(filteredItems);
            //     console.log(filteredItems);
            //     return false;
            // }

            // const updatedItems = runningOrder.map(item =>
            //     item.ord2Id === ord2Id ? { ...item, quantity: totalQty } : item
            // );
            // setRunningOrder(updatedItems);
            // console.log(updatedItems);

            // const updatedData = [
            //     {
            //         operationType: 0,
            //         path: "qty",
            //         op: "Add",
            //         from: "string",
            //         value: totalQty
            //     },
            // ]
            // await OrderApi.updateItemPartially(updatedData, ord2Id);
        } else {
            alert("You don't have permission to Modify or Cancel this Order");
        }

    }

    const runningIncrement = (d2Id, overAllStatus, ord2Id) => {
        //alert("Please add a new item to the existing order.");
        if (overAllStatus == "Pending") {
            handleIncrement(d2Id, overAllStatus, ord2Id);
        }
        else {
            ItemButtonClick(d2Id);
        }
    }

    const closeCancelModal = () => {
        setCancelModal(false);
        setCheckIsSelectItem(false);
    }
    // **************** Check Available for selected Service Type, Available Date & Time **********
    const isAvailableTodayAndTime = (item) => {
        const today = new Date().toISOString().split('T')[0];
        const fromDate = item.availableDatefrom ? new Date(item.availableDatefrom).toISOString().split('T')[0] : null;
        const toDate = item.availableDateTo ? new Date(item.availableDateTo).toISOString().split('T')[0] : null;

        if (!fromDate && !toDate) {
            return { available: true, message: "" };
        }

        const isDateWithinRange = (!fromDate || today >= fromDate) && (!toDate || today <= toDate);
        const currentTime = new Date().toTimeString().split(' ')[0].substring(0, 5);

        const isTimeWithinRange = item.restoAvailableTime.length === 0 || item.restoAvailableTime.some(timeSlot => {
            return (!timeSlot.availableTimefrom || !timeSlot.availableTimeTo || (currentTime >= timeSlot.availableTimefrom && currentTime <= timeSlot.availableTimeTo));
        });

        if (!isDateWithinRange) {
            return { available: false, message: 'Not Available Today' };
        } else if (!isTimeWithinRange) {
            return { available: false, message: 'NA for this time' };
        }
        return { available: true, message: "" };
    };

    const isAvailableForService = (item) => {
        const { available, message } = isAvailableTodayAndTime(item);
        if (!available) return { available: false, message };

        if (item.stockCheck === 1 && item.restoAvailableQuantity === 0) {
            return { available: false, message: 'Out of Stock' };
        }

        switch (orderData.serviceTypeId) {
            case 751: return { available: item.availableForTableOrder, message: 'NA for Table Order' };
            case 752: return { available: item.availableForHomeDelivery, message: 'NA for Home Delivery' };
            case 753: return { available: item.availableForPickUp, message: 'NA for Pick Up' };
            case 754: return { available: item.availableForPOS, message: 'NA for POS' };
            default: return { available: true }
        }
    };

    // const checkAvailability = (item) => {
    //     if (item.stockCheck <= 0) {
    //         return { available: false, message: 'Out of stock' };
    //     }
    //     const serviceAvailability = isAvailableForService(item);
    //     return serviceAvailability.available ? { available: true, message: "" } : serviceAvailability;
    // };

    const [checkIsSelectItem, setCheckIsSelectItem] = useState(false);

    const [requestedQty, setRequestedQty] = useState(0);
    const [approvedQty, setApprovedQty] = useState(0);

    const onHandleChange = async (event, id, qty, rate, index, cancelQty, apprQty, requestQty) => {
        const { name, value } = event.target;
        const parsedValue = parseInt(value, 10);

        const numericValue = parseFloat(value);

        if (name === "cancelQty") {
            setCheckIsSelectItem(true);
        }
        if (parsedValue < 0) {
            alert("Quantity cannot be negative");
            updateItemCancelList(index, name, (qty - apprQty));
            return;
        }
        else if (numericValue != "" && !isNaN(numericValue) && !Number.isInteger(numericValue)) {
            alert("Please enter valid number");
            return;
        }
        // if (qty && (parsedValue + apprQty + requestQty) > (qty - apprQty)) {
        if (qty && (parsedValue) > (qty - apprQty)) {
            alert("You cannot select more than the actual Qty");
            updateItemCancelList(index, name, (qty - apprQty));
            return false;
        }
        updateItemCancelList(index, name, value);
    };

    const updateItemCancelList = (index, name, value) => {
        setItemCancelList(prevValues => {
            const updatedList = [...prevValues];
            updatedList[index] = { ...updatedList[index], [name]: value };
            console.log(updatedList);
            return updatedList;
        });
    };

    const cancelConfirmItem = async () => {
        try {
            setLoading(true);
            const tableGroupData = tableData.find(item => item.id == orderData.tableId);
            if (tableGroupData && tableGroupData.orderPrintStatus === true) {
                setIsRunningOrderChange(true);
            }

            console.log(itemCancelList)
            const totalCancelAmount = await Order.getCancelItemByOrd1Id(orderData.ord1Id);
            console.log(totalCancelAmount);

            if (!checkIsSelectItem) {
                alert("Select at least one qty to cancel.");
                setLoading(false);
                return;
            }
            // if (approvedQty == requestedQty) {
            //     alert("No more Qty left for cancellation")
            //     setLoading(false);
            //     return false;
            // }

            const currentDate = await CurrentDateAndTime.getCurrentDateTime();
            // const currentDate = new Date(isoString);

            let cancelAmt = 0;

            if (itemCancelList[0].cancelRemarks == "" || itemCancelList[0].cancelRemarks == null || itemCancelList[0].cancelRemarks == undefined) {
                alert("Please enter reason to cancel");
                return true;
            }

            for (const item of itemCancelList) {
                cancelAmt += item.cancelQty * item.rate;
            }

            for (const item of itemCancelList) {
                if (item.cancelQty == 0 || item.cancelQty == "" || item.cancelQty == null || item.cancelQty == undefined) {
                    alert("Select at least one qty to cancel.")
                    setLoading(false);
                    return false;
                }
                // if (!item.cancelQty) continue;
                console.log(item);
                const prevTotalCancel = ((totalCancelAmount && totalCancelAmount.length > 0 ? (totalCancelAmount[0]?.totalCancelAmt || 0) : 0) + ((Number(item.cancelQty)) * item.rate));
                console.log(prevTotalCancel)

                const filteredData = {
                    ord1Id: orderData.ord1Id,
                    ord2Id: item.ord2Id,
                    d2ItemId: item.d2ItemId,
                    cancelRequestQty: item.cancelQty,
                    cancelAmt: cancelAmt,
                    cancelRemarks: item.cancelRemarks,
                    cancelStatus: (item.cancelStatus == "" || item.cancelStatus == null || item.cancelStatus == undefined ? "Cancel Item" : item.cancelStatus),
                    cancelApprovalStatus: "Approve",
                    cancelRequestedBy: loginUserDetails.userId,
                    cancelRequestedOn: currentDate,
                    cancelApprovedBy: loginUserDetails.userId,
                    cancelApprovedOn: currentDate,
                    cancelApprovedQty: Number(item.cancelQty),
                };
                await OrderApi.createCancelItem(filteredData);

                // **************** Approve *********************
                // const updateData = [
                //     { operationType: 0, path: "ICanApprBy", op: "Add", value: loginUserDetails.userId },
                //     { operationType: 0, path: "ICanApprOn", op: "Add", value: currentDate },
                //     { operationType: 0, path: "ICanApprStatus", op: "Add", value: "Approve" },
                //     { operationType: 0, path: "ICanApprQty", op: "Add", value: Number(item.cancelQty) },
                // ];

                const cancelData = [
                    { operationType: 0, path: "CancelOrderTotalAmt", op: "Add", value: prevTotalCancel }
                ];
                await OrderApi.updateOrderPartially(cancelData, orderData.ord1Id);
                setOrderData((prevValues) => ({
                    ...prevValues,
                    "o1CancelledAmt": orderData.o1CancelledAmt + prevTotalCancel,
                }));
                // await OrderApi.updateCancelItemPartially(updateData, item.ord2Id);
            }
            const balanceAmt = orderData.grandTotalAmt - ((orderData?.paidAmt || 0) + (totalCancelAmount ? (totalCancelAmount[0]?.totalCancelAmt || 0) : 0));
            if (balanceAmt === 0) {
                const paymentStatus = [{ operationType: 0, path: "paymentStatus", op: "Add", value: "Done" }];
                await OrderApi.updateOrderPartially(paymentStatus, orderData.ord1Id);
            }

            const orderDataaa = await OrderApi.getOrderListByOrd1Id(orderData.ord1Id);
            const data = orderDataaa.item1;
            // const data = await OrderApi.getOrderItemWiseList(orderData.ord1Id);

            const newDataItems = data.map((data) => ({
                ...data,
                d2Id: data.d2ItemId,
                name: data.d2ItemName,
                barcode: data.defaultBarcode,
                image: "",
                salePrice: data.rate,
                variableItem: true,
                preferenceNo: data.preferenceNo,
                quantity: data.cancelApprovalStatus === "Approve" ? Number(itemCancelList[0].cancelQty) : data.qty,
                color: "white",
                mrpPrice: 0,
            }));
            setRunningOrder(newDataItems)
            setRunningOrderOverAll(newDataItems)
            calculateTotalPrice(newDataItems);
            alert("Updated");
        } catch (error) {
            console.error("Error:", error);
            //alert("An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
        closeCancelModal();
    };

    const [itemMasterLoader, setItemMasterLoader] = useState(false);

    useEffect(() => {
        if (!containerRef.current) return;
        const fetchMoreDataInterval = setInterval(async () => {
            if (fetchMoreData && !itemMasterLoader) {
                await setIndex(prevIndex => {
                    const newIndex = prevIndex + 1;
                    fetchItemMasterData(newIndex);
                    return newIndex;
                });
            } else {
                clearInterval(fetchMoreDataInterval);
            }
        }, 2000);

        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            // console.log(`scrollTop: ${scrollTop}`);
            // console.log(`scrollHeight: ${scrollHeight}`);
            // console.log(`clientHeight: ${clientHeight}`);

            // Prevent fetch on scroll down
            if (scrollHeight - scrollTop <= clientHeight + 10) {
                console.log('Scrolling near bottom, but fetch is controlled by interval.');
            }
        };

        const container = containerRef.current;
        container.addEventListener('scroll', handleScroll);

        return () => {
            container.removeEventListener('scroll', handleScroll);
            clearInterval(fetchMoreDataInterval);
        };

    }, [itemMasterLoader, fetchMoreData]);

    useEffect(() => {
        fetchItemMasterData(1);
    }, []);

    const fetchItemMasterData = async (page) => {
        setItemMasterLoader(true);
        try {
            const data = await ItemMasterApi.GetItemMasterPagination(page);
            console.log(`Menu Items for page ${page}: ${data}`);

            if (data.length > 0) {
                setMenuItems(prevItems => [...prevItems, ...data]);
                setFetchMoreData(true);
            } else if (data.length == 0) {
                setFetchMoreData(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setItemMasterLoader(false);
        }
    };

    const [cancelItemModal, setCancelItemModal] = useState(false);

    const cancelItemDetailModal = async () => {
        try {
            setLoading(true);
            const data = await Order.getCancelItemByOrd1Id(orderData.ord1Id);
            if (data && data.length > 0) {
                setCancelData(data);
                setCancelItemModal(true)
                setLoading(false)
            }
            else {
                alert("No Cancel Data");
                setLoading(false)
            }
        }
        catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    const closeCancelItemDetailModal = () => {
        setCancelItemModal(false)
    }

    const handleKotPrint = async (counter) => {
        const data = await OrderApi.getOrderListByOrd1Id(orderData.ord1Id);
        const filteredData = data.item1.filter(item => item.itemRunningOrderCounter == counter);
        console.log("SaveAndKot", filteredData)
        setOrderListData(filteredData);
        setShowPrintModal(true);
    }

    const [showPopup, setShowPopup] = useState(false);

    const handleFocusOnTableClick = () => {
        setShowPopup(true);
        setTimeout(() => {
            setShowPopup(false);
        }, 2000);
    };

    const [openDineIn, setOpenDineIn] = useState(false);

    const handleOpenDineIn = () => {
        setOpenDineIn(true);
    }

    const handleCloseDineIn = () => {
        setOpenDineIn(false);
    }
    // ********************** POS REPORT *****************
    const [selectedDate, setSelectedDate] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const data = await getCurrentDate();
            setSelectedDate(data)
        }
        fetchData();
    }, [])

    const [summary, setSummary] = useState();
    const [orderListPos, setOrderListPos] = useState();

    const fetchOrderDataPOS = async (selectedDate) => {
        const date = await getCurrentDate();
        try {
            setLoading(true)
            const filterData = [
                {
                    fieldName: "serviceTypeId",
                    operatorName: "Equal",
                    compareValue: 754,
                },
                {
                    fieldName: "orderDate",
                    operatorName: "Equal",
                    compareValue: selectedDate || date,
                }
            ];
            const list = await OrderApi.getRawOrderReportforPageSize100(filterData, pageIndexPos);
            let summaryData = list.summary
            setSummary(summaryData)
            let data = list.orderList

            if (list) {
                var data1 = data.slice().reverse();
                console.log(data1);
                setLoading(false)
                setOrderListPos(data1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false)
        }
    };

    const [openPOSReportModal, setPOSReportModal] = useState(false);

    const handlePOSReportOpen = () => {
        fetchOrderDataPOS(selectedDate);
        setPOSReportModal(true);
    }

    const handleClosePOSReport = () => {
        setPOSReportModal(false);
    }

    const formatDatePOS = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleRefreshBtn = () => {
        fetchOrderDataPOS(selectedDate);
    }

    const [pageIndexPos, setPageIndexPos] = useState(1);
    const [startIndexPos, setStartIndexPos] = useState(0);

    const previousPagePos = async () => {
        setLoading(true);
        const index = pageIndexPos - 1;

        const addFilterForPOS = [
            {
                fieldName: "serviceTypeId",
                operatorName: "Equal",
                compareValue: 754
            },
            {
                fieldName: "orderDate",
                operatorName: "Equal",
                compareValue: selectedDate
            }
        ]

        if (index != 0) {
            setPageIndexPos(index)
            const list = await OrderApi.getRawOrderReport(addFilterForPOS, index);
            const orderList = list.orderList
            let summaryData = list.summary

            setSummary(summaryData)
            setStartIndexPos(Number(100 * index) - 100) //Number(val.length * index) - 50
            if (list.length > 0) {
                setLoading(false);
                setOrderListPos(orderList);
            }
            else {
                alert("No More Data")
                setLoading(false);
            }
        }
        else {
            //alert("Not Found")
            setLoading(false);
        }
    }

    const nextPagePos = async () => {
        setLoading(true);
        const index = pageIndexPos + 1;

        const addFilterForPOS = [
            {
                fieldName: "serviceTypeId",
                operatorName: "Equal",
                compareValue: 754
            },
            {
                fieldName: "orderDate",
                operatorName: "Equal",
                compareValue: selectedDate
            }
        ]

        const list = await OrderApi.getRawOrderReport(addFilterForPOS, index);
        const orderList = list.orderList
        let summaryData = list.summary
        setSummary(summaryData)
        if (orderList.length > 0) {
            setPageIndexPos(index)
            setStartIndexPos(Number(100 * index) - 100) //Number(val.length * index) - 50
            setLoading(false);
            setOrderListPos(orderList);
        }
        else {
            alert("No More Data")
            setLoading(false);
        }
    }

    const handlePaymentPay = async (orderNo, ord1Id, orderListData) => {
        setLoading(true);
        const orderData = orderListData.find(item => item.ord1Id === ord1Id);

        if (!orderData) {
            console.error('Order not found');
            return;
        }

        let totalAmt = orderData.grandTotal;
        if (orderData.paymentType == 2) {
            totalAmt = orderData.grandTotal;
        }
        const balAmt = Number(totalAmt - (orderData.o1CancelledAmt + orderData.paymentAmount));

        if (balAmt && balAmt != 0) {
            // ******************* Payment Gateway ********************
            const filteredData = [{
                fieldName: "ord1Id",
                operatorName: "Equal",
                compareValue: orderData.ord1Id,
            }]

            const dateTime = await CurrentDateAndTime.getCurrentDateTime();
            // const dateTime = date.toISOString();

            const previousPaymentDetails = await Payment.getPaymentGatewayDetailsFilter(filteredData)

            let paymentData;

            if (previousPaymentDetails && previousPaymentDetails.length > 0) {
                const spiltData = Number((previousPaymentDetails[0].orderNo).split("_")[1]) || 0;

                orderNo = `${orderData.orderNo}_${spiltData + 1}`

                paymentData = {
                    ord1Id: orderData.ord1Id,
                    orderNo: orderNo,
                    orderRefNo: orderData.orderSeriesId,
                    date: dateTime,
                    amount: balAmt,
                    apiId: 0,
                    apiName: "",
                    pgOrderId: "",
                    pgOrderStatus: "",
                    pgTrackingId: "",
                    pgBankRefNo: "",
                    pgFailureMessage: "",
                    pgPaymentMode: "",
                    pgCardName: "",
                    pgStatusCode: "",
                    pgStatusMessage: "",
                    transactionDetail: "",
                    remarks: "",
                }
            }
            else {
                orderNo = orderData.orderNo;
                paymentData = {
                    ord1Id: orderData.ord1Id,
                    orderNo: orderNo,
                    orderRefNo: orderData.orderSeriesId,
                    date: dateTime,
                    amount: balAmt,
                    apiId: 0,
                    apiName: "",
                    pgOrderId: "",
                    pgOrderStatus: "",
                    pgTrackingId: "",
                    pgBankRefNo: "",
                    pgFailureMessage: "",
                    pgPaymentMode: "",
                    pgCardName: "",
                    pgStatusCode: "",
                    pgStatusMessage: "",
                    transactionDetail: "",
                    remarks: "",
                }
            }
            const insertedData = await Payment.insertPayment(paymentData);

            // ***********************************
            const protocol = window.location.protocol;
            const hostname = window.location.hostname;
            const port = window.location.port;

            const fullUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

            // const returnUrl = `${fullUrl}/Response`;
            const returnUrl = `https://pay.mothershut.com/Response`;

            const data = { orderNo, balAmt, returnUrl };
            const apiUrl = `${WebApiUrl}/api/JusPayPayment/initiateJuspayPayment`;
            try {
                const response = await axios.post(apiUrl, data, { withCredentials: true });

                if (response.data && response.data.payment_links && response.data.payment_links.web) {
                    const url = response.data.payment_links.web;
                    console.log("orderNo", orderNo);
                    window.location.href = url;
                    localStorage.setItem("JusPayOrderNo", orderNo);
                    localStorage.setItem("Ord1Id", ord1Id);
                } else {
                    console.error('Payment links not found in response');
                }
                setLoading(false);
            } catch (error) {
                console.error('Error initiating payment:', error);
                setLoading(false);
            }
        }
        else if (balAmt == 0) {
            alert("Payment has been done");
        }
        setLoading(false);
    }

    const [posSearch, setPosSearch] = useState();

    const handleDateChange = (e) => {
        const date = e.target.value;
        setSelectedDate(date);
        fetchOrderDataPOS(date);
    }

    const handlePosSearch = async (e) => {
        const value = e.target.value;
        setPosSearch(value);

        try {
            const fields = [
                "mobileNo",
                "name",
                "orderNo",
                "overallOrderStatus",
                "paymentStatus"
            ];

            const filterData = fields.map(field => ({
                and: [
                    {
                        fieldName: field,
                        operatorName: "LIKE",
                        compareValue: value
                    },
                    {
                        fieldName: "orderDate",
                        operatorName: "Equal",
                        compareValue: selectedDate
                    }
                ]
            }));

            const list = await OrderApi.getRawOrderReportForPOS(filterData, pageIndexPos);
            let summaryData = list.summary
            setSummary(summaryData)
            let data = list.orderList

            if (list) {
                var data1 = data.slice().reverse();
                console.log(data1);
                setLoading(false)
                setOrderListPos(data1);
            }
        }
        catch (error) {
            console.log(error);
        }

    }

    return (
        <div>
            <div className="" style={{ height: "99vh" }} onClick={suggestionClose}>
                <div style={{ height: "100vh", display: "flex", justifyContent: "start" }}>
                    {/************************** Left Sidebar******************/}
                    <div className="" style={{ width: "15%", borderRight: "2px solid darkgrey", }}>
                        <div style={{ display: "flex" }}>
                            <select className="form-control" onChange={handleItemDepartmentChange}
                                style={{ backgroundColor: "black", border: "1px solid white", borderRadius: "0px", fontWeight: "600", color: "white", cursor: "pointer", padding: "7px" }}>
                                <option style={{ backgroundColor: "white", padding: "15px", color: "black" }} value="default">Select Items</option>
                                {(() => {
                                    if (itemDepartment !== null && Array.isArray(itemDepartment)) {
                                        return itemDepartment.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.name}
                                            </option>
                                        ))
                                    }
                                })()}
                                {/* <option style={{ backgroundColor: "white", padding: "15px", color: "black" }}>Blast of Love</option>
                                    <option style={{ backgroundColor: "white", padding: "15px", color: "black" }}>Build your own taste</option>
                                    <option style={{ backgroundColor: "white", padding: "15px", color: "black" }}>Family Combos</option>
                                    <option style={{ backgroundColor: "white", padding: "15px", color: "black" }}>Golden Delights</option>
                                    <option style={{ backgroundColor: "white", padding: "15px", color: "black" }}>Paneer Lover</option>
                                    <option style={{ backgroundColor: "white", padding: "15px", color: "black" }}>Veggie Lover</option>
                                    <option style={{ backgroundColor: "white", padding: "15px", color: "black" }}>Wednesday & Saturday Offers</option> */}
                            </select>
                            {UserDetails.canModifyMaster == true && (
                                <button style={{
                                    color: "white", border: "1px solid white", backgroundColor: "black",
                                    padding: "10px", marginLeft: "-1px", width: "22%"
                                }} onClick={handleOpenItemMasterModal}>
                                    <i class="fa-solid fa-plus"></i>
                                </button>
                            )}
                        </div>
                        <div className="leftSideMenu">
                            <div style={{ backgroundColor: "lightgray" }}>
                                {(() => {
                                    if (itemGroupList !== null && Array.isArray(itemGroupList)) {
                                        return itemGroupList
                                            .filter(item => item.primaryGroup == false)
                                            .sort((a, b) => a.displayOrder - b.displayOrder)
                                            .map((list, index) => (
                                                <div className={itemDepartmentActiveIndex == (index + 1) ? `menuListAcive` : `menuList`} style={{}} onClick={() => itemGroupClick(list.d2_Id, index)}>
                                                    <div style={{ padding: "10px 14px", }}>
                                                        <label key={list.id} style={{ color: "black", fontWeight: "600", fontSize: "14px" }}>
                                                            {list.name}
                                                        </label>
                                                    </div>
                                                    <hr style={{ margin: "5px" }} />
                                                </div>
                                            ))
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                    {/************************** Menu Item ******************/}
                    <div className="menuContainer" style={{ height: "100vh" }}>
                        <div className=" " style={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
                            <div style={{ display: "flex", justifyContent: "space-evenly", backgroundColor: "black", padding: "5px", width: "100%", height: "40px" }}>

                                <input type="search" className="form-control" placeholder="Search" style={{ width: "100%" }} value={searchValue}
                                    onKeyDown={(event) => onHandleKeyPress(event)} onChange={onSearchChange} />

                                <input type="text" className="form-control" placeholder="Code" style={{ marginLeft: "5px", width: "100%" }}
                                    onKeyDown={(event) => onHandleCodeKeyPress(event)} onChange={handleCodeChange} />

                                {showInTable && (
                                    <div style={{ display: "flex", width: "115%" }}>
                                        <select className="ques form-control " onChange={(e) => onTableGroupSelection(e.target.value)} value={orderData.tableGroupId}
                                            style={{ selectStyle, border: "2px solid transparent", marginLeft: "4px", backgroundColor: "white", padding: "2px 8px", color: "#62686d" }}>
                                            <option disabled selected value="0">Select Floor</option>
                                            {(() => {
                                                if (tableGroup !== null && Array.isArray(tableGroup)) {
                                                    return tableGroup.map((data) => (
                                                        <option key={data.id} value={data.id}>
                                                            {data.name}
                                                        </option>
                                                    ))
                                                }
                                            })()}
                                        </select>
                                        <button style={{ color: "black", backgroundColor: "white", marginLeft: "3px", width: "22%", borderRadius: "5px", margin: "2px 0px", padding: "0px" }}
                                            onClick={handleOpenDineIn}>
                                            <i class="fa-solid fa-plus"></i>
                                        </button>
                                    </div>
                                )}
                                { // ((orderData.serviceTypeId == 752 || orderData.serviceTypeId == 753 || orderData.serviceTypeId == 754) && isAllowModificationOfVoucher != false) ||
                                    //     (orderData.serviceTypeId != 752 && orderData.serviceTypeId != 753 || orderData.serviceTypeId != 754) ? (
                                    showInOtherServiceType && isAllowModificationOfVoucher != false && (
                                        <select className="ques form-control" onChange={onOrderNoSelection}
                                            style={{
                                                selectStyle, border: "2px solid transparent", marginLeft: "4px",
                                                backgroundColor: "white", width: "100%", padding: "2px 8px", color: "#62686d"
                                            }}>
                                            <option value="0">Select Order No</option>
                                            {orderNoData !== null && Array.isArray(orderNoData) &&
                                                orderNoData.map((data) => (
                                                    <option key={data.ord1Id} value={data.ord1Id}>
                                                        {data.name} -- Ord No.{data.orderNo}
                                                    </option>
                                                ))}
                                        </select>
                                    )
                                    // ) : null
                                }
                            </div>
                            <div className="space-between menu-pos">
                                <div className="gridView-pos mt-3 overFlow_none" ref={containerRef} id="menu-pos" style={{ width: "100%" }}>
                                    {filteredItems
                                        .filter(parentAttribute => parentAttribute.attributeParentId === 0)
                                        .map(item => {
                                            const { available, message } = isAvailableForService(item);
                                            console.log("filteredItem", filteredItems.length);
                                            return (
                                                <div key={item.id}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-evenly",
                                                        opacity: available ? 1 : 0.6,
                                                        pointerEvents: available ? "auto" : "none"
                                                    }}
                                                    onClick={() => available && ItemButtonClick(item.d2Id)}
                                                >
                                                    <div className="menuItem" style={{ maxHeight: '150px' }}>
                                                        <img
                                                            src={`${imageBaseUrl}${(item?.images && item?.images == 0) ? companyData?.companyImageId : (item?.images?.length > 0 ? item?.images[0] : item?.images)}`}
                                                            ref={imageRef}
                                                            alt=""
                                                            style={{ height: "65px", width: "65px", borderRadius: "15px", border: "1px solid lightGrey" }}
                                                        />
                                                        <div className="mt-2" style={{}}>{/*marginLeft: "5px"*/}
                                                            <label className="menuLabel">{item.name}</label>
                                                            <h6 style={{ fontWeight: "600", color: "#606060", fontSize: "13px" }}>
                                                                Price: &#x20B9;{item.salePrice}
                                                            </h6>
                                                            {!available && (
                                                                <div style={{ color: "red", fontSize: "10px", marginTop: "5px", fontWeight: "600" }}>
                                                                    {message}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    {(filteredItems == null || filteredItems == undefined || filteredItems.length < 5) && (
                                        <div className="flex-center" style={{ alignContent: "center", flexWrap: "wrap", padding: "20px" }}>
                                            <button className="greenBtn" style={{ width: "100%", height: "80%" }} onClick={handleOpenItemMasterModal}>+ Add New Item </button>
                                        </div>
                                    )}
                                </div>
                                {/* ***************** Table List ********************** */}
                                {showInTable && (
                                    <div id="TableNo" className="overFlow_none" style={{ display: "none" }}>
                                        <div className="flex-start mt-3" style={{ cursor: "pointer", flexDirection: "column" }}>
                                            {(() => {
                                                if (tableData !== null && Array.isArray(tableData)) {
                                                    return tableData.map((data, i) => (
                                                        <div className="mt-2" key={i}>
                                                            <div
                                                                className="tableNumber-pos"
                                                                style={{
                                                                    background: (() => {
                                                                        if (data.paymentStatus === "Done") {
                                                                            if (data.orderPrintStatus == true) {
                                                                                return 'linear-gradient(48deg, #34c0ee, rgb(237 105 253))';
                                                                            }
                                                                            else {
                                                                                return "#34c0ee";
                                                                            }
                                                                        } else if (data.tableStatus === "Selected") {
                                                                            return "#ffe4c4";
                                                                        } else if (data.tableStatus === "Busy") {
                                                                            return data.orderPrintStatus ? "#ed7cfbbf" : "yellowgreen";
                                                                        } else {
                                                                            return "white"; // Default color
                                                                        }
                                                                    })(),
                                                                }}
                                                                onClick={() => handleTableSelect(data.id, data.tableStatus)}
                                                            >
                                                                <div>
                                                                    <label>{data.tableNumber}</label><br />
                                                                    {/* <i className="fa-solid fa-sack-dollar" style={{ cursor: "pointer", color: "black" }}></i> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ));
                                                }
                                            })()}
                                        </div>
                                        {/*  style={{
                                                backgroundColor: data.paymentStatus === "Done"
                                                ? "linear-gradient(to right, #34c0ee, #00bfff)" // Gradient color
                                                    : (data.tableStatus === "Busy"
                                                    ? (data.orderPrintStatus === true ? "#34c0ee" : "yellowgreen")
                                                        : (data.paymentStatus === "Done" ? "goldenrod"
                                                            : (data.tableStatus === "Selected" ? "#ffe4c4" : "white")))
                                            }}*/}
                                    </div>
                                )}
                                {/* {showPopup && (
                                    <div className="popup_pos">
                                        <div className="arrow_pos" />
                                        <div className="popup-message_pos">Select Table from here !!</div>
                                    </div>
                                )} */}
                                {showPopup && (
                                    <div className="focusOnTable">

                                    </div>
                                )}
                            </div>
                            {/* *********** Item Footer ****** */}
                            <div style={{ position: "relative", width: "100%" }}>
                                {/* Overlapping div */}
                                <div style={{
                                    position: "absolute",
                                    top: "-33px",
                                    left: "40%",
                                    backgroundColor: "black",
                                    padding: "5px 10px",
                                    zIndex: 1,
                                    borderRadius: "5px",
                                    color: "white",
                                    width: "15%"
                                }} onClick={handlePOSReportOpen}>
                                    <i class="fa-solid fa-magnifying-glass"></i>
                                </div>

                                {/* Original div */}
                                <div className="mt-1" style={{ padding: "5px 2px", border: "1px solid lightgrey" }}>
                                    <div className="space-evenly mt-2">
                                        <div style={{ display: "flex" }}>
                                            <div className="pos-circle mt-1" style={{ backgroundColor: "white", border: "1px solid #b3adad" }}></div>
                                            <label className="ques">&nbsp; Free &nbsp;</label>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <div className="pos-circle mt-1" style={{ backgroundColor: "bisque", border: "1px solid #b3adad" }}></div>
                                            <label className="ques">&nbsp; Selected &nbsp;</label>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <div className="pos-circle mt-1" style={{ backgroundColor: "yellowGreen" }}></div>
                                            <label className="ques">&nbsp; Busy &nbsp;</label>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <div className="pos-circle mt-1" style={{ backgroundColor: "#ed7cfbbf" }}></div>
                                            <label className="ques">&nbsp; Printed &nbsp;</label>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <div className="pos-circle mt-1" style={{ backgroundColor: "#34c0ee" }}></div>
                                            <label className="ques">&nbsp; Payment Done &nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/******************************************************************* Cart ******************************************************************/}
                    <div className="" style={{ width: "45%", borderLeft: "2px solid darkgrey", paddingLeft: "0px" }}>
                        <div style={{ display: "flex", justifyContent: "space-evenly", width: "100%", backgroundColor: "black", padding: "0px", borderRadius: "10px", position: "sticky", zIndex: "1" }}>
                            {(() => {
                                if (serviceType !== null && Array.isArray(serviceType)) {
                                    return serviceType.map((data, index) => (
                                        <button className="serviceTypeBtnActive" style={getButtonStyle(index + 1)} key={data.id}
                                            onClick={() => handleButtonClick((index + 1), data.id)}>
                                            {data.name}
                                        </button>
                                    ))
                                }
                            })()}
                        </div>
                        <div className="cartScroll" style={{ marginLeft: "00px", }}>
                            <div style={{ display: "flex", justifyContent: "flex-start", backgroundColor: "#f2f2f2" }}>
                                {/* <div className="paymentDiv-pos" style={{}}>
                                    <i className="fa-solid fa-burger payemntIcon"></i>
                                </div> */}
                                <div className="paymentDiv-pos space-between multiplePosDiv" style={{ flexDirection: "unset" }}>
                                    <div className="w-100">
                                        <div className="space-between">
                                            <div className="flex-start">
                                                <label className="ques">Captain&nbsp;:&nbsp;</label>
                                                <select className="ans  POS_Select_im_input" onChange={handleOrderTakerSelect} value={orderData.orderConfirmedBy}
                                                    style={{ selectStyle, backgroundColor: "transparent", width: "65%", paddingLeft: "2px", fontSize: "14px", padding: "1px" }}>
                                                    <option value="default">Select Name</option>
                                                    {/* <option value="563">Order Taker</option> */}
                                                    {(() => {
                                                        if (users !== null && Array.isArray(users)) {
                                                            return users
                                                                // .filter(item => item.loginType == 563)
                                                                .map((data) => (
                                                                    <option key={data.id} value={data.id}>
                                                                        {data.userName}
                                                                    </option>
                                                                ))
                                                        }
                                                    })()}
                                                </select>
                                            </div>
                                            <div className="flex-end">
                                                {orderData.ord1Id != 0 && (
                                                    <>
                                                        <i className="fa-solid fa-c" style={{ color: "rgb(87, 86, 86)" }} onClick={openCancelOrderModal}></i> &nbsp; &nbsp;
                                                        {orderData.serviceTypeId == 751 && (
                                                            <i className="fa-solid fa-couch" style={{ color: "rgb(87, 86, 86)" }} onClick={openTableChangeModal}></i>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div className="flex-start" style={{ padding: "1px" }}>
                                                <label className="ques">Hi, &nbsp;</label>
                                                <label className="ques">{loginUserDetails.userName ? loginUserDetails.userName : 'Loading...'}</label>
                                            </div>
                                        </div>
                                        <div className="space-between">
                                            <div className="" style={{ padding: "1px" }}>
                                                {/* <label className="ques">Counter No : &nbsp;</label> */}
                                                <select className="ques POS_Select_im_input" onChange={(e) => billSeriesChange(e.target.value)}
                                                    value={orderData.orderSeriesId}
                                                    style={{ selectStyle, backgroundColor: "transparent", width: "auto", paddingLeft: "2px", fontSize: "14px", padding: "1px" }}>
                                                    <option disabled selected>Select Bill Series</option>
                                                    {(() => {
                                                        if (billseries !== null && Array.isArray(billseries)) {
                                                            return billseries
                                                                .map((data) => (
                                                                    <option key={data.id} value={data.id}>
                                                                        {data.billSeriesName}
                                                                    </option>
                                                                ))
                                                        }
                                                    })()}
                                                </select>
                                                <label className="ans"> : {orderData.orderNo}</label>{/*Counter,BillSeries*/}
                                            </div>
                                            {orderData.serviceTypeId == 751 && (
                                                <div className="flex-end">
                                                    <select className="ques  POS_Select_im_input" onChange={(e) => onFloorSelect(e.target.value)} value={orderData.tableGroupId} disabled={true}
                                                        style={{ selectStyle, border: "1px solid transparent", backgroundColor: "transparent", width: "50%", paddingLeft: "2px", fontSize: "14px", textAlign: "end", padding: "1px" }}>
                                                        <option value="0">Floor</option>
                                                        {(() => {
                                                            if (tableGroup !== null && Array.isArray(tableGroup)) {
                                                                return tableGroup.map((data) => (
                                                                    <option key={data.id} value={data.id}>
                                                                        {data.name}
                                                                    </option>
                                                                ))
                                                            }
                                                        })()}
                                                    </select>
                                                    <label>:</label>
                                                    <select className="ans  POS_Select_im_input" onChange={(e) => onTableSelect(e.target.value)} value={orderData.tableId} disabled={true}
                                                        style={{ selectStyle, border: "1px solid transparent", backgroundColor: "transparent", width: "50%", paddingLeft: "2px", fontSize: "14px", padding: "1px" }}>
                                                        <option style={{ appearance: 'none', background: "" }} value="0">Table No.</option>
                                                        {(() => {
                                                            if (tableNumberData !== null && Array.isArray(tableNumberData)) {
                                                                return tableNumberData.map((data) => (
                                                                    <option key={data.id} value={data.id}>
                                                                        {data.tableName}
                                                                    </option>
                                                                ))
                                                            }
                                                        })()}
                                                    </select>
                                                </div>
                                            )}
                                            <div className="flex-start" style={{ padding: "1px" }}>
                                                {/* <label className="ques">Date : &nbsp;</label> */}
                                                <label className="ans">{currentDate} {currentTime}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ********************* {User details} *********************** */}
                            <div id="userDetails" style={{ backgroundColor: "white", padding: "0px 0px" }}>
                                <div className=" input-height2  space-between" style={{ width: "100%" }}>
                                    <div className="gridView3" id="gridView">
                                        <div style={{ position: "relative" }} onClick={handleClick}>
                                            <div className="input-containerEffect mt-3" style={{ margin: "1%" }}>
                                                <input
                                                    className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                                                    type="text" name="name" required
                                                    onChange={handleInputChange}
                                                    value={inputItemValue}
                                                    ref={input1Ref}
                                                    onKeyDown={(event) => handleKeyPressForSuggestion(event, input2Ref)}
                                                    autoComplete="off"
                                                />
                                                <label id="input1" className="labelEffect1">Name</label>
                                            </div>
                                            {isItemOpen && (
                                                <div style={{ position: "absolute", zIndex: "1", top: "42px", minWidth: "200px", maxHeight: "350px", backgroundColor: "" }}>
                                                    <div className="form-control dropdown-c scroll-container" style={{ backgroundColor: "ghostWhite", maxHeight: "350px", padding: "0px" }}>
                                                        {suggestedItems1
                                                            // .filter(item => item.parentGroupId === 0)
                                                            .map((suggestion, index) => (
                                                                <div style={{ backgroundColor: highlightedIndex === index ? '#dcdcf4' : 'transparent', padding: "0px", marginLeft: "5px" }}>
                                                                    <div className="" style={{ marginTop: "5px", display: "flex", justifyContent: "center", padding: "10px" }} key={index}>
                                                                        <label onClick={(e) => handleItemSuggestion(suggestion.name, suggestion.d1Id, suggestion.d2Id, e)}
                                                                            value={suggestion.d1Id} name="name" style={{ textAlign: "center", fontWeight: "500" }}>
                                                                            {suggestion.name}
                                                                        </label>
                                                                    </div>
                                                                    <hr style={{ padding: "0px", margin: "0px" }} />
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="input-containerEffect mt-3" style={{ margin: "1%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text"
                                                ref={input2Ref} onKeyDown={(event) => handleKeyPress(event, input3Ref)}
                                                name="aMobileNo" onChange={handleInputChange} value={inputValues.aMobileNo} required />
                                            <label id="input1" className="labelEffect1">Mobile No.</label>
                                        </div>
                                        {/* <div className="input-containerEffect mt-3" style={{ margin: "1%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="date"
                                                ref={input3Ref} onKeyDown={(event) => handleKeyPress(event, input4Ref)}
                                                name="adob" onChange={handleInputChange} value={inputValues.adob} required />
                                            <label id="input1" className="labelEffect1">Date of Birth &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>

                                        <div className="input-containerEffect mt-3" style={{ margin: "1%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="date"
                                                ref={input4Ref} onKeyDown={(event) => handleKeyPress(event, input5Ref)}
                                                name="adom" onChange={handleInputChange} value={inputValues.adom} required />
                                            <label id="input1" className="labelEffect1">Date Of Anniversary &nbsp; &nbsp; &nbsp;</label>
                                        </div> */}

                                        {/* <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text"
                                                ref={input5Ref} onKeyDown={(event) => handleKeyPress(event, input6Ref)}
                                                name="referralBy" onChange={handleInputChange} value={inputValues.referralBy} required /> */}

                                        {(() => {
                                            var val = orderData.serviceTypeId === 751 ? input1Ref : input6Ref;
                                            //console.log("orderData", orderData);
                                            return (
                                                <div className="input-containerEffect mt-3" style={{ margin: "1%" }}>
                                                    <select className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                                                        ref={input5Ref} onKeyDown={(event) => handleKeyPress(event, val)}
                                                        name="referralBy" onChange={handleInputChange} value={inputValues.referralBy} required>
                                                        <option value="0">Select Referred By</option>
                                                        {(() => {
                                                            if (account !== null && Array.isArray(account)) {
                                                                return account
                                                                    //.filter(item => item.parentGroupId == 0)
                                                                    .map((data) => (
                                                                        //console.log(data),
                                                                        <option key={data.id} value={data.d2Id}>
                                                                            {data.name}
                                                                        </option>
                                                                    ))
                                                            }
                                                        })()}
                                                    </select>
                                                    <label id="input1" className="labelEffect1">Referred By</label>
                                                </div>
                                            )
                                        })()}
                                        {/* 
                                        {inputVisible && (
                                            <div className="input-containerEffect mt-3" id="DeliveryDate" style={{ margin: "1%", }}>
                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="date"
                                                    ref={input6Ref} onKeyDown={(event) => handleKeyPress(event, input7Ref)} required />
                                                <label id="input1" className="labelEffect1">Delivery Date &nbsp; &nbsp; &nbsp; </label>
                                            </div>
                                        )}
                                        {inputVisible && (
                                            <div className="input-containerEffect mt-3" id="DeliveryTime" style={{ margin: "1%", }}>
                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text"
                                                    ref={input7Ref} onKeyDown={(event) => handleKeyPress(event, input8Ref)} required />
                                                <label id="input1" className="labelEffect1">Delivery Time</label>
                                            </div>
                                        )}

                                        {inputVisible && (
                                            <div className="input-containerEffect mt-3" style={{ margin: "1%" }}>
                                                <input className="form-control inputEffect modify_im_input mode_limit mode_category input-s1" type="text"
                                                    ref={input8Ref} //onKeyDown={(event) => handleKeyPress(event, input9Ref)}
                                                    name="aAddress" onChange={handleInputChange} value={inputValues.aAddress} required />
                                                <label id="input1" className="labelEffect1">Location </label>
                                            </div>
                                        )} */}
                                    </div>

                                    <div className="flex-start" style={{ flexDirection: "column", }}>
                                        <i className="fa-solid fa-circle-plus mt-1" onClick={openAccountMasterModal} style={{ color: "rgb(19, 129, 196)" }}></i>
                                        {/* <i className="fa-solid fa-clock-rotate-left mt-1" onClick={toggleSlider} style={{ color: "darkgreen" }}></i> */}
                                        <i className="fa-solid fa-star mt-1" style={{ color: "orange" }} onClick={openFeedbackModal}></i>
                                        {/* <i className="fa-solid fa-envelope mt-1" style={{ color: "darkred" }} onClick={handleMessageBtn}></i> */}
                                    </div>
                                </div>
                            </div>
                            {/****************************************************/}
                            <div className="cartItem" ref={cartItemRef}>
                                {(() => {
                                    if (cartData != null && cartData.length > 0 || (runningOrder != null && runningOrder.length > 0)) {
                                        return (
                                            <table className="tableStyle-pos" style={{}}>
                                                <thead style={{ backgroundColor: "#d3d3d34f", border: "1px solid lightgrey" }}>
                                                    <tr>
                                                        <th className="" style={{ width: "5%", textAlign: "center", fontSize: "14px" }}> </th>
                                                        <th className="" style={{ width: "40%", textAlign: "center", fontSize: "14px" }} onClick={toggleBottomSlider}>Item Name</th>
                                                        <th className="" style={{ width: "15%", textAlign: "center", fontSize: "14px" }}>Del. Pref.</th>
                                                        <th className="" style={{ width: "25%", textAlign: "center", fontSize: "14px" }}>Quantity</th>
                                                        <th className="" style={{ width: "15%", textAlign: "center", fontSize: "14px" }}>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* ********************* Running Order ******************************* */}
                                                    {runningOrder != null && runningOrder.length > 0 && (
                                                        <>
                                                            {Array.from(new Set(runningOrder
                                                                //.filter(item => item.quantity != 0)
                                                                .map(data => data.itemRunningOrderCounter))).map((counter, index) => (
                                                                    <React.Fragment key={index}>
                                                                        <tr style={{ backgroundColor: "rgba(191, 191, 191, 0.53)" }}>
                                                                            <td colSpan={5} style={{ fontWeight: "600", fontSize: "14px", padding: "5px" }}>
                                                                                <div className="space-between">
                                                                                    <label>Kot Running Order {counter + 1}</label>
                                                                                    <i class="fa-solid fa-print" style={{ marginTop: "4px", marginRight: "10px" }} onClick={() => handleKotPrint(counter)}></i>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        {runningOrder
                                                                            .filter(data => data.itemRunningOrderCounter === counter)
                                                                            .map((data, idx) => (
                                                                                <tr className="POS_Table_Tr1" key={idx} style={{ backgroundColor: data.color }}>
                                                                                    {/* <td className="POS_Table_Td1" >
                                                                                        <div className="POS_Table_Td2" style={{ backgroundColor: "darkgreen" }}>
                                                                                            <label className="POS_Table_Label1" style={{ fontSize: "12px" }}>{idx + 1}</label>
                                                                                        </div>
                                                                                    </td> */}
                                                                                    <td className="POS_Table_Td1" >
                                                                                        <div className="POS_Table_Td2" onClick={() => handleRunningOrderChange(data.ord1Id, data.ord2Id, data.quantity, data.overallOrderStatus, data.d2Id, data.o2KotStatus)}>
                                                                                            <label className="POS_Table_Label1" onClick={() => handleRunningOrderChange(data.ord1Id, data.ord2Id, data.quantity, data.overallOrderStatus, data.d2Id, data.o2KotStatus)}>X</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="POS_Table_Td3" onClick={() => openCommentModal(data.name, data.d2Id, data.d1ItemId)}>
                                                                                        {data.name}<i className="fa-solid fa-pen Icon_Pen_POS"></i>
                                                                                    </td>
                                                                                    <td className="" style={{ textAlign: "center" }}>
                                                                                        <input className="POS_Table_Input_Text hide-spinner" type="number" name="preferenceNo" value={data.preferenceNo >= 0 ? data.preferenceNo : 0} />
                                                                                    </td>
                                                                                    <td className="" style={{ textAlign: "left" }}>
                                                                                        <div className="mt-1">
                                                                                            <div className="quantity" style={{ justifyContent: "flex-start", marginLeft: "10px" }}>
                                                                                                <button style={{ paddingBottom: "4px", display: "flex", justifyContent: "center" }} onClick={() => handleRunningOrderChange(data.ord1Id, data.ord2Id, data.quantity, data.overallOrderStatus, data.d2Id, data.o2KotStatus)} className="quantity__minus">-</button>
                                                                                                <input className="quantity__input" type="text" value={data.quantity} />
                                                                                                <button className="quantity__plus" style={{ display: "flex", justifyContent: "center" }} onClick={() => runningIncrement(data.d2Id, data.overallOrderStatus, data.ord2Id)} >+</button>
                                                                                                <label style={{ fontSize: "12px" }}>&nbsp; x &#x20B9; {data.salePrice}&nbsp; &nbsp; </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="" style={{ textAlign: "center", fontSize: "13px" }}>
                                                                                        &#x20B9; {(data.quantity === 0 ? 1 : data.quantity) * data.salePrice}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                    </React.Fragment>
                                                                ))}
                                                        </>
                                                    )}
                                                    {/* ********************************** New Order ************************************** */}
                                                    {cartData !== null && cartData.length > 0 && (
                                                        <tr style={{ backgroundColor: "rgba(191, 191, 191, 0.53)" }}>
                                                            <td colSpan={5} style={{ fontWeight: "600", fontSize: "14px", padding: "5px" }}>New KOT</td>
                                                        </tr>
                                                    )}
                                                    {cartData !== null && cartData.length > 0 && cartData.map((data, index) => (
                                                        <>
                                                            <tr className="POS_Table_Tr1" key={index} style={{ backgroundColor: data.color }}>
                                                                <td className="POS_Table_Td1" >
                                                                    <div className="POS_Table_Td2" onClick={() => deleteCartItemClick(data.d2Id)}>
                                                                        <label className="POS_Table_Label1" onClick={() => deleteCartItemClick(data.d2Id)}>X</label>
                                                                    </div>
                                                                </td>
                                                                <td className="POS_Table_Td3" onClick={() => openCommentModal(data.name, data.d2Id, data.d1ItemId)}>{data.name}<i className="fa-solid fa-pen Icon_Pen_POS"></i></td>
                                                                <td className="" style={{ textAlign: "center" }}>
                                                                    <input className="POS_Table_Input_Text hide-spinner" type="number" name="preferenceNo" onFocus={(e) => handleFocus(e)}
                                                                        value={data.preferenceNo >= 0 ? data.preferenceNo : 0} onChange={(e) => handleDelPreferenceChange(e, data.d2Id)} />
                                                                </td>
                                                                <td className="" style={{ textAlign: "left" }}>
                                                                    <div className="mt-1">
                                                                        {/* <div className="" style={{ display: "flex", justifyContent: "center" }}>
                                                                                <button className="preferenceInput increDecreBtn" onClick={() => handleDecrement()}>-</button>
                                                                                <input onChange={handleChange} min={1} value={IncrementValue} className="preferenceInput bold" style={{ width: "20%", margin: "0%", paddingLeft: "1px", borderColor: "darkgray", textAlign: "center" }} />
                                                                                <button className="preferenceInput increDecreBtn" onClick={() => handleIncrement()}>+</button>
                                                                            </div> */}
                                                                        <div className="quantity" style={{ justifyContent: "flex-start", marginLeft: "10px" }}>
                                                                            <button
                                                                                style={{ paddingBottom: "4px" }}
                                                                                className="quantity__minus"
                                                                                onClick={() => handleDecrement(data.d2Id)}
                                                                            > - </button>
                                                                            <input className="quantity__input"
                                                                                type="text"
                                                                                onChange={(event) => onQuantityChange(event, data.d2Id)}
                                                                                value={data.quantity}
                                                                            />
                                                                            <button className="quantity__plus"
                                                                                onClick={() => handleIncrement(data.d2Id)}
                                                                            >  + </button>
                                                                            <label style={{ fontSize: "12px" }}>&nbsp; x &#x20B9; {data.salePrice}&nbsp; &nbsp; </label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="" style={{ textAlign: "center", fontSize: "13px" }}>&#x20B9; {(data.quantity == 0 ? 1 : data.quantity) * data.salePrice}</td>
                                                                {/* <td className="" style={{ textAlign: "center", fontSize: "13px" }}>{data.newTotal} &#x20B9; {data.quantity * data.salePrice}</td> */}
                                                            </tr>
                                                        </>
                                                    ))}
                                                </tbody>
                                            </table>
                                        );
                                    }
                                    else {
                                        return <div>
                                            <hr />
                                            <p style={{ fontWeight: "700", textAlign: "center", marginTop: "25px" }}>No items in the cart.</p></div>;
                                    }
                                })()}
                            </div>
                            <div style={{ position: "relative", zIndex: 6 }}>
                                <div className="space-between" style={{ padding: "1px 15px", backgroundColor: "ghostwhite", borderTop: "2px solid black", alignItems: "", paddingTop: "8px" }}>
                                    <div id="comment" style={{ width: '75%' }}>
                                        <div className="input-containerEffect" style={{ padding: "0px", width: "100%", marginBottom: "0px" }}>
                                            {/* <label className="fw-600">Order wise Comment</label> */}
                                            <textarea className=" pos_AreaText_input" placeholder="Order Wise Comment.." style={{ height: "auto", width: "75%", padding: "0px" }}
                                                name="orderSuggestion" onChange={handleChange} value={orderData.orderSuggestion}></textarea>
                                        </div>
                                    </div>
                                    <div>
                                        {ord1TotalCancelAmount != 0 && (
                                            <>
                                                <div className="flex" style={{ justifyContent: "flex-end" }}>
                                                    {/* <label style={{ color: "black", fontWeight: "700", fontSize: "16px" }}>-</label> */}
                                                    <ToolTipMaster cancelDetail={true} cancelItemDetailModal={cancelItemDetailModal} />
                                                    <label style={{ color: "black", fontWeight: "700", fontSize: "16px" }}>&nbsp;Cancel&nbsp;Amt:&nbsp;&#x20B9;</label>
                                                    <label style={{ color: "black", fontWeight: "700", fontSize: "16px" }} value={ord1TotalCancelAmount || 0}>{ord1TotalCancelAmount || 0}</label> {/*id="Total"*/}
                                                </div>
                                                <hr style={{ padding: "0px", margin: "0px" }} />
                                            </>
                                        )}
                                        <div className="flex" style={{ justifyContent: "flex-end" }}>
                                            <label style={{ color: "black", fontWeight: "700", fontSize: "16px" }}>Total Amt:&nbsp;&#x20B9;</label>
                                            <label style={{ color: "black", fontWeight: "700", fontSize: "16px" }} value={totalAmount}>{totalAmount}</label> {/*id="Total"*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isButtonVisible && (
                            <div className="" style={{ position: "relative", zIndex: 6, height: "40px", backgroundColor: "black", padding: "2px", display: "flex", justifyContent: "space-evenly" }}>
                                {/* <button className="cart-btn" style={{ width: "25%" }}>Save</button> */}
                                {/* <button className="serviceTypeBtnActive" style={{ width: "50%" }} onClick={OrderAndPaymentDone}>Order & Payment Done</button> */}
                                {(companyData && companyData?.isPOSConfirmNKotBtn == true) ? (
                                    <button className="OrderNow-btn" style={{ width: "100%" }} onClick={() => ConfirmAndKot(false)}>Confirm & KOT</button> // {/*KOT & Print */}
                                ) : (
                                    <button className="OrderNow-btn" style={{ width: "100%" }} onClick={() => KotAndPrint(true)}>KOT & Print</button>
                                )}
                                {orderData.ord1Id !== 0 && orderData.ord1Id !== undefined && orderData.ord1Id !== null && orderData.ord1Id !== ""
                                    && (
                                        <>
                                            <button className="OrderNow-btn" style={{ width: "100%" }} onClick={BillPrintBtn}>Bill Print</button>
                                            {tableData && tableData.filter(item => item.id == orderData.tableId).map((item, index) => {
                                                if (item.orderPrintStatus === true) {
                                                    if (cartData && cartData.length > 0 || isRunningOrderChange == true) {
                                                        return (
                                                            <button key={index} className="OrderNow-btn" style={{ width: "100%" }} onClick={handleSettleBillClick}> Settle Bill </button>
                                                        );
                                                    }
                                                    return (
                                                        <button className="OrderNow-btn" style={{ width: "100%" }} onClick={OrderDoneBtn}>Settle Order</button>
                                                    )
                                                }
                                                return (
                                                    <button className="OrderNow-btn" style={{ width: "100%" }} onClick={OrderDoneBtn}>Settle Order</button>
                                                )
                                            })
                                            }
                                        </>
                                    )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/************************** Table Change Modal ***************/}
            {
                tableChangeModal && (
                    <div className="modal-back">
                        <div className="partyDetail-modal" style={{ height: "auto", backgroundColor: "white", width: "35%", padding: "15px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                <label className="fw-600" style={{ fontSize: "18px" }}>Change Table</label>
                                <i className="fa-solid fa-xmark xmark" onClick={closeTableModal}></i>
                            </div>
                            <hr style={{ padding: "0px", margin: "5px", marginTop: "10px" }} />
                            <div className="">
                                <label className="ques" style={{ marginTop: "5px" }}>Order Number</label>
                                <input type="text" value={ordDetail.orderNo} className="form-control" />

                                <label className="ques" style={{ marginTop: "10px" }}>Current Table</label>
                                <input type="text" value={ordDetail.tableName} className="form-control" />

                                <label className="ques" style={{ marginTop: "10px" }}>Table Group</label>
                                <select className="form-control  inputEffect" style={{ padding: "7px" }} onChange={onTableGroupDataChange}>
                                    <option>Select</option>
                                    {(() => {
                                        if (tableGroup !== null && Array.isArray(tableGroup)) {
                                            return tableGroup.map((data) => (
                                                <option key={data.id} value={data.id}>
                                                    {data.name}
                                                </option>
                                            ))
                                        }
                                    })()}
                                </select>
                                <label className="ques" style={{ marginTop: "10px" }}>Table List</label>
                                <select className="form-control inputEffect" style={{ padding: "7px" }} onChange={handleTableChange}>
                                    <option>Select</option>
                                    {(() => {
                                        if (tableChangeData !== null && Array.isArray(tableChangeData)) {
                                            return tableChangeData.filter(item => item.tableStatus == "Free")
                                                .map((data) => (
                                                    <option key={data.id} value={data.tableId}>
                                                        {data.tableName}
                                                    </option>
                                                ))
                                        }
                                    })()}
                                </select>
                            </div>
                            <hr />
                            <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                <button className="greenBtn" style={{ width: "25%" }} onClick={tableChangeBtn}>Save</button>
                                <button className="redBtn" style={{ width: "25%" }} onClick={closeTableModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )
            }
            {/******************************* Item Master Modal *************************/}
            {
                itemMasterModal && (
                    <div className="modal-back" onClick={closeItemMasterModal}>
                        <div onClick={handleModalClick} className="partyDetailPosModal" style={{ height: "auto", width: "35%" }}>
                            <div style={{ padding: "15px" }} onClick={closeSuggestion}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <label style={{ fontWeight: "600", color: "black" }}>Add Item</label>
                                    <label style={{ cursor: "pointer", fontSize: "24px", alignItems: "baseline" }} onClick={closeItemMasterModal}>x</label>
                                </div>
                                <hr />
                                <div style={{ margin: "10px", padding: "0px 10px" }}>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ position: "relative", width: "50%" }}>
                                            <div className="input-box-m11" style={{ width: "100%" }}>
                                                <input className="modify_im_input mode_limit mode_category"
                                                    onChange={handleItemMasterChange} name="ProductName" type="text" required />
                                                <label className="modifyTextLabel">Product Name</label>
                                            </div>
                                            {isItemSuggestionOpen && (
                                                <div style={{ position: "absolute", zIndex: "1", top: "42px", minWidth: "200px", maxHeight: "350px", backgroundColor: "" }}>
                                                    <div className="form-control dropdown-c scroll-container" style={{ backgroundColor: "ghostWhite", maxHeight: "350px", padding: "0px" }}>
                                                        {searchData
                                                            // .filter(item => item.parentGroupId === 0)
                                                            .map((suggestion, index) => (
                                                                <div style={{ backgroundColor: highlightedIndex === index ? '#dcdcf4' : 'transparent', padding: "0px", marginLeft: "5px" }}>
                                                                    <div className="" style={{ marginTop: "5px", display: "flex", justifyContent: "center", padding: "10px" }} key={index}>
                                                                        <label //onClick={(e) => handleItemSuggestion(suggestion.name, suggestion.d1Id, suggestion.d2Id, e)}
                                                                            value={suggestion.d1Id} name="name" style={{ textAlign: "center", fontWeight: "500" }}>
                                                                            {suggestion.name}
                                                                        </label>
                                                                    </div>
                                                                    <hr style={{ padding: "0px", margin: "0px" }} />
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="input-box-m11">
                                            <input className="modify_im_input mode_limit mode_category"
                                                onChange={handleItemMasterChange} name="SalePrice" type="text" required />
                                            <label className="modifyTextLabel">Product Price</label>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ padding: "0px", margin: "5px" }} />
                                <div className="flex-end" style={{ padding: "0px" }}>
                                    <button className="greenBtn" onClick={handleInsertItemMaster} style={{ width: "20%" }}>Add Item</button>
                                    <button className="redBtn" onClick={closeItemMasterModal} style={{ width: "20%" }}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {/************************* Cancel Item Wise Modal *****************************/}
            {
                cancelModal && (
                    <div className="modal-back">
                        <div className="partyDetail-modal" style={{ height: "auto", backgroundColor: "white", width: "35%", padding: "15px" }}>
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between", position: "relative", alignItems: "baseline" }}>
                                    <label><h5 style={{ fontWeight: "600" }}>Cancel Item</h5></label>
                                    <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeCancelModal}>x</label>
                                </div>
                                <hr style={{ padding: "0px", margin: "0px" }} />
                                <div>
                                    <div className="cancelModalPopup" style={{ marginBottom: "15px", height: "auto" }}>
                                        <>
                                            {itemCancelList !== null && itemCancelList.length > 0 && (
                                                itemCancelList
                                                    .filter(item => item.kotStatus !== "Pending")
                                                    .map((data, index) => {
                                                        const cancelItems = cancelItemList ? cancelItemList.filter(cancelItem => cancelItem.o2Ord2Id === data.ord2Id) : [];
                                                        const requestedItem = cancelItems && cancelItems.filter(item => item.cancelApprovalStatus === "Request");
                                                        const requestedQty = requestedItem.reduce((total, item) => total + item.cancelRequestQty, 0);
                                                        const totalCancelQty = cancelItems.reduce((total, item) => total + item.cancelRequestQty, 0);
                                                        const approvedItem = cancelItems && cancelItems.filter(item => item.cancelApprovalStatus === "Approve");
                                                        const totalApprovedQty = approvedItem.reduce((total, item) => total + (item.cancelApprovedQty || 0), 0);
                                                        const totalCancelAmt = totalCancelQty * data.rate;
                                                        console.log(`cancelItems`, cancelItems);
                                                        return (
                                                            <div className="detailDiv" key={index}>
                                                                <div className="mt-2">
                                                                    <div className="flex-start">
                                                                        <label className="ques">#{index + 1} &nbsp;</label>
                                                                        <label className="ques" style={{ color: "#000000ab" }}>{data.d2ItemName}</label>
                                                                    </div>
                                                                    <div className="space-evenly">
                                                                        <div className="flex-start">
                                                                            <label className="ques" style={{ color: "rgb(249, 123, 30)" }}>Qty : &nbsp;</label>
                                                                            <label className="ques" style={{ color: "#000000ab" }}>{data.qty}</label>
                                                                        </div>
                                                                        <div className="flex-start">
                                                                            <label className="ques" style={{ color: "rgb(249, 123, 30)" }}>Amt : &nbsp;</label>
                                                                            <label className="ques" style={{ color: "#000000ab" }}>&#x20B9; {data.qty * data.rate}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="space-evenly">
                                                                        <div className="flex-start">
                                                                            <label className="ques" style={{ color: "orange" }}>C.App. Qty : &nbsp;</label>
                                                                            <label className="ques">
                                                                                {totalApprovedQty ? totalApprovedQty : 0}
                                                                            </label>
                                                                        </div>
                                                                        <div className="flex-start">
                                                                            <label className="ques" style={{ color: "orange" }}>C.App. Amt : &nbsp;</label>
                                                                            <label className="ques">&#x20B9;
                                                                                {totalApprovedQty && cancelItems.length > 0 ? (totalApprovedQty * cancelItems[0].itemPrice) : 0}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="space-evenly">
                                                                        <select className="form-control" style={{ height: "35px", fontSize: "13px" }} name="cancelStatus"
                                                                            onChange={(e) => onHandleChange(e, data.ord2Id, false, 0, index, 0, 0, 0)} value={data.cancelStatus ? data.cancelStatus : cancelItems && cancelItems.length > 0 && cancelItems[0].cancelItems}>
                                                                            {/*<option value="">Select</option>*/}
                                                                            <option value="Cancel Item">Cancel Item</option>
                                                                            <option value="NCKOT">NCKOT</option>
                                                                        </select>
                                                                        <input
                                                                            className="form-control"
                                                                            type="number"
                                                                            placeholder="Qty"
                                                                            name="cancelQty"
                                                                            ref={inputRef}
                                                                            onClick={handleCancelFocus}
                                                                            value={(data.cancelQty && data.cancelQty != 0) ? data.cancelQty : 0}
                                                                            onChange={(e) => onHandleChange(e, data.ord2Id, data.qty, data.rate, index, data.cancelQty, totalApprovedQty, requestedQty)}
                                                                            style={{ fontSize: "13px", height: "35px", marginLeft: "5px" }}
                                                                        />
                                                                    </div>
                                                                    <div className="space-evenly mt-2">
                                                                        <textarea
                                                                            className="form-control"
                                                                            type="text"
                                                                            placeholder="Reason to Cancel"
                                                                            name="cancelRemarks"
                                                                            //value={data.cancelRemarks}
                                                                            onChange={(e) => onHandleChange(e, data.ord2Id, false, 0, index, 0, 0, 0)}
                                                                            style={{ fontSize: "13px", marginLeft: "5px", height: "35px", width: "100%" }}
                                                                        />
                                                                    </div>
                                                                    {/* <hr /> */}
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                            )}
                                        </>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-end" style={{ padding: "10px", borderTop: "1px solid darkgrey" }}>
                                <button className="greenBtn" style={{ width: "35%" }} onClick={cancelConfirmItem}>Cancel Item</button>
                                <button className="redBtn" style={{ width: "25%" }} onClick={closeCancelModal}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            }
            {/************************* Cancel Order Modal *****************************/}
            {
                cancelOrderModal && (
                    <div className="modal-back">
                        <div className="partyDetail-modal" style={{ height: "auto", backgroundColor: "white", width: "35%", padding: "15px" }}>
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between", position: "relative", alignItems: "baseline" }}>
                                    <label><h5 style={{ fontWeight: "600" }}>Cancel Order</h5></label>
                                    <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeCancelOrderModal}>x</label>
                                </div>
                                <hr style={{ padding: "0px", margin: "0px" }} />
                                <div>
                                    <div className="cancelModalPopup mt-3" style={{ marginBottom: "15px", height: "auto" }}>
                                        <>
                                            <textarea name="" value={remark} onChange={onRemarkChange} placeholder="Enter reason to Cancel"></textarea>
                                        </>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-end" style={{ padding: "10px", borderTop: "1px solid darkgrey" }}>
                                <button className="greenBtn" style={{ width: "35%" }} onClick={CancelItemBtn}>Cancel Item</button>
                                <button className="redBtn" style={{ width: "25%" }} onClick={closeCancelOrderModal}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            }
            {/******************************* Print Modal *************************/}
            {
                showPrintModal && (
                    <div style={{ display: "none" }}>
                        <div className="modal-back" onClick={closePrintModal}>
                            <div className="billPrintModal" style={{}}>
                                <div style={{ padding: "5px", backgroundColor: "white" }}>
                                    {/* <OverallAndKotPrint id={orderListData && orderListData[0].ord1Id} isOnloadPrint={true} closePrintModal={closePrintModal} backBtn={"modal"} /> */}
                                    {/* <OrderTakerBillPrint id={orderListData && orderListData[0].ord1Id} closePrintModal={closePrintModal} backBtn={"modal"} />*/}
                                    {/* {orderListData && orderListData.map((item, index) => ( */}
                                    <div style={{ marginTop: "30px", display: "none" }} key={index}>
                                        {/* {orderListData && orderListData.map((data, index) => ( */}
                                        <ReportDesign templateName="FinalBillPrint" ord1Id={orderListData && orderListData[0]?.ord1Id} setShowBillPrint={setShowPrintModal} />
                                        <ReportDesign templateName="KOTBillPrint" orderData={orderListData} ord1Id={0} setShowBillPrint={setShowPrintModal} />
                                        {/* ))} */}
                                        {/* <MultipleKotPrintPage orderListData={orderListData} setShowPrintModal={setShowPrintModal} isOnloadPrint={true} /> */}
                                    </div>
                                    {/*  ))} */}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {/************************** Payment Detail Modal ***************/}
            {
                paymentDetailModalPopup && (
                    <div className="">
                        <PaymantModeModel handlePayment={handlePayment}
                            PaymentModalData={PaymentModalData}
                            closePaymentModal={closePaymentDetailsModal} />
                    </div>
                )
            }
            {/******************************* Bill Modal *************************/}
            {
                showBillPrint && (
                    <div style={{ display: "none" }}> {/**/}
                        <div className="modal-back" onClick={closeBillPrint}>
                            <div className="billPrintModal" style={{}}>
                                <div style={{ padding: "5px", backgroundColor: "white", display: "none" }}>
                                    <ReportDesign templateName="FinalBillPrint" ord1Id={orderData.ord1Id} setShowBillPrint={setShowBillPrint} /> {/*TestReport */}
                                    {/*<OrderTakerBillPrint id={orderListData && orderListData[0]?.ord1Id} isOnloadPrint={true} closePrintModal={closeBillPrint} backBtn={"modal"} />*/}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {/******************************* Toast Message *************************/}
            {
                showToast && (
                    <div className="">
                        <div className="messageDiv" style={{ height: "auto", width: "auto", padding: "15px" }}>
                            <label style={{ color: "white" }}> Message Send Successfully !!</label>
                        </div>
                    </div>
                )
            }
            {/******************************* History Slider *************************/}
            <div onClick={toggleSlider} className={`slider-container ${isSliderOpen ? 'open' : ''}`}>
                <div onClick={handleModalClick} style={{ padding: "15px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <label></label>
                        <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={toggleSlider}>x</label>
                    </div>
                    <PreviousOrder />
                </div>
            </div>
            {/************************* Comment Modal *****************************/}
            {
                commentModal && (
                    <div className="modal-back">  {/*onClick={closeCommentModal}*/}
                        <div className="partyDetail-modal" style={{ height: "auto", backgroundColor: "white", width: "25%", padding: "15px" }}>
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between", position: "relative", alignItems: "baseline" }}>
                                    <label>Add Comment for {CommentName}</label>    {/*{CommentName}*/}
                                    <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeCommentModal}>x</label>
                                </div>
                                <hr />
                                <div className="">
                                    <div id="comment" style={{ backgroundColor: "white" }}>
                                        <div className="input-containerEffect" style={{ padding: "10px", width: "100%" }}>
                                            <textarea className="form-control" ref={input9Ref} placeholder="Add Item Comment.." value={selectedComment} onChange={handleCommentChange}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-end" style={{ padding: "10px" }}>
                                <button className="greenBtn" style={{ width: "22%" }} onClick={saveComment}>Save</button>
                                <button className="redBtn" style={{ width: "22%" }} onClick={closeCommentModal}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            }
            {/******************************* Coupon Modal*************************/}
            {
                CouponModal && (
                    <div className="modal-back" onClick={closeCouponModal}>
                        <div onClick={handleModalClick} className="partyDetailPosModal" style={{ height: "auto", width: "35%" }}>
                            <div style={{ padding: "15px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <label style={{ fontWeight: "600", color: "black" }}>View Coupon</label>
                                    <label style={{ cursor: "pointer", fontSize: "24px", alignItems: "baseline" }} onClick={closeCouponModal}>x</label>
                                </div>
                                <hr />
                                <div style={{ margin: "10px", padding: "0px 10px" }}>
                                    <input type="text" placeholder="Enter Coupon" style={{ width: "100%", padding: '5px', borderRadius: "5px" }} />
                                    <div className="couponBox mt-2">
                                        <div className="couponLeftSide">
                                            <label className="rotated-text" style={{ color: "white", fontWeight: "600" }}>20% OFF</label>
                                        </div>
                                        <div className="" style={{ padding: "1px 10px", width: "100%" }}>
                                            <div className="space-between">
                                                <label className="couponName">FRAMEFREE</label>
                                                <button className="apply-button" style={{ padding: "5px" }}>Apply</button>
                                            </div>
                                            <div>
                                                <label style={{ color: "grey" }}>Save &#x20B9;50 on this order.</label><br />
                                                <label style={{ color: "grey" }}>Use code FRAMEFREE & get flat 20% off on this order.</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="couponBox mt-3">
                                        <div className="couponLeftSide">
                                            <label className="rotated-text" style={{ color: "white", fontWeight: "600" }}>20% OFF</label>
                                        </div>
                                        <div className="" style={{ padding: "1px 10px", width: "100%" }}>
                                            <div className="space-between">
                                                <label className="couponName">FRAMEFREE</label>
                                                <button className="apply-button" style={{ padding: "5px" }}>Apply</button>
                                            </div>
                                            <div>
                                                <label style={{ color: "grey" }}>Save &#x20B9;50 on this order.</label><br />
                                                <label style={{ color: "grey" }}>Use code FRAMEFREE & get flat 20% off on this order.</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <hr style={{ padding: "0px", margin: "5px" }} />
                                <div className="flex-end" style={{ padding: "0px" }}>
                                    <button className="greenBtn" onClick={closeCouponModal} style={{ width: "20%" }}>Apply</button>
                                    <button className="redBtn" onClick={closeCouponModal} style={{ width: "20%" }}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {/************************** Variable Modal ***************/}
            {
                VariableItemModal && (
                    <div className="modal-back">
                        <div className="variableItem-modal">
                            <i onClick={closeVariableItemModal} style={{ position: "absolute", right: "12px" }} id="modal_box_close" className="fa-sharp fa-solid fa-xmark" ></i>
                            <h1 style={{ fontSize: "22px", paddingLeft: "10px", color: "#6a6666" }} className="c-modal-txt">Variable Item</h1>
                            <hr />
                            <div className="space-evenly" style={{ padding: "10px" }}>
                                <div className="gridView3">
                                    {/* {(() => {
                                    if (VariableData !== null && Array.isArray(VariableData)) {
                                        return VariableData.map((data) => (
                                            <div style={{ margin: "5px" }}>
                                                <div className="variationDiv" style={getButtonStyle1(4)}
                                                    onClick={() => handleButtonClickforVariableItem(4)}>
                                                    <label className="ques1">{data.name} </label><br />
                                                    <label className="ques1">&#x20B9; {data.salePrice}</label>
                                                </div>
                                            </div>
                                        ))
                                    }
                                })()} */}
                                    {VariableData !== null && Array.isArray(VariableData) && VariableData.map((data, index) => (
                                        <div key={index} style={{ margin: "5px" }}>
                                            <div
                                                className="variationDiv"
                                                style={index === selectedItemIndex ? getVariableStyle() : getVariableStyleWhite()}
                                                onClick={() => handleButtonClickforVariableItem(index, data.d2Id)}
                                            >
                                                <label className="ques1">{data.name} </label><br />
                                                <label className="ques1">&#x20B9; {data.salePrice}</label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <hr />
                            <div className="flex-end" style={{ paddingBottom: "15px" }}>
                                <button className="redBtn" onClick={closeVariableItemModal} style={{ width: "20%", backgroundColor: "#d03a3af2" }}> Cancel </button>
                                <button className="greenBtn" onClick={VariableSaveBtn} style={{ width: "20%" }}> Add </button>
                                <button className="greenBtn" onClick={variableCloseModal} style={{ width: "20%" }}> Add & Close </button>
                            </div>
                        </div>
                    </div>
                )
            }
            {/************************* Account Master Modal *****************************/}
            {
                AccountMasterModal && (
                    <div className="modal-back" onClick={closeAccountMasterModal}>
                        <div onClick={handleModalClick} className="partyDetail-modal" style={{ height: "auto", backgroundColor: "white", width: "85%", padding: "15px" }}>
                            <div style={{ display: "flex", justifyContent: "flex-end", position: "sticky" }}>
                                <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeAccountMasterModal}>x</label>
                            </div>
                            <div className="Pos_invoice_scroll_AM" >
                                <AccountMaster />
                            </div>
                        </div>
                    </div>
                )
            }
            {/************************* Feedback Modal *****************************/}
            {
                FeedbackModal && (
                    <div className="modal-back" onClick={closeFeedbackModal}>
                        <div onClick={handleModalClick} className="partyDetail-modal Pos_invoice_scroll" style={{ height: "auto", backgroundColor: "white", width: "60%", padding: "15px" }}>
                            <div style={{}}>
                                <div className="CloseHeadBtn">
                                    <spam className="CrossBtnBack" onClick={closeFeedbackModal}>x</spam>
                                </div>
                                <OrderFeedback closeFeedbackModal={closeFeedbackModal} serviceTypeId={orderData.serviceTypeId} />
                            </div>
                        </div>
                    </div>
                )
            }
            {/************************* Cancel Item Detail Modal (Alrerady Cancelled Item) *****************************/}
            {
                cancelItemModal && (
                    <div className="modal-back">
                        <div className="partyDetail-modal" style={{ height: "auto", backgroundColor: "white", width: "35%", padding: "15px" }}>
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between", position: "relative", alignItems: "baseline" }}>
                                    <label><h5 style={{ fontWeight: "600" }}>Cancel Detail Items</h5></label>
                                    <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeCancelItemDetailModal}>x</label>
                                </div>
                                <hr style={{ padding: "0px", margin: "0px" }} />
                                <div>
                                    <div className="scrollable_div mt-3" style={{ marginBottom: "15px", maxHeight: "445px" }}> {/* cancelModalPopup */}
                                        {cancelData && (
                                            <>
                                                {(() => {
                                                    const filteredData = cancelData.reduce((acc, current) => {
                                                        const existingItem = acc.find(item => item.itemOrd2Id === current.itemOrd2Id);
                                                        if (existingItem) {
                                                            if (existingItem.o2Ord2Id !== current.o2Ord2Id) {
                                                                existingItem.orderQty += current.orderQty;
                                                            }
                                                            existingItem.cancelRequestQty += current.cancelRequestQty;
                                                            existingItem.cancelApprovedQty += current.cancelApprovedQty;
                                                        } else {
                                                            acc.push({ ...current });
                                                        }
                                                        return acc;
                                                    }, []);

                                                    return filteredData.map((groupedData, index) => (
                                                        <div className="" key={index}>
                                                            <div className="flex-start">
                                                                <label className="ques">#{index + 1}&nbsp;</label>
                                                                <label className="ques" style={{ color: "#3892b7" }}>{groupedData.itemName}</label>
                                                            </div>
                                                            <div className="space-evenly">
                                                                <div className="flex-start">
                                                                    <label className="ques" style={{ color: "green" }}>Qty : &nbsp;</label>
                                                                    <label className="ques">{groupedData.orderQty}</label>
                                                                </div>
                                                                <div className="flex-start">
                                                                    <label className="ques" style={{ color: "green" }}>Amt : &nbsp;</label>
                                                                    <label className="ques">&#x20B9; {groupedData.itemPrice}</label>
                                                                </div>
                                                            </div>
                                                            <div className="space-evenly">
                                                                <div className="flex-start">
                                                                    <label className="ques" style={{ color: "darkred" }}>Total C.Req Qty : &nbsp;</label>
                                                                    <label className="ques">{groupedData.cancelRequestQty}</label>
                                                                </div>
                                                                <div className="flex-start">
                                                                    <label className="ques" style={{ color: "darkred" }}>Total C.Req Amt : &nbsp;</label>
                                                                    <label className="ques">&#x20B9; {groupedData.cancelRequestQty * groupedData.itemPrice}</label>
                                                                </div>
                                                            </div>
                                                            <div className="space-evenly">
                                                                <div className="flex-start">
                                                                    <label className="ques" style={{ color: "orange" }}>Total C.App. Qty : &nbsp;</label>
                                                                    <label className="ques">{groupedData.cancelApprovedQty}</label>
                                                                </div>
                                                                <div className="flex-start">
                                                                    <label className="ques" style={{ color: "orange" }}>Total C.App. Amt : &nbsp;</label>
                                                                    <label className="ques">&#x20B9; {groupedData.cancelApprovedQty * groupedData.itemPrice}</label>
                                                                </div>
                                                            </div>
                                                            <div className="space-evenly">
                                                                <div className="flex-start">
                                                                    <label className="ques" style={{ color: "#707070" }}>Cancel Requested By : &nbsp;</label>
                                                                    <label className="ques">{groupedData.cancelRequestedByName}</label>
                                                                </div>
                                                                <div className="flex-start">
                                                                    <label className="ques" style={{ color: "#707070" }}>Cancel Approved By : &nbsp;</label>
                                                                    <label className="ques">{groupedData.cancelApprovedByName}</label>
                                                                </div>
                                                            </div>
                                                            <hr style={{ padding: "0px", margin: "2px" }} />
                                                        </div>
                                                    ));
                                                })()}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex-end" style={{ padding: "10px", borderTop: "1px solid darkgrey" }}>
                                {/* <button className="greenBtn" style={{ width: "35%" }} >Cancel Item</button> */}
                                <button className="redBtn" style={{ width: "25%" }} onClick={closeCancelItemDetailModal}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            }
            {/************************* Tables / Dine-In *****************************/}
            {
                openDineIn && (
                    <div className="modal-back">
                        <div className="partyDetail-modal" style={{ height: "auto", backgroundColor: "white", width: "90%", padding: "15px" }}>
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between", position: "relative", alignItems: "baseline" }}>
                                    <label><h5 style={{ fontWeight: "600" }}>DINE-IN TABLE OVERVIEW</h5></label>
                                    <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={handleCloseDineIn}>x</label>
                                </div>
                                <hr style={{ padding: "0px", margin: "0px" }} />
                                <div>
                                    <div className="scrollable_div mt-3" style={{ marginBottom: "15px", maxHeight: "445px" }}> {/* cancelModalPopup */}
                                        <DineIn handleTableSelect={handleTableSelect} handleCloseDineIn={handleCloseDineIn} />
                                    </div>
                                </div>
                            </div>
                            <div className="flex-end" style={{ padding: "10px", borderTop: "1px solid darkgrey" }}>
                                <button className="redBtn" style={{ width: "25%" }} onClick={handleCloseDineIn}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            }
            <PosSlider toggleBottomSlider={toggleBottomSlider}
                openPaymentDetailsModal={openPaymentDetailsModal}
                // isOpen={isOpen}
                AllocatedTimeSlots={AllocatedTimeSlots}
                isSliderOpen_bottomUp={isSliderOpen_bottomUp}
                orderData={orderData}
                loginUserDetails={loginUserDetails}
                moment={moment}
                handleFocus={handleFocus}
                handleChange={handleChange}
                saveNoOfPerson={saveNoOfPerson}
                SavePaymentBtn={SavePaymentBtn}
                firstInputRef={firstInputRef}
                inputValues1={inputValues1}
                handlePaymentChange={handlePaymentChange}
                paymentDetails={paymentDetails}
                openCouponModal={openCouponModal}
            />
            {/* ************************** POS REPORT ************************************ */}
            {openPOSReportModal && (
                <div className="modal-back" onClick={handleClosePOSReport}>
                    <div onClick={handleModalClick} className="partyDetail-modal Pos_invoice_scroll" style={{ height: "auto", maxHeight: "88%", backgroundColor: "white", width: "80%", padding: "15px" }}>
                        <div style={{}}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <label style={{ fontWeight: "600", color: "black", fontSize: "18px" }}>POS ORDER LIST</label>
                                <label style={{ cursor: "pointer", fontSize: "24px", alignItems: "baseline" }} onClick={handleClosePOSReport}>x</label>
                            </div>
                            <hr />
                            <div>
                                <div className="space-between">
                                    <div className="input-box-m11">
                                        <input
                                            className="modify_im_input mode_category"
                                            onChange={handleDateChange}
                                            value={selectedDate}
                                            type="date"
                                            name=""
                                            required
                                        />
                                        <label className="modifyTextLabel">Order Date</label>
                                    </div>
                                    <div className="input-box-m11">
                                        <input
                                            className="modify_im_input mode_category"
                                            onChange={handlePosSearch}
                                            value={posSearch}
                                            type="text"
                                            name=""
                                            required
                                        />
                                        <label className="modifyTextLabel">Search</label>
                                    </div>
                                </div>
                                {/* ************************** Page Index *********************** */}
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                        justifyContent: "flex-end",
                                        width: "100%",
                                        margin: "5px 0px"
                                    }}
                                >
                                    <button
                                        onClick={() => previousPagePos()}
                                        className="table-list-button"
                                    >
                                        <i className="fa-solid fa-chevron-left"></i>
                                    </button>
                                    <span className="table-list-page-txt">
                                        Page -
                                        <strong style={{ margin: "0px 3px" }}>
                                            {pageIndexPos}
                                        </strong>
                                    </span>
                                    <button
                                        onClick={() => nextPagePos()}
                                        className="table-list-button"
                                    >
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </button>
                                </div>
                                <POS formatDate={formatDatePOS}
                                    fetchOrderData={fetchOrderDataPOS}
                                    summary={summary}
                                    orderListPos={orderListPos}
                                    setOrderListPos={setOrderListPos}
                                    serviceTypeId={754}
                                    handleRefreshBtn={handleRefreshBtn}
                                    isShowTotalAmountInReport={isShowTotalAmountInReport}
                                    startIndexPos={startIndexPos}
                                    handlePaymentPay={handlePaymentPay} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {loading && <Loader />}
        </div >
    );
};

export default OrderPage;

