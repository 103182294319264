import React, { useEffect, useState } from 'react'
import WebTemplate from '../../API/APIServices/WebTemplate';
import { useParams } from 'react-router-dom';
import TemplateSetting from '../../API/APIServices/TemplateSetting';
import 'jspdf-autotable';
import html2pdf from 'html2pdf.js';
import Loader from "../../Inventory/Props/Loader";
// import "jspdf-autotable";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const ReportDesign = (props) => {
    const [apiData, setApiData] = useState([]);
    const [droppedItems, setDroppedItems] = useState([]);
    const [styleArray, setStylesArray] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        console.log("styleArray", styleArray);
        console.log("apiData", apiData);
        console.log("droppedItems", droppedItems);
    }, [apiData, droppedItems, styleArray]);

    const [variableReplaceData, setVariableReplaceData] = useState('')

    // const showTemplateData = async (e) => {
    //     debugger;
    //     setLoading(true)
    //     const filteredData = [{
    //         "fieldName": "name",
    //         "operatorName": "equal",
    //         "compareValue": props.templateName || "FinalBillPrint" || "TestReport" || "ReportDesign" || "TestReport",
    //     }];
    //     var getdata = await WebTemplate.GetCustomizedPageTemplatesWithFilter(filteredData);
    //     if (getdata) {
    //         if (getdata.jsonStructure) {
    //             try {
    //                 const decodedData = decodeURIComponent(getdata.jsonStructure);
    //                 const parsedData = JSON.parse(decodedData);
    //                 // getdata.jsonStructure = parsedData
    //                 setDroppedItems(parsedData);
    //                 if (getdata.textContents) {
    //                     const decodedData = decodeURIComponent(getdata.textContents)
    //                     const parsedData = JSON.parse(decodedData);
    //                     // getdata.jsonStructure = parsedData
    //                     setStylesArray(parsedData);
    //                 }
    //                 console.log("ord1Id", props.ord1Id)
    //                 const orderNo = props.ord1Id || 10923 || 256;
    //                 let sqlQuery = getdata?.insertQuery;
    //                 if (sqlQuery?.includes('@transId')) {
    //                     sqlQuery = sqlQuery.replace(/@transId/g, orderNo);
    //                 }
    //                 const queryData = {
    //                     query1: sqlQuery, //"select ord1Id, itemName, OrderNo,ServiceTypeId,TableGroupId,TableId,PaymentStatus,ord2.itemName,D2ItemId,Qty,Rate,ord2.TotalAmt,KotStatus from ord2 inner Join ord1 on ord1.id = ord2.ord1id where o2orderno = 15",
    //                     query2: sqlQuery,
    //                     query3: sqlQuery
    //                 }
    //                 if (sqlQuery) {
    //                     const tableData = await TemplateSetting.getMultiDatasetExecuteQuery(queryData);
    //                     setVariableReplaceData(tableData);
    //                 }
    //             } catch (error) {
    //                 console.error('Error parsing JSON:', error);
    //                 setDroppedItems([]);
    //             }
    //         } else {
    //             setDroppedItems([]);
    //         }
    //         var templateInnerHtml = getdata.textContents;
    //         var templateInnerHtmlDecode = decodeURIComponent(templateInnerHtml);
    //         getdata.textContents = templateInnerHtmlDecode

    //         setApiData(getdata);
    //         setLoading(false)
    //         // setDataHtml(getdata)
    //     } else {
    //         //document.getElementById("drop-target-innerHtml").outerHTML = "";
    //         setDroppedItems([]);
    //         setLoading(false)
    //     }
    // }

    const showTemplateData = async (ord1Id) => {
        debugger
        setLoading(true)
        const filteredData = [{
            "fieldName": "name",
            "operatorName": "equal",
            "compareValue": props.templateName || "FinalBillPrint" || "TestReport" || "ReportDesign" || "TestReport",
        }];
        var getdata = await WebTemplate.GetCustomizedPageTemplatesWithFilter(filteredData);
        if (getdata) {
            if (getdata.jsonStructure) {
                try {
                    const decodedData = decodeURIComponent(getdata.jsonStructure);
                    const parsedData = JSON.parse(decodedData);
                    console.log("parsedData1", parsedData);
                    // getdata.jsonStructure = parsedData
                    setDroppedItems(parsedData);
                    if (getdata.textContents) {
                        const decodedData = decodeURIComponent(getdata.textContents)
                        const parsedData = JSON.parse(decodedData);
                        console.log("parsedData2", parsedData);
                        // getdata.jsonStructure = parsedData
                        setStylesArray(parsedData);
                    }
                    console.log("ord1Id", props.ord1Id)
                    const orderNo = ord1Id || props.ord1Id || 10923 || 256;
                    let sqlQuery = getdata?.insertQuery;
                    if (sqlQuery?.includes('@transId')) {
                        sqlQuery = sqlQuery.replace(/@transId/g, orderNo);
                    }
                    const queryData = {
                        query1: sqlQuery, //"select ord1Id, itemName, OrderNo,ServiceTypeId,TableGroupId,TableId,PaymentStatus,ord2.itemName,D2ItemId,Qty,Rate,ord2.TotalAmt,KotStatus from ord2 inner Join ord1 on ord1.id = ord2.ord1id where o2orderno = 15",
                        query2: sqlQuery,
                        query3: sqlQuery
                    }
                    if (sqlQuery) {
                        const tableData = await TemplateSetting.getMultiDatasetExecuteQuery(queryData);
                        setVariableReplaceData(tableData);
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                    setDroppedItems([]);
                }
            } else {
                setDroppedItems([]);
            }
            var templateInnerHtml = getdata.textContents;
            var templateInnerHtmlDecode = decodeURIComponent(templateInnerHtml);
            getdata.textContents = templateInnerHtmlDecode

            setApiData(getdata);
            setLoading(false)
            // setDataHtml(getdata)
        } else {
            //document.getElementById("drop-target-innerHtml").outerHTML = "";
            setDroppedItems([]);
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.orderData && props.orderData.length > 0) {
            for (var i = 0; i < props.orderData.length; i++) {
                showTemplateData(props.orderData[i]?.ord2Id);
            }
        }
        else {
            showTemplateData();
        }
    }, []);

    useEffect(() => {
        // Use a for loop to iterate over stylesArray
        for (let index = 0; index < styleArray.length; index++) {
            const styleObject = styleArray[index];
            console.log(styleObject.id);

            const element = document.getElementById(styleObject.id);

            if (element) {
                for (const [key, value] of Object.entries(styleObject.style)) {
                    // Convert kebab-case to camelCase for inline styles
                    const camelCaseKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
                    element.style[camelCaseKey] = value;
                }
            }
        }
    }, [styleArray]);

    // function replacePlaceholders(itemContent, variableReplaceData) {
    //     // Iterate over each table in variableReplaceData
    //     for (const tableName in variableReplaceData) {
    //         const tableData = variableReplaceData[tableName];

    //         // Check if tableData is an array (as expected)
    //         if (Array.isArray(tableData)) {
    //             // Iterate over the objects in tableData (assuming each object contains key-value pairs for replacement)
    //             for (const obj of tableData) {
    //                 if (typeof obj === 'object' && obj !== null) { // Ensure obj is a valid object
    //                     for (const key in obj) {
    //                         if (obj.hasOwnProperty(key)) {
    //                             const regex = new RegExp(key, 'g'); // Create regex to match the placeholder
    //                             itemContent = itemContent.replace(regex, obj[key]); // Replace in itemContent
    //                         }
    //                     }
    //                 }
    //             }
    //         } else if (typeof tableData === 'object' && tableData !== null) {
    //             // Handle case where tableData is a single object instead of an array of objects
    //             for (const key in tableData) {
    //                 if (tableData.hasOwnProperty(key)) {
    //                     const regex = new RegExp(key, 'g'); // Create regex to match the placeholder
    //                     itemContent = itemContent.replace(regex, tableData[key]); // Replace in itemContent
    //                 }
    //             }
    //         }
    //         // If tableData is neither an object nor an array, it is ignored (or handled according to your needs)
    //     }
    //     return itemContent;
    // }

    // function replaceVariables(itemContent, variableReplaceData) {
    //     for (const tableName in variableReplaceData) {
    //         const tableData = variableReplaceData[tableName];

    //         // Find the first <tr> that contains <td> or is inside <tbody>
    //         const tbodyRegex = /<tbody[^>]*>(.*?)<\/tbody>/gs;
    //         let tbodyMatch = tbodyRegex.exec(itemContent);
    //         let firstRowStructure = null;

    //         if (tbodyMatch) {
    //             const tbodyContent = tbodyMatch[1];  // Content inside the first <tbody>
    //             const trRegex = /<tr>(.*?)<\/tr>/g;
    //             const firstRowMatch = trRegex.exec(tbodyContent);
    //             if (firstRowMatch) {
    //                 firstRowStructure = firstRowMatch[1]; // The first <tr> content within <tbody>
    //             }
    //         }

    //         // If no <tbody> is found, fall back to searching for the first <tr> with <td> directly
    //         if (!firstRowStructure) {
    //             const trRegex = /<tr>(.*?)<\/tr>/g;
    //             const firstRowMatch = trRegex.exec(itemContent);
    //             if (firstRowMatch) {
    //                 firstRowStructure = firstRowMatch[1]; // The first <tr> content
    //             }
    //         }

    //         // If we found a <tr> structure
    //         if (firstRowStructure) {
    //             let rowsToAdd = '';

    //             // Process replacements for each object in tableData
    //             for (const obj of tableData) {
    //                 let shouldAppend = false;

    //                 // Check if the first row (or <td>) contains placeholders matching @T1, @T2, etc.
    //                 for (const key in obj) {
    //                     if (firstRowStructure.includes(key)) {
    //                         shouldAppend = true;  // If it contains a relevant placeholder, mark for appending
    //                         break;
    //                     }
    //                 }

    //                 // If there's a match, process the row and append it
    //                 if (shouldAppend) {
    //                     let rowContent = firstRowStructure;

    //                     // Replace placeholders in the row structure with corresponding values from the current object
    //                     for (const key in obj) {
    //                         const regex = new RegExp(key, 'g');
    //                         rowContent = rowContent.replace(regex, obj[key]);
    //                     }

    //                     rowsToAdd += `<tr>${rowContent}</tr>`; // Wrap the updated row in <tr> tags
    //                 }
    //             }

    //             // Append the rows before the closing </table> tag, if rows were added
    //             if (rowsToAdd) {
    //                 itemContent = itemContent.replace('</table>', rowsToAdd + '</table>');
    //             }
    //         }

    //         // Replace any remaining placeholders in the entire content outside of the table
    //         for (const obj of tableData) {
    //             for (const key in obj) {
    //                 const regex = new RegExp(key, 'g');
    //                 itemContent = itemContent.replace(regex, obj[key]);
    //             }
    //         }
    //     }
    //     return itemContent;
    // }

    function replaceVariables(itemContent, variableReplaceData) {

        // Create a map to track the visibility status of tables
        const tableVisibility = {};

        // Iterate through each table name in variableReplaceData
        for (const tableName in variableReplaceData) {
            const tableData = variableReplaceData[tableName];

            // Initialize the visibility status for the table
            tableVisibility[tableName] = {
                shouldHide: true,
                rowsToAdd: ''
            };

            // Check if the table has data
            if (Array.isArray(tableData) && tableData.length > 0) {
                // The table has data, so we should process it to show the table
                tableVisibility[tableName].shouldHide = false; // Set to show the table
            } else {
                // If there's no data, skip further processing for this table
                continue; // Skip to the next table in variableReplaceData
            }

            // Find the first <tbody> and get the content inside it
            const tbodyRegex = new RegExp(/<tbody[^>]*>(.*?)<\/tbody>/s);
            let tbodyMatch = tbodyRegex.exec(itemContent);
            let firstRowStructure = null;

            if (tbodyMatch) {
                const tbodyContent = tbodyMatch[1];  // Content inside the first <tbody>
                const trRegex = /<tr>(.*?)<\/tr>/g;
                const firstRowMatch = trRegex.exec(tbodyContent);
                if (firstRowMatch) {
                    firstRowStructure = firstRowMatch[1]; // The first <tr> content within <tbody>
                }
            }

            // If no <tbody> is found, fallback to searching for the first <tr> with <td> directly
            if (!firstRowStructure) {
                const trRegex = /<tr>(.*?)<\/tr>/g;
                const firstRowMatch = trRegex.exec(itemContent);
                if (firstRowMatch) {
                    firstRowStructure = firstRowMatch[1]; // The first <tr> content
                }
            }
            // If we found a <tr> structure
            if (firstRowStructure) {
                let appendedRowIndexes = [];  // Track which row indexes have been appended
                let firstRowAppended = false;  // Track if the first row has already been appended

                // Process replacements for each object in tableData
                for (const obj of tableData) {
                    let shouldAppend = false;

                    // Check if the first row (or <td>) contains placeholders matching the table name
                    const tdRegex = /<td[^>]*>(.*?)<\/td>/g;
                    let tdMatch;
                    while ((tdMatch = tdRegex.exec(firstRowStructure)) !== null) {
                        const tdContent = tdMatch[1];

                        // Check if any placeholder in this <td> matches the keys in the current object
                        for (const key in obj) {
                            if (tdContent.includes(key)) {
                                shouldAppend = true;  // If it contains a relevant placeholder, mark for appending
                                break;
                            }
                        }
                        if (shouldAppend) break; // No need to check further once a match is found
                    }

                    // If there's a match, process the row and append it
                    if (shouldAppend) {
                        let rowContent = firstRowStructure;

                        // Replace placeholders in each <td> with corresponding values from the current object
                        rowContent = rowContent.replace(tdRegex, (match, p1) => {
                            for (const key in obj) {
                                if (p1.includes(key)) {
                                    return match.replace(key, obj[key]); // Replace within <td> tag
                                }
                            }
                            return match;  // Return unchanged if no matching key is found
                        });

                        // Add the updated row content to the rowsToAdd variable
                        tableVisibility[tableName].rowsToAdd += `<tr>${rowContent}</tr>`;
                        appendedRowIndexes.push(obj);  // Mark this row as appended

                        // Mark the first row as appended after the first match
                        if (!firstRowAppended) {
                            firstRowAppended = true; // If we append a row, mark that the first row has been processed
                        }
                    }
                }

                // Remove the original first <tr> inside <tbody> if a row was appended
                if (firstRowAppended && tbodyMatch) {
                    // Remove the first <tr> inside <tbody> only
                    const firstTrRegex = /<tr>(.*?)<\/tr>/;
                    itemContent = itemContent.replace(tbodyMatch[0], (match) => {
                        return match.replace(firstTrRegex, ''); // Remove only the first <tr> inside <tbody>
                    });
                }

                // Append the rows before the closing </table> tag, if rows were added
                if (tableVisibility[tableName].rowsToAdd) {
                    itemContent = itemContent.replace('</table>', tableVisibility[tableName].rowsToAdd + '</table>');
                }
            }

            // Replace any remaining placeholders in the entire content outside of the table
            for (const obj of tableData) {
                for (const key in obj) {
                    const regex = new RegExp(key, 'g');
                    itemContent = itemContent.replace(regex, obj[key]);
                }
            }
        }
        // After processing all tables, modify the HTML to hide/show tables
        for (const tableName in tableVisibility) {
            if (tableVisibility[tableName].shouldHide) {
                // Hide the table by commenting it out or another means
                const hideTableRegex = new RegExp(`<table[^>]*id="${tableName}"[^>]*>.*?<\\/table>`, 's');
                itemContent = itemContent.replace(hideTableRegex, '<!-- Table Hidden -->');
            }
        }
        return itemContent;
    }

    function replaceVariables1(itemContent, variableReplaceData) {
        for (const tableName in variableReplaceData) {
            const tableData = variableReplaceData[tableName];

            // If there's no data in the table, continue to the next table
            if (!Array.isArray(tableData) || tableData.length === 0) {
                continue;
            }

            // Loop through each key-value pair in the tableData (we consider only the first item for now)
            const obj = tableData[0];

            // Replace placeholders in the content with actual values from the table data
            for (const key in obj) {
                const placeholder = `${key}`;
                const value = obj[key];

                const placeholderRegex = new RegExp(placeholder, 'g');
                itemContent = itemContent.replace(placeholderRegex, value);
            }
        }
        return itemContent;
    }

    // function replaceVariablesInTable(html, variableReplaceData) {
    //     if (typeof variableReplaceData !== "object" || variableReplaceData === null) {
    //         console.error("Error: variableReplaceData should be an object with arrays");
    //         return html;
    //     }

    //     return html.replace(/@\w+\.\w+/g, (match) => {
    //         for (const tableKey in variableReplaceData) {
    //             const table = variableReplaceData[tableKey];
    //             if (Array.isArray(table)) {
    //                 for (const row of table) {
    //                     if (row[match] !== undefined) {
    //                         return row[match];
    //                     }
    //                 }
    //             }
    //         }
    //         return match;
    //     });
    // }

    function generateThermalTable(doc, tableHtml, variableReplaceData, startY) {
        debugger
        const replacedHtml = replaceVariables(tableHtml, variableReplaceData);

        const lineHeight = 5;
        let currentY = startY;
        const parser = new DOMParser();
        const docHtml = parser.parseFromString(replacedHtml, "text/html");
        const tableElement = docHtml.querySelector("table");


        if (tableElement) {

            const columns = tableElement.querySelectorAll('tr:first-child td').length;
            const columnStyles = {};

            for (let i = 0; i < columns; i++) {
                // Distribute cell widths equally or adjust as needed
                columnStyles[i] = { cellWidth: 50 / columns };  // Distribute width equally
            }
            // Draw a separator line **above** the table
            doc.setDrawColor(128, 128, 128); // Set the color for the line (black)
            doc.setLineWidth(0.5);
            doc.line(5, currentY, 55, currentY);
            currentY += lineHeight;

            doc.autoTable({
                html: tableElement,
                startY: startY,
                styles: {
                    font: "Courier",
                    fontSize: 8,
                    fillColor: false,
                    textColor: [0, 0, 0],
                    cellPadding: 2,
                },
                headStyles: {
                    fillColor: false
                },
                tableWidth: 55,
                columnStyles: {
                    0: { cellWidth: 25 },
                    // 1: { cellWidth: 10 },
                    // 2: { cellWidth: 15 },
                    // 3: { cellWidth: 15 },
                },
                // columnStyles: columnStyles,
                theme: 'plain',
                margin: { left: 2, right: 2 },

            });
            currentY = doc.lastAutoTable.finalY || currentY;
            doc.line(5, currentY, 55, currentY);
        }
        return currentY;
    }

    const [printData, setPrintData] = useState("")

    useEffect(() => {
        console.log("printData", printData);
    }, [printData]);

    const renderDroppedItems = () => {
        return droppedItems.length > 0 && droppedItems.some(item => item.items.length > 0) ? (
            droppedItems.map((item, index) => {
                if (item.items.length > 0) {
                    const itemContent = item.textContents;
                    let updatedData = itemContent;
                    if (variableReplaceData && variableReplaceData != "") {
                        updatedData = replaceVariables(itemContent, variableReplaceData);
                        // const data = replaceVariables(itemContent, variableReplaceData)
                        // updatedData = replacePlaceholders(data, variableReplaceData)
                        // generatePDF(updatedData)
                        const placeholderRegex = /<td[^>]*>\s*<div[^>]*>\s*@T\d+\.\w+\s*<\/div>\s*<\/td>/;
                        if (placeholderRegex.test(updatedData)) {
                            return null; // If placeholders are still present, hide the div by returning null
                        }
                    }
                    return (
                        <div
                        // style={{
                        //     transformOrigin: "5% 5%",
                        //     transform: "scale(0.5, 0.5)",
                        //     width: '100%',
                        //     overflow: 'hidden',
                        // }}
                        >
                            <div key={index} dangerouslySetInnerHTML={{ __html: updatedData }} />
                        </div>
                    );
                }
                return null;
            })
        ) : null;
    };

    useEffect(() => {
        if (droppedItems.length > 0) {
            droppedItems.forEach((item) => {
                if (item.items.length > 0) {
                    const itemContent = item.textContents;
                    let updatedData = null;

                    if (variableReplaceData && Object.keys(variableReplaceData).length > 0) {
                        updatedData = replaceVariables(itemContent, variableReplaceData);
                    }
                    if (updatedData) {
                        const placeholderRegex = /<td[^>]*>\s*<div[^>]*>\s*@T\d+\.\w+\s*<\/div>\s*<\/td>/;
                        if (placeholderRegex.test(updatedData)) {
                            return null; // If placeholders are still present, hide the div by returning null
                        }
                        if (printData == "" || printData == null || printData == undefined) {
                            // updatedData = `<div style="width:300px ; height:300px"> ${updatedData} </div>`
                        }
                        setPrintData(prevValues => prevValues + updatedData);
                    }
                    // else {
                    //     setPrintData(prevValues => prevValues + itemContent);
                    // }
                }
            });
        }
    }, [droppedItems, variableReplaceData]);

    // const generatePDF = async () => {
    //     const element = printData;
    //     console.log(element)
    //     debugger;
    //     const testData = document.getElementById('pdf-content');

    //     //     // const container = document.createElement('div');
    //     //     // container.id = 'temporary-container';
    //     //     // container.innerHTML = `<div id="innerhtml">${element}</div>`;
    //     //     // document.body.appendChild(container);

    //     //     // // Get the dynamically created element
    //     //     // const ele = container.querySelector('#innerhtml');
    //     //     // const contentHeight = ele.scrollHeight;
    //     //     debugger;

    //     //     const element1 = `
    //     //     <div style="width: 100mm; margin: 0 auto; overflow: hidden;">
    //     //         <div style="padding: 0mm; box-sizing: border-box;">
    //     //             <div
    //     //                 style="
    //     //                     transform-origin: 5% 5%; 
    //     //                     transform: scale(1, 1); 
    //     //                     width: 200%; /* Adjust based on content size */
    //     //                     overflow: hidden;
    //     //                 "
    //     //             >
    //     //                 ${printData}
    //     //             </div>
    //     //         </div>
    //     //         <style>
    //     //             ${`
    //     //                 @media screen {
    //     //                     * {
    //     //                         color-adjust: exact !important;
    //     //                         -webkit-print-color-adjust: exact !important;
    //     //                         print-color-adjust: exact !important;
    //     //                     }
    //     //                 }
    //     //                 @media print {
    //     //                     * {
    //     //                         color-adjust: exact !important;
    //     //                         -webkit-print-color-adjust: exact !important;
    //     //                         print-color-adjust: exact !important;
    //     //                     }
    //     //                 }
    //     //             `}
    //     //         </style>
    //     //     </div>
    //     // `;

    //     //     if (!element) {
    //     //         console.log('Element with id "pdf_content" not found.');
    //     //         return;
    //     //     }
    //     //     const contentHeight = testData.scrollHeight;

    //     // const container = document.createElement('div');
    //     // container.innerHTML = printData;
    //     // document.body.appendChild(container);

    //     // Select the element to capture
    //     // const element = container;
    //     const contentHeight = testData.scrollHeight;

    //     const opt = {
    //         margin: 0,
    //         filename: 'myfile.pdf',
    //         image: { type: 'jpeg', quality: 1 },
    //         html2canvas: { scale: 4 },
    //         jsPDF: { unit: 'mm', format: [58, contentHeight / 1.75], orientation: 'portrait' },
    //         // pagebreak: { mode: 'avoid-all', after: '.avoidThisRow' }
    //     };

    //     try {
    //         const pdfDataUri = await html2pdf()
    //             .from(element)
    //             .set(opt)
    //             .toPdf()
    //             .output('datauristring');

    //         const pdfBase64 = pdfDataUri.split(',')[1];

    //         if (window.ReactNativeWebView) {
    //             window.ReactNativeWebView.postMessage(pdfBase64);
    //         } else {
    //             const byteCharacters = atob(pdfBase64);
    //             const byteNumbers = new Array(byteCharacters.length);
    //             for (let i = 0; i < byteCharacters.length; i++) {
    //                 byteNumbers[i] = byteCharacters.charCodeAt(i);
    //             }
    //             const byteArray = new Uint8Array(byteNumbers);
    //             const blob = new Blob([byteArray], { type: 'application/pdf' });

    //             const pdfUrl = URL.createObjectURL(blob);
    //             const newWindow = window.open(pdfUrl, '_blank', 'width=1000px,height=1000px');

    //             if (newWindow) {
    //                 props.setShowBillPrint(false);

    //                 newWindow.onload = function () {
    //                     newWindow.print();
    //                     newWindow.onafterprint = function () {
    //                         newWindow.close();
    //                     };
    //                 };
    //             } else {
    //                 console.log('Failed to open new window for printing.');
    //             }
    //         }
    //     } catch (error) {
    //         console.log('Error generating PDF:', error);
    //     }


    //     // const opt = {
    //     //     margin: 0,
    //     //     filename: 'myfile.pdf',
    //     //     image: { type: 'jpeg', quality: 1 },
    //     //     html2canvas: { scale: 4 },
    //     //     jsPDF: { unit: 'mm', format: [58, `auto`], orientation: 'portrait' }
    //     // };

    //     // try {
    //     //     const pdfDataUri = await html2pdf()
    //     //         .from(element)
    //     //         .set(opt)
    //     //         .toPdf()
    //     //         .output('datauristring');

    //     //     const pdfBase64 = pdfDataUri.split(',')[1];

    //     //     if (window.ReactNativeWebView) {
    //     //         window.ReactNativeWebView.postMessage(pdfBase64);
    //     //     } else {
    //     //         // Create a blob from the base64 string
    //     //         const byteCharacters = atob(pdfBase64);
    //     //         const byteNumbers = new Array(byteCharacters.length);
    //     //         for (let i = 0; i < byteCharacters.length; i++) {
    //     //             byteNumbers[i] = byteCharacters.charCodeAt(i);
    //     //         }
    //     //         const byteArray = new Uint8Array(byteNumbers);
    //     //         const blob = new Blob([byteArray], { type: 'application/pdf' });

    //     //         const pdfUrl = URL.createObjectURL(blob);
    //     //         const newWindow = window.open(pdfUrl, '_blank', 'width=1000px,height=1000px');
    //     //         props.setShowBillPrint(false);

    //     //         newWindow.onload = function () {
    //     //             newWindow.print();
    //     //             newWindow.onafterprint = function () {
    //     //                 newWindow.close();
    //     //             };
    //     //         };
    //     //     }
    //     // } catch (error) {
    //     //     console.log('Error generating PDF:', error);
    //     // }
    // };

    const pixelToMM = (pixels) => {
        const conversionFactor = 0.2645833333; // 1 pixel ≈ 0.2645833333 mm
        return pixels * conversionFactor;
    };

    //old generatePDF Function
    // const generatePDF = async () => {
    //     const content = printData;
    //     try {
    //         const opt = {
    //             margin: 0,
    //             filename: 'OrderData.pdf',
    //             image: { type: 'jpeg', quality: 1 },
    //             html2canvas: { scale: 4 },
    //             jsPDF: { unit: 'mm', format: [58, 297], orientation: 'portrait' },
    //         };
    //         const pdfDataUri = await html2pdf()
    //             .from(content)
    //             .set(opt)
    //             .toPdf()
    //             .output('datauristring');

    //         const pdfBase64 = pdfDataUri.split(',')[1];

    //         if (window.ReactNativeWebView) {
    //             try {
    //                 window.ReactNativeWebView.postMessage(pdfBase64);
    //                 props.setShowBillPrint(false);
    //             }
    //             catch (error) {
    //                 console.log(error);
    //             }
    //         }
    //         else {

    //             let container = document.createElement('div');
    //             container.id = 'temporary-container';
    //             container.style.width = "300px";
    //             container.innerHTML = `<div id="innerhtml">${content}</div>`  //style="width:100%";
    //             document.body.appendChild(container);

    //             const contentHeight = container.offsetHeight;
    //             const contentWidth = container.offsetWidth;

    //             try {
    //                 // Capture the content using html2canvas
    //                 const canvas = await html2canvas(container, {
    //                     scale: 2, // Higher scale for better quality
    //                 });

    //                 // Get canvas dimensions in pixels
    //                 const canvasWidth = canvas.width;
    //                 const canvasHeight = canvas.height;

    //                 const pxWidth = pixelToMM(canvasWidth)
    //                 const pxHeight = pixelToMM(canvasHeight)

    //                 // Convert pixels to mm for jsPDF
    //                 const pdfWidth = 58;
    //                 const pdfHeight = pdfWidth * (canvasHeight / canvasWidth); //297;
    //                 const aspectRatio = canvasWidth / canvasHeight;
    //                 // const aspectRatio = pxWidth / pxHeight;

    //                 let finalWidth, finalHeight;

    //                 if (canvasWidth > canvasHeight) {
    //                     // Landscape orientation
    //                     finalWidth = pdfWidth;
    //                     finalHeight = pdfWidth / aspectRatio;
    //                 } else {
    //                     // Portrait orientation
    //                     finalHeight = pdfHeight;
    //                     finalWidth = (pdfHeight * aspectRatio);
    //                 }

    //                 debugger
    //                 // Scale the content to fit within the PDF dimensions
    //                 const pdf = new jsPDF({
    //                     orientation: "P",
    //                     unit: "mm",
    //                     format: [pdfWidth, pdfHeight],
    //                 });

    //                 const imgData = canvas.toDataURL("image/png");

    //                 // Center the content
    //                 // const xOffset = (pdfWidth - finalWidth) / 2;
    //                 // const yOffset = (pdfHeight - finalHeight) / 2;

    //                 // pdf.addImage(imgData, "PNG", xOffset, yOffset, finalWidth, finalHeight);

    //                 const xOffset = 3;//(pdfWidth - finalWidth) / 2;
    //                 const yOffset = 0;

    //                 // Add the image at a specified location
    //                 pdf.addImage(imgData, "PNG", xOffset, yOffset, (finalWidth + 13), (finalHeight - 5));

    //                 const pdfBlob = pdf.output('blob');

    //                 // Open the PDF in a new window
    //                 const pdfURL = URL.createObjectURL(pdfBlob);
    //                 const newWindow = window.open(pdfURL, '_blank');

    //                 if (newWindow) {
    //                     props.setShowBillPrint(false);
    //                     newWindow.focus();
    //                     newWindow.onload = () => {
    //                         newWindow.print();
    //                     };
    //                 }

    //                 //pdf.save("OrderBill.pdf");

    //                 document.body.removeChild(container);
    //             } catch (err) {
    //                 console.error("PDF Generation Error:", err);
    //             }


    //             //             let container = document.createElement('div');
    //             //             container.id = 'temporary-container';
    //             //             container.style.width = "300px";  // get this value from API
    //             //             container.innerHTML = `<div id="innerhtml">${content}</div>`;
    //             //             document.body.appendChild(container);

    //             //             try {
    //             //                 // Open content in a new window for printing
    //             //                 const printWindow = window.open('', '_blank');
    //             //                 printWindow.document.write(`
    //             //     <html>
    //             //         <head>
    //             //             <title>Print Bill</title>
    //             //             <style>
    //             //                 body {
    //             //                     font-family: Arial, sans-serif;
    //             //                     text-align: center;
    //             //                 }
    //             //                 #innerhtml {
    //             //                     padding: 20px;
    //             //                 }
    //             //             </style>
    //             //         </head>
    //             //         <body>
    //             //             ${content}
    //             //         </body>
    //             //     </html>
    //             // `);

    //             //                 printWindow.document.close();

    //             //                 // Wait until content loads fully before printing
    //             //                 printWindow.onload = () => {
    //             //                     printWindow.print();
    //             //                     printWindow.onafterprint = () => {
    //             //                         printWindow.close();  // Close window after printing
    //             //                         document.body.removeChild(container);
    //             //                     };
    //             //                 };

    //             //             } catch (err) {
    //             //                 console.error("Immediate Print Error:", err);
    //             //                 document.body.removeChild(container);
    //             //             }
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const generatePDF = async () => {
        debugger
        const content = printData;
        const content1 = styleArray;
        const opt = {
            margin: 0,
            filename: 'OrderData.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 4 },
            jsPDF: { unit: 'mm', format: [58, 297], orientation: 'portrait' },
        };
        try {
            if (window.ReactNativeWebView) {
                try {
                    const pdfDataUri = await html2pdf()
                        .from(content)
                        .set(opt)
                        .toPdf()
                        .output("datauristring");

                    const pdfBase64 = pdfDataUri.split(",")[1];
                    window.ReactNativeWebView.postMessage(pdfBase64);
                    props.setShowBillPrint(false);
                } catch (error) {
                    console.log(error);
                }
            } else {
                let container = document.createElement('div');
                container.id = 'temporary-container';
                container.style.width = "300px";
                container.innerHTML = `<div id="innerhtml">${content}</div>`  //style="width:100%";
                document.body.appendChild(container);

                const contentHeight = container.offsetHeight;
                const contentWidth = container.offsetWidth;
                let contentHeightMm = pixelToMM(contentHeight);
                const contentWidthMm = pixelToMM(contentWidth);

                const htmlRegex = /<([a-z][\w0-9]*)\s*[^>]*>.*<\/\1\s*>|<([a-z][\w0-9]*)\s*[^>]*\/>/i;

                if (contentHeightMm < 297) {
                    contentHeightMm = 297;
                }

                const doc = new jsPDF({
                    orientation: "portrait",
                    unit: "mm",
                    format: [58, contentHeightMm],
                });

                doc.setFont("Courier", "normal");
                doc.setFontSize(10);

                let currentY = 10;

                for (const item of content1) {

                    const { content, style } = item;

                    if (variableReplaceData && variableReplaceData !== '') {
                        if (htmlRegex.test(content)) {
                            // const testdata = replaceVariables(content, variableReplaceData)

                            // console.log("testdata", testdata);
                            // const blob = new Blob([testdata], { type: 'text/html' });
                            // const url = URL.createObjectURL(blob);

                            // // Create a download link and append it to the body
                            // const a = document.createElement('a');
                            // a.href = url;
                            // a.download = 'testdata.html';

                            // // Append the anchor to the DOM (not visible to user)
                            // document.body.appendChild(a);
                            // a.click();  // Automatically simulate a click to download
                            // document.body.removeChild(a);  // Clean up the DOM

                            // // Release the object URL after download
                            // URL.revokeObjectURL(url);

                            const updatedY = await generateThermalTable(doc, content, variableReplaceData, currentY);

                            currentY = updatedY + 5;
                        } else {
                            const data = replaceVariables1(content, variableReplaceData)
                            console.log("data", data);

                            const lineHeight = 5;
                            const maxWidth = 50;
                            const lines = doc.splitTextToSize(data, maxWidth);

                            if (style) {
                                if (style.fontWeight) {
                                    doc.setFont('Courier', style.fontWeight === 'Bold' ? 'bold' : 'normal');
                                }

                                if (style.fontStyle) {
                                    doc.setFont('Courier', style.fontStyle === 'Italic' ? 'italic' : 'normal');
                                }

                                if (style['font-size']) {
                                    const fontSizePx = parseFloat(style['font-size'].replace('px', ''));

                                    if (!isNaN(fontSizePx)) {
                                        // Convert pixels to points
                                        const fontSizePt = fontSizePx * 0.75;

                                        // Apply font size in the PDF
                                        doc.setFontSize(fontSizePt);
                                    }
                                }
                                if (style.color) {
                                    const rgb = style.color.replace('#', '');
                                    const r = parseInt(rgb.substring(0, 2), 16);
                                    const g = parseInt(rgb.substring(2, 4), 16);
                                    const b = parseInt(rgb.substring(4, 6), 16);
                                    doc.setTextColor(r, g, b);
                                }

                                console.log('Applied styles:', style);


                                delete item.style;
                            }

                            doc.text(lines, 5, currentY);
                            currentY += lines.length * lineHeight;


                            doc.setFont('Courier', 'normal');
                            doc.setFontSize(10);
                            doc.setTextColor(0, 0, 0);
                            // doc.text(data, 5, currentY); 
                            // currentY += 5; // Increment Y position for the next line

                        }
                    }
                }

                // Add a separator line
                doc.text("-----------------------------", 5, currentY);
                currentY += 5;

                // Footer
                doc.setFont("Courier", "italic");
                doc.text("Thank you for shopping!", 29, currentY, { align: "center" });


                // Generate the PDF Blob and open it in a new window
                const pdfBlob = doc.output("blob");
                const pdfURL = URL.createObjectURL(pdfBlob);
                const newWindow = window.open(pdfURL, "_blank");

                if (newWindow) {
                    props.setShowBillPrint(false);
                    newWindow.focus();
                    newWindow.onload = () => {
                        newWindow.print();
                    };
                }
            }
        } catch (error) {
            console.error("PDF Generation Error:", error);
        }
    };


    // const generatePDF = async () => {
    //     const element = printData; // Ensure `printData` references the correct DOM element
    //     if (!element) {
    //         console.log('Element with id "pdf_content" not found.');
    //         return;
    //     }

    //     const opt = {
    //         margin: 0, // Margins: top, left, right, bottom in millimeters
    //         filename: 'dynamic_report.pdf',
    //         image: { type: 'jpeg', quality: 2 },
    //         html2canvas: { scale: 4 },
    //         jsPDF: {
    //             unit: 'mm',
    //             format: [58, 297],
    //             orientation: 'portrait'
    //         }
    //     };

    //     try {
    //         // Generate PDF with html2pdf.js
    //         const pdfDataUri = await html2pdf()
    //             .from(element)
    //             .set(opt)
    //             .toPdf()
    //             .output('datauristring');

    //         const pdfBase64 = pdfDataUri.split(',')[1];

    //         if (window.ReactNativeWebView) {
    //             window.ReactNativeWebView.postMessage(pdfBase64);
    //         } else {
    //             const byteCharacters = atob(pdfBase64);
    //             const byteNumbers = new Array(byteCharacters.length);

    //             for (let i = 0; i < byteCharacters.length; i++) {
    //                 byteNumbers[i] = byteCharacters.charCodeAt(i);
    //             }

    //             const byteArray = new Uint8Array(byteNumbers);
    //             const blob = new Blob([byteArray], { type: 'application/pdf' });

    //             const pdfUrl = URL.createObjectURL(blob);
    //             const newWindow = window.open(pdfUrl, '_blank', 'width=1000px,height=1000px');
    //             props.setShowBillPrint(false);

    //             newWindow.onload = function () {
    //                 newWindow.print();
    //                 newWindow.onafterprint = function () {
    //                     newWindow.close();
    //                 };
    //             };
    //         }
    //     } catch (error) {
    //         console.error('Failed to generate PDF:', error);
    //     }
    // };

    useEffect(() => {
        if (droppedItems.length > 0) {
            const timer = setTimeout(() => {
                generatePDF();
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [droppedItems, printData]);

    return (
        <>
            {/* <button onClick={generatePDF}>Print</button> */}
            {/* <div id="pdf-content">
                <div style={{
                    transformOrigin: "5% 5%", 
                    transform: "scale(0.7, 0.7)"
                }}>
                    {renderDroppedItems()}
                </div>
            </div > */}

            <div id="pdf-content" style={{ width: '100mm', margin: '5 auto', overflow: 'hidden' }}>
                <div style={{ padding: '5mm', boxSizing: 'border-box' }}>
                    <div
                        style={{
                            transformOrigin: "5% 5%",
                            transform: "scale(0.5, 0.5)",
                            width: '100%',
                            overflow: 'hidden',
                        }}
                    >
                        {renderDroppedItems()}
                    </div>
                </div>
                <style>
                    {`
                @media screen {
                    * {
                        color-adjust: exact !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                }
                @media print {
                    * {
                        color-adjust: exact !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                }
                `}
                </style>
            </div>
            {loading && <Loader />}
        </>
    )
}

export default ReportDesign
